import { TextField as LibTextField } from 'lib/componentLibrary';
import { ChangeEvent, useCallback } from 'lib/react';

type TextFieldType = 'text' | 'email' | 'number' | 'textarea';

export interface TextFieldProps {
  value?: string;
  onChange?: (_value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
}

const TextField: React.FC<TextFieldProps & { type?: TextFieldType }> = ({ disabled, label, error, placeholder, value, onChange, type = 'text', rows }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  return (
    <LibTextField
      value={value}
      onChange={handleChange}
      disabled={disabled}
      className="textField"
      fullWidth
      type={type}
      variant="outlined"
      label={label}
      error={!!error}
      helperText={error || ''}
      placeholder={placeholder}
      rows={rows}
    />
  );
};

export default TextField;
