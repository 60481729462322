import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color, TABLE_CELL_HEIGHT } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import { SortableTableColumnHeader, SortableTableDataItem, TableDataItem, TableProps } from './types';

const Container = styled.div`
  max-width: 100%;
`;

const TableOuterContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 8px 0;
  position: relative;
`;

const TableInnerContainer = styled.div<{ height: number }>`
  ${({ height }) => `height: ${height}px`};
  overflow: auto;
`;

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const StyledTableElement = styled.table`
  border-collapse: collapse;
  width: 100%;

  tbody {
    max-height: 200px;
    overflow: scroll;
  }
`;

const TBodyTr = styled.tr`
  transition: background-color 0.3s;

  cursor: pointer;
  &:hover {
    background: ${Color.grey1};
  }
`;

const Th = styled.th<{ $cursorPointer?: boolean }>`
  text-align: left;
  padding: 8px 16px;
  white-space: nowrap;
  height: ${TABLE_CELL_HEIGHT}px;
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'auto')};
`;

const Td = styled.td`
  text-align: left;
  padding: 8px 16px;
  white-space: nowrap;
  height: ${TABLE_CELL_HEIGHT}px;
`;

const TableEmptyMessage = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  background: ${Color.grey};
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const noSortFun = () => (_item1: SortableTableDataItem, _item2: SortableTableDataItem) => 0;

type SortBehavior = 'ASC' | 'DESC';

const Table: React.FC<TableProps> = ({
  className,
  height,
  title,
  columnHeaders,
  tableData,
  headerLabelResolver,
  onTableBodyRowHover,
  onTableBodyRowClick,
  noDataMessage,
  noVerticalScrolling = false,
  type
}) => {
  const [t] = useGlobalTranslation();

  const [sortFun, setSortFun] = useState<(_item1: SortableTableDataItem, _item2: SortableTableDataItem) => number>(noSortFun);
  const [sortBehavior, setSortBehavior] = useState<SortBehavior | null>(null);

  const nextSortBehavior: SortBehavior = useMemo(() => {
    if (sortBehavior === null) return 'DESC';
    if (sortBehavior === 'ASC') return 'DESC';
    return 'ASC';
  }, [sortBehavior]);

  const getAndUpdateSortBehavior = useCallback(() => {
    setSortBehavior(nextSortBehavior);
    return nextSortBehavior;
  }, [nextSortBehavior]);

  const handleSortableTableHeaderClick = useCallback(
    (header: SortableTableColumnHeader) => {
      if (!header.sortable) return;

      const newSortBehavior = getAndUpdateSortBehavior();

      const newSortFunction = (item1: SortableTableDataItem, item2: SortableTableDataItem) => {
        const item1Value = item1[header.label].value;
        const item2Value = item2[header.label].value;
        if (newSortBehavior === 'DESC') {
          return item1Value < item2Value ? -1 : 1;
        }
        return item1Value > item2Value ? -1 : 1;
      };

      setSortFun(() => newSortFunction);
    },
    [getAndUpdateSortBehavior]
  );

  const handleTableRowMouseOver = useCallback(
    (e: MouseEvent<HTMLTableRowElement>, item: TableDataItem) => {
      onTableBodyRowHover?.(e, item);
    },
    [onTableBodyRowHover]
  );

  const handleTableRowClick = useCallback(
    (e: MouseEvent<HTMLTableRowElement>, item: TableDataItem) => {
      onTableBodyRowClick?.(e, item);
    },
    [onTableBodyRowClick]
  );

  const generateHeaderKey = useCallback((header: string) => {
    return `table-header-row-${header}`;
  }, []);

  const generateDataRowKey = useCallback((item: TableDataItem) => {
    return `table-body-row-${item.id}`;
  }, []);

  const generateDataCellKey = useCallback((item: TableDataItem, header: string) => {
    return `table-body-cell-${item.id}-${header}`;
  }, []);

  const tableBodyRowCount = useMemo(() => (noVerticalScrolling ? Math.floor((height - TABLE_CELL_HEIGHT) / TABLE_CELL_HEIGHT) : Infinity), [height, noVerticalScrolling]);

  const tableDataRendered = useMemo(() => {
    const getShouldRender = (index: number) => index < tableBodyRowCount;

    // table is normal
    if (type === undefined || type === 'normal') {
      return tableData.map((item, index) => {
        const shouldRender = getShouldRender(index);

        if (shouldRender) {
          return (
            <TBodyTr key={generateDataRowKey(item)} onMouseOver={(e) => handleTableRowMouseOver(e, item)} onClick={(e) => handleTableRowClick(e, item)}>
              {columnHeaders.map((header) => (
                <Td key={generateDataCellKey(item, header)}>{item[header]}</Td>
              ))}
            </TBodyTr>
          );
        }
        return '';
      });
    }

    if (type === 'sortable') {
      return tableData.sort(sortFun).map((item, index) => {
        const shouldRender = getShouldRender(index);

        if (shouldRender) {
          const idObjectForExtractingKey = { id: item.id.label };
          const trKey = generateDataRowKey(idObjectForExtractingKey);

          const trContent = columnHeaders.map((header) => {
            const tdKey = generateDataCellKey(idObjectForExtractingKey, header.label);
            const tdContent = item[header.label].label;

            return <Td key={tdKey}>{tdContent}</Td>;
          });

          return (
            <TBodyTr key={trKey} onMouseOver={(e) => handleTableRowMouseOver(e, item)} onClick={(e) => handleTableRowClick(e, item)}>
              {trContent}
            </TBodyTr>
          );
        }
        return '';
      });
    }
    return '';
  }, [columnHeaders, generateDataCellKey, generateDataRowKey, handleTableRowClick, handleTableRowMouseOver, sortFun, tableBodyRowCount, tableData, type]);

  const tableHeadersRendered = useMemo(() => {
    // table is normal
    if (type === undefined || type === 'normal') {
      return columnHeaders.map((header) => <Th key={generateHeaderKey(header)}>{t(headerLabelResolver(header))}</Th>);
    }
    if (type === 'sortable') {
      return columnHeaders.map((header) => (
        <Th $cursorPointer={header.sortable} key={generateHeaderKey(header.label)} onClick={() => handleSortableTableHeaderClick(header)}>
          {t(headerLabelResolver(header.label))}
        </Th>
      ));
    }
    throw new Error('invalid type');
  }, [columnHeaders, generateHeaderKey, handleSortableTableHeaderClick, headerLabelResolver, t, type]);

  const tableEmptyMessageRendered = useMemo(() => (tableData.length ? '' : <TableEmptyMessage>{noDataMessage}</TableEmptyMessage>), [noDataMessage, tableData.length]);

  return (
    <Container>
      <Title>{title}</Title>
      <TableOuterContainer>
        {tableEmptyMessageRendered}
        <TableInnerContainer height={height}>
          <StyledTableElement className={className}>
            <thead>
              <tr>{tableHeadersRendered}</tr>
            </thead>
            <tbody>{tableDataRendered}</tbody>
          </StyledTableElement>
        </TableInnerContainer>
      </TableOuterContainer>
    </Container>
  );
};

export default Table;
