import { ButtonProps, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { preventEventDefault } from 'utils/event';
import { UsersSearchData } from '../types';

const OuterContainer = styled.div``;

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const InnerContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 40px 40px 16px;
  position: relative;

  @media (max-width: 430px) {
    padding: 40px 8px;
  }
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  max-width: calc(25% - 16px);
  

  @media(max-width: 1000px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const SubmitButtonContainerEl = styled.div`
max-width: calc(25% - 16px);

width: 100%;

padding: 0 16px;

@media(max-width: 1000px) {
  max-width: none;
  height: auto
  margin-bottom: 16px
}
`;

const SubmitButtonContainerCo: React.FC<ButtonProps> = ({ children, ...otherProps }) => {
  return (
    <SubmitButtonContainerEl>
      <PrimaryButtonMedium {...otherProps} fullWidth>
        {children}
      </PrimaryButtonMedium>
    </SubmitButtonContainerEl>
  );
};

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.usersManagement.form.label.${formFieldName}`;

interface Props {
  submit: (_data: UsersSearchData) => void;
}

const UsersSearchForm: React.FC<Props> = ({ submit }) => {
  const [t] = useGlobalTranslation();

  const formFields: FormFields = useMemo(() => {
    return [
      {
        name: 'firstName',
        label: 'firstName',
        type: 'text'
      },
      {
        name: 'lastName',
        label: 'lastName',
        type: 'text'
      },
      {
        name: 'username',
        label: 'username',
        type: 'text'
      },
      {
        fixedLabel: t('message.search'),
        type: 'submit'
      }
    ];
  }, [t]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SubmitButton: SubmitButtonContainerCo
    }),
    [formFields]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: UsersSearchData = getFormFieldsWithValues() as unknown as UsersSearchData;
      submit(data);
    },
    [getFormFieldsWithValues, submit]
  );

  return (
    <OuterContainer>
      <Title>{t('message.search')}</Title>
      <InnerContainer>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
          <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
        </form>
      </InnerContainer>
    </OuterContainer>
  );
};

export default UsersSearchForm;
