import { TextField } from '@mui/material';
import { AxiosError as OriginalAxiosError } from 'axios';
import { ChangeEvent, FormEventHandler, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import { useAuth } from 'auth';
import {
  Content as ContentUI,
  FooterContainer,
  FormInnerContainer,
  Header,
  IconsSection,
  Image,
  InnerBox,
  OuterBox,
  PageWithFooterContainer,
  Title,
  TitleContainer
} from 'components/AuthenticationPages';
import Footer from 'components/Footer';
import { Form } from 'components/Form';
import LanguageSwitcher from 'components/Language/LanguageSwitcher';
import Logo from 'components/Logo';
import { FullWidthPrimaryButtonLarge } from 'components/UI/PrimaryButton';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useGetServerHealth from 'hooks/server/useGetServerHealth';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import type { PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';

import FormLink from './components/FormLink';

const ResetPasswordMessageContainer = styled.div`
  margin: 32px 0 0;
  text-align: center;
`;

const validate = (value: string) => {
  return value.length > 0;
};

// eslint-disable-next-line no-unused-vars
enum TextFieldName {
  // eslint-disable-next-line no-unused-vars
  EMAIL = 'email',
  // eslint-disable-next-line no-unused-vars
  PASSWORD = 'password'
}

type TextFieldsErrorState = {
  [_ in TextFieldName]: boolean;
};

type AxiosError = OriginalAxiosError<string>;

const Content = () => {
  const [t] = useGlobalTranslation();

  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const { serverIsOn } = useGetServerHealth();

  const { authenticate } = useAuth();

  const [textFieldsErrorState, setTextFieldsErrorState] = useState<TextFieldsErrorState>({
    email: false,
    password: false
  });

  const errorIsInEmail = useMemo(() => textFieldsErrorState.email, [textFieldsErrorState.email]);
  const errorIsInPassword = useMemo(() => textFieldsErrorState.password, [textFieldsErrorState.password]);

  const emptyFieldErrorMessage = useMemo(() => t('form.error.empty'), [t]);

  const emailErrorMessage = useMemo(() => (errorIsInEmail ? emptyFieldErrorMessage : ''), [emptyFieldErrorMessage, errorIsInEmail]);
  const passwordErrorMessage = useMemo(() => (errorIsInPassword ? emptyFieldErrorMessage : ''), [emptyFieldErrorMessage, errorIsInPassword]);

  const changeTextFieldErrorState = useCallback(
    (textFieldName: TextFieldName, isError: boolean) => {
      setTextFieldsErrorState((pre) => ({
        ...pre,
        [textFieldName]: isError
      }));
    },
    [setTextFieldsErrorState]
  );

  const handleTextFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, textFieldName: TextFieldName) => {
      changeTextFieldErrorState(textFieldName, !validate(e.target.value));
    },
    [changeTextFieldErrorState]
  );

  const elementDisabled = useMemo(() => !serverIsOn, [serverIsOn]);

  const serverStatusIcon = useMemo(() => (serverIsOn ? <Image src="/images/icons/check.png" /> : <Image src="/images/icons/cancel.png" />), [serverIsOn]);

  const prepareTextField = useCallback(
    (textFieldName: TextFieldName, form: HTMLFormElement) => {
      const textFieldValue = form[textFieldName].value as string;
      const textFieldIsValid = validate(textFieldValue);

      if (!textFieldIsValid) {
        changeTextFieldErrorState(textFieldName, true);
      }

      return { textFieldValue, textFieldIsValid };
    },
    [changeTextFieldErrorState]
  );

  const failedLogInCallback = useCallback(
    (error: AxiosError) => {
      enqueueErrorSnackbar(t(`page.login.message.error.${error.response?.data ?? 'error'}`));
    },
    [enqueueErrorSnackbar, t]
  );

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      preventEventDefault(e);

      const form = e.currentTarget;

      const { textFieldIsValid: isEmailValid, textFieldValue: email } = prepareTextField(TextFieldName.EMAIL, form);
      const { textFieldIsValid: isPasswordValid, textFieldValue: password } = prepareTextField(TextFieldName.PASSWORD, form);

      if (!(isEmailValid && isPasswordValid)) {
        return;
      }

      const callbacks: PromiseCallbacks = {
        failure: failedLogInCallback
      };

      authenticate(email, password, callbacks);
    },
    [authenticate, failedLogInCallback, prepareTextField]
  );

  return (
    <PageWithFooterContainer>
      <Header>
        <Logo />
      </Header>
      <ContentUI>
        <OuterBox>
          <InnerBox>
            <IconsSection>
              <LanguageSwitcher color={Color.typography} />
              {serverStatusIcon}
            </IconsSection>
            <TitleContainer>
              <Title>{t('page.login.title')}</Title>
            </TitleContainer>
            <Form onSubmit={handleFormSubmit}>
              <FormInnerContainer>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  name={TextFieldName.EMAIL}
                  label={t('form.label.email')}
                  error={errorIsInEmail}
                  helperText={emailErrorMessage}
                  onChange={(event) => handleTextFieldChange(event, TextFieldName.EMAIL)}
                  disabled={elementDisabled}
                />
                <TextField
                  fullWidth
                  type="password"
                  variant="outlined"
                  name={TextFieldName.PASSWORD}
                  label={t('form.label.password')}
                  error={errorIsInPassword}
                  helperText={passwordErrorMessage}
                  onChange={(event) => handleTextFieldChange(event, TextFieldName.PASSWORD)}
                  disabled={elementDisabled}
                />
                <FullWidthPrimaryButtonLarge disabled={elementDisabled} type="submit">
                  {t('message.login')}
                </FullWidthPrimaryButtonLarge>
              </FormInnerContainer>
            </Form>
            <ResetPasswordMessageContainer>
              <FormLink>{t('message.changeOrResetPassword')}</FormLink>
            </ResetPasswordMessageContainer>
          </InnerBox>
        </OuterBox>
      </ContentUI>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </PageWithFooterContainer>
  );
};

export default Content;
