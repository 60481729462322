import { ExitToApp, Menu, Settings } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color, paths } from 'appConstants';
import { useAuth } from 'auth';
import IconWrapper from 'components/Icons/IconWrapper';
import LanguageSwitcher from 'components/Language/LanguageSwitcher';
import Logo from 'components/Logo';
import { stopEventPropagation } from 'utils/event';

import useManageSideNav from '../hooks/manageSideNav';
import TopNav from './TopNav';

const OuterContainer = styled.header`
  background: ${Color.accentPrimary};
`;

const InnerContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  svg {
    font-size: 30px !important;
    width: auto !important;
  }

  @media (max-width: 500px) {
    display: block;
    padding: 16px 0;
  }
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 500px) {
    gap: 8px;

    img {
      width: 190px;
    }
  }
`;

const IconsSection = styled.div`
  display: flex;
`;

const Separator = styled.hr`
  background: ${Color.white};
  display: none;
  margin: 16px 0 4px;

  @media (max-width: 500px) {
    display: block;
  }
`;

const MenuIconContainer = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`;

const Topbar = () => {
  const { openSideNav } = useManageSideNav();

  const { logout } = useAuth();

  const handleMenuIconClick = useCallback(
    (e: MouseEvent) => {
      stopEventPropagation(e);
      openSideNav();
    },
    [openSideNav]
  );

  return (
    <OuterContainer>
      <InnerContainer>
        <LogoSection>
          <MenuIconContainer>
            <IconButton onClick={handleMenuIconClick}>
              <IconWrapper Icon={Menu} />
            </IconButton>
          </MenuIconContainer>
          <Link to={paths.home}>
            <Logo />
          </Link>
        </LogoSection>
        <Separator />
        <IconsSection>
          <Link to={paths.settings}>
            <IconButton>
              <IconWrapper Icon={Settings} />
            </IconButton>
          </Link>
          <LanguageSwitcher color={Color.white} />
          <IconButton onClick={logout}>
            <IconWrapper Icon={ExitToApp} />
          </IconButton>
        </IconsSection>
      </InnerContainer>
      <TopNav />
    </OuterContainer>
  );
};

export default Topbar;
