import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import { useHasAccess } from 'auth';
import { FilesList } from 'components/Files';
import { PrimaryButtonSmall } from 'components/UI/PrimaryButton';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGetWarehouse from 'features/warehouses/hooks/useGetWarehouse';
import { composeWarehouseAddress } from 'features/warehouses/util';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import { useTrigger } from 'hooks/trigger';
import { transformResponseFilesToAppFiles } from 'utils/file';

import DisableWarehouseButton from './DisableWarehouseButton';
import EnableWarehouseButton from './EnableWarehouseButton';

const Container = styled.div``;

const WarehouseHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const WarehouseHeaderImage = styled.img`
  width: 300px;
  border-radius: 4px;

  @media (max-width: 640px) {
    width: 100%;
  }
`;

const WarehouseHeaderInfoContainer = styled.div``;

const WarehouseHeaderTextContainer = styled.div``;

const WarehouseHeaderLocationName = styled.div`
  font-size: 27px;
  font-weight: 600;
`;

const WarehouseHeaderAddress = styled.div`
  font-size: 20px;
  color: ${Color.typography_input};
  font-weight: 500;
  margin-top: 16px;
`;

const WarehouseHeaderActionContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  & > button {
    min-width: 180px;
  }
`;

const WarehouseContent = styled.div`
  margin-top: 80px;
  padding: 0 40px;

  @media (max-width: 640px) {
    padding: 0;
  }
`;

const WarehouseContentTableContainer = styled.div`
  overflow: auto;
`;

const WarehouseContentTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const WarehouseContentTableRow = styled.tr`
  &:nth-child(odd) {
    background: ${Color.grey1};
  }
`;

const WarehouseContentTableRowLabel = styled.th`
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  text-align: left;
  width: 350px;
`;

const WarehouseContentTableRowValue = styled.td`
  font-size: 18px;
  padding: 16px;
  text-align: right;
`;

const FilesListingContainer = styled.div`
  margin: 80px 0;

  @media (max-width: 767px) {
    max-width: none;
  }
`;

const FilesListingHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    max-width: none;
  }
`;

const LinkToFile = styled.p`
  font-weight: 500;
  color: ${Color.typography};
`;

const LinkToFileImg = styled.img`
  height: 54px;
`;

interface Props {
  warehouseId: number;
  onEditClick: () => void;
  onConfigureLayoutButtonClick: () => void;
}

const WarehouseDetails: React.FC<Props> = ({ warehouseId, onEditClick, onConfigureLayoutButtonClick }) => {
  const [t] = useGlobalTranslation();

  const userHasAccessToEditWarehouseForm = useHasAccess('warehouses.update');
  const userHasAccessToDisableWarehouse = useHasAccess('warehouses.disable');
  const userHasAccessToConfigureWarehouseLayout = useHasAccess('warehouses.update');

  const { enqueueSuccessSnackbar } = useCustomSnackbar();

  const { trigger, triggerState } = useTrigger();

  const { warehouse } = useGetWarehouse(warehouseId, triggerState);

  const warehouseHeaderAddressValue = useMemo(() => warehouse && composeWarehouseAddress(warehouse.addressStreetAndHouseNumber, warehouse.addressPostCode, warehouse.addressCity), [warehouse]);

  const editWarehouseButtonRendered = useMemo(
    () => (userHasAccessToEditWarehouseForm ? <PrimaryButtonSmall onClick={onEditClick}>{t('message.edit')}</PrimaryButtonSmall> : ''),
    [onEditClick, t, userHasAccessToEditWarehouseForm]
  );

  const enqueueWarehouseDisabledSuccessSnackbar = useCallback(() => {
    enqueueSuccessSnackbar(t('feature.warehouse.message.warehouseSuccessfullyDisabled', { warehouseName: warehouse?.locationName }));
  }, [enqueueSuccessSnackbar, t, warehouse?.locationName]);

  const warehouseDisableSuccessCallback = useCallback(() => {
    enqueueWarehouseDisabledSuccessSnackbar();
    trigger();
  }, [enqueueWarehouseDisabledSuccessSnackbar, trigger]);

  const warehouseDisableCallbacks = useMemo(() => {
    return {
      success: warehouseDisableSuccessCallback
    };
  }, [warehouseDisableSuccessCallback]);

  const disableWarehouseButtonRendered = useMemo(
    () => (warehouse && !warehouse.disabled && userHasAccessToDisableWarehouse ? <DisableWarehouseButton warehouse={warehouse} callbacks={warehouseDisableCallbacks} /> : ''),
    [userHasAccessToDisableWarehouse, warehouse, warehouseDisableCallbacks]
  );

  const enqueueWarehouseEnabledSuccessSnackbar = useCallback(() => {
    enqueueSuccessSnackbar(t('feature.warehouse.message.warehouseSuccessfullyEnabled', { warehouseName: warehouse?.locationName }));
  }, [enqueueSuccessSnackbar, t, warehouse?.locationName]);

  const warehouseEnableSuccessCallback = useCallback(() => {
    enqueueWarehouseEnabledSuccessSnackbar();
    trigger();
  }, [enqueueWarehouseEnabledSuccessSnackbar, trigger]);

  const warehouseEnableCallbacks = useMemo(() => {
    return {
      success: warehouseEnableSuccessCallback
    };
  }, [warehouseEnableSuccessCallback]);

  const enableWarehouseButtonRendered = useMemo(() => {
    return warehouse && warehouse.disabled && userHasAccessToDisableWarehouse ? <EnableWarehouseButton warehouse={warehouse} callbacks={warehouseEnableCallbacks} /> : '';
  }, [userHasAccessToDisableWarehouse, warehouse, warehouseEnableCallbacks]);

  const configureWarehouseLayoutButtonRendered = useMemo(() => {
    return userHasAccessToConfigureWarehouseLayout ? <PrimaryButtonSmall onClick={onConfigureLayoutButtonClick}>{t('feature.warehouse.message.configureLayout')}</PrimaryButtonSmall> : '';
  }, [onConfigureLayoutButtonClick, t, userHasAccessToConfigureWarehouseLayout]);

  const warehouseSpaceInSquareMetersValue = useMemo(() => (warehouse && warehouse.warehouseSpaceInSquareMeters ? warehouse.warehouseSpaceInSquareMeters : ''), [warehouse]);

  const warehouseSecurityFeaturesValue = useMemo(() => {
    if (warehouse) {
      return warehouse.securityFeatures
        .split(',')
        .map((feature) => {
          const fromTranslation = t(`feature.warehouse.label.${feature}`);
          if (fromTranslation === `feature.warehouse.label.${feature}`) return feature;
          return fromTranslation;
        })
        .join(', ');
    }

    return '';
  }, [t, warehouse]);

  const emergencyAndEvacuationPlansFiles = useMemo(() => {
    if (warehouse) return transformResponseFilesToAppFiles(warehouse.emergencyAndEvacuationPlans ? warehouse.emergencyAndEvacuationPlans : []);
    return [];
  }, [warehouse]);

  const maintenanceScheduleFiles = useMemo(() => {
    if (warehouse) return transformResponseFilesToAppFiles(warehouse.maintenanceSchedule ? [warehouse.maintenanceSchedule] : []);
    return [];
  }, [warehouse]);

  if (!warehouse) return <div />;

  return (
    <Container>
      <WarehouseHeader>
        <WarehouseHeaderImage src={`data:image/jpeg;base64,${warehouse.warehouseImage.data}`} />
        <WarehouseHeaderInfoContainer>
          <WarehouseHeaderTextContainer>
            <WarehouseHeaderLocationName>{warehouse.locationName}</WarehouseHeaderLocationName>
            <WarehouseHeaderAddress>{warehouseHeaderAddressValue}</WarehouseHeaderAddress>
          </WarehouseHeaderTextContainer>
          <WarehouseHeaderActionContainer>
            {editWarehouseButtonRendered}
            {disableWarehouseButtonRendered}
            {enableWarehouseButtonRendered}
            {configureWarehouseLayoutButtonRendered}
          </WarehouseHeaderActionContainer>
        </WarehouseHeaderInfoContainer>
      </WarehouseHeader>
      <WarehouseContent>
        <WarehouseContentTableContainer>
          <WarehouseContentTable>
            <tbody>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.locationName')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.locationName}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.addressStreetAndHouseNumber')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.addressStreetAndHouseNumber}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.addressPostCode')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.addressPostCode}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.addressCity')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.addressCity}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.addressCountry')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{t(`country.label.${warehouse.addressCountry}`)}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.warehouseSpaceInSquareMeters')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouseSpaceInSquareMetersValue}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.securityFeatures')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouseSecurityFeaturesValue}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.accessRestrictions')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.accessRestrictions}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
              <WarehouseContentTableRow>
                <WarehouseContentTableRowLabel>{t('feature.warehouse.label.technicalEquipment')}</WarehouseContentTableRowLabel>
                <WarehouseContentTableRowValue>{warehouse.technicalEquipment}</WarehouseContentTableRowValue>
              </WarehouseContentTableRow>
            </tbody>
          </WarehouseContentTable>
        </WarehouseContentTableContainer>
        <FilesListingContainer>
          <FilesListingHeader>
            <LinkToFileImg src="/images/icons/file.png" />
            <LinkToFile>{t('feature.warehouse.label.emergencyAndEvacuationPlans')}</LinkToFile>
          </FilesListingHeader>
          <FilesList feedbackIfEmpty files={emergencyAndEvacuationPlansFiles} withDelete={false} />
        </FilesListingContainer>
        <FilesListingContainer>
          <FilesListingHeader>
            <LinkToFileImg src="/images/icons/file.png" />
            <LinkToFile>{t('feature.warehouse.label.maintenanceSchedule')}</LinkToFile>
          </FilesListingHeader>
          <FilesList feedbackIfEmpty files={maintenanceScheduleFiles} withDelete={false} />
        </FilesListingContainer>
      </WarehouseContent>
    </Container>
  );
};

export default withHasAccessToFeature(WarehouseDetails, 'warehouses.get');
