import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/Settings/Content';

const Settings = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(Settings);
