import { useHasAccess } from 'auth';
import useGetWarehouse from 'features/warehouses/hooks/useGetWarehouse';

interface Props {
  warehouseId: number;
}

const ConfigureLayout: React.FC<Props> = ({ warehouseId }) => {
  const { warehouse } = useGetWarehouse(warehouseId);

  const userHasAccessToConfigureWarehouseLayout = useHasAccess('warehouses.update');

  if (!userHasAccessToConfigureWarehouseLayout) {
    return <div>Forbidden</div>;
  }
  return <div>{warehouse?.locationName}</div>;
};

export default ConfigureLayout;
