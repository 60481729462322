import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Color, zIndices } from 'appConstants';

import { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { stopEventPropagation } from 'utils/event';
import useNavLinks from '../hooks/useNavLinks';

const Container = styled.nav`
  display: flex;
  color: ${Color.white};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2px;

  @media (max-width: 500px) {
    display: none;
  }
`;

const NavItem = styled(NavLink)`
  background: ${Color.topNav};
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  padding: 16px;

  &.active {
    background: ${Color.topNavActive};
    color: ${Color.topNavActiveTypography};
    font-weight: 900;
  }
`;

const SubmenuParent = styled.div`
  background: ${Color.topNav};
  flex: 1;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  padding: 16px;
  position: relative;
  cursor: pointer;

  &.active {
    > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const SubmenuList = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% - 0px);
  width: 100%;
  z-index: ${zIndices['topNav.submenu']};
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 2px solid ${Color.accentPrimary};
  padding: 8px 8px 16px;
  background: inherit;
`;

const SubmenuItem = styled(NavLink)`
  background: ${Color.accentPrimary};
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  padding: 8px;

  &.active {
    background: ${Color.topNavActive};
    color: ${Color.topNavActiveTypography};
    font-weight: 900;
  }
`;

const mapToSubmenuParent = (title: string, submenu: { title: string; path: string; accessible: boolean }[]) => {
  const addActiveClass = (el: HTMLElement) => {
    document.querySelectorAll('.topNav-submenu').forEach((e) => {
      e.classList.remove('active');
    });
    el.classList.add('active');
  };

  const handleSubmenuParentClick = (e: MouseEvent<HTMLDivElement>) => {
    stopEventPropagation(e);
    addActiveClass(e.currentTarget);
  };

  const someSubMenuItemsAccessible = submenu.some((item) => item.accessible);

  if (!someSubMenuItemsAccessible) return '';

  return (
    <SubmenuParent key={`submenuParent-${title}`} onClick={handleSubmenuParentClick} className="topNav-submenu">
      {title}
      <SubmenuList>
        {submenu.map(({ path: submenuPath, title: submenuTitle, accessible }) => {
          if (!accessible) return '';
          return (
            <SubmenuItem to={submenuPath} key={`submenuItem-${submenuTitle}`}>
              {submenuTitle}
            </SubmenuItem>
          );
        })}
      </SubmenuList>
    </SubmenuParent>
  );
};

const mapToNavItem = (title: string, path: string) => {
  return (
    <NavItem to={path} key={`topNavItem-${title}`}>
      {title}
    </NavItem>
  );
};

const TopNav = () => {
  const { topNavLinks: links } = useNavLinks();

  const submenuRef = useRef<HTMLDivElement>(null);

  const removeActiveClassFromSubmenusParents = () => {
    submenuRef.current?.querySelectorAll('.topNav-submenu').forEach((e) => {
      e.classList.remove('active');
    });
  };

  const addWindowClickEventHandler = useCallback(() => {
    document.addEventListener('click', removeActiveClassFromSubmenusParents);
  }, []);

  const removeWindowClickEventHandler = useCallback(() => {
    document.removeEventListener('click', removeActiveClassFromSubmenusParents);
  }, []);

  useEffect(() => {
    addWindowClickEventHandler();

    return () => {
      removeWindowClickEventHandler();
    };
  });

  return (
    <Container ref={submenuRef}>
      {links.map(({ path = '', title, submenu, accessible }) => {
        if (!accessible) return '';
        if (submenu) {
          return mapToSubmenuParent(title, submenu);
        }
        return mapToNavItem(title, path);
      })}
    </Container>
  );
};

export default TopNav;
