import { AxiosResponse } from 'axios';
import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage, StatusMessageLarge } from 'components/StatusMessage';
import { PrimaryButtonLarge, PrimaryButtonSmall } from 'components/UI/PrimaryButton';
import { useGetClients } from 'features/clients/hooks';
import { withHasAccessToFeature } from 'features/hocComponents';
import { FormFieldsMeta, useManageFormFields } from 'hooks/form2';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useManageRequests from 'hooks/request/useManageRequests';
import { useStatus } from 'hooks/status';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { Request } from 'types';
import { preventEventDefault } from 'utils/event';
import { generateNumberSelectOptions } from 'utils/form';

import useCreateNotification from '../hooks/useCreateNotification';
import type { CreateNotificationRequest, CreateNotificationResponse } from '../types';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  .textField,
  .selectField,
  .dateTimeField {
    max-width: calc(25% - 16px);
    height: 80px;

    @media (max-width: 1000px) {
      max-width: calc(50% - 16px);
    }

    @media (max-width: 767px) {
      max-width: none;
      height: auto;
      margin-bottom: 16px;
    }
  }
`;

const FormSubmissionContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;

  @media (max-width: 1000px) {
    display: block;
  }
`;

const ButtonsSection = styled.div`
  width: calc(50% - 16px);
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SubmitContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const CreateNewOrderButton = styled(PrimaryButtonSmall)`
  width: fit-content;
`;

const PDFSection = styled.div`
  width: calc(50% - 16px);
  height: 400px;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 24px auto;
    max-width: 500px;
  }
`;
const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;

const itemLabelResolver = (itemName: string) => `feature.order.label.select.item.${itemName}`;

const numOfPackagingPiecesOptions = generateNumberSelectOptions(20);

const GoodsNotificationForm = () => {
  const { clients } = useGetClients();

  const clientsAsSelectItems = useMemo(() => {
    return clients.map((client) => ({ label: client.name, value: String(client.id) }));
  }, [clients]);

  const formFieldsMeta: FormFieldsMeta = useMemo(
    () => [
      { label: 'client', name: 'clientId', type: 'select', labelFixed: true, items: clientsAsSelectItems },
      {
        name: 'announcementType',
        label: 'announcementType',
        type: 'select',
        itemLabelResolver,
        items: [
          { label: 'email', value: 'email' },
          { label: 'phone', value: 'phone' }
        ]
      },
      {
        name: 'shipmentType',
        label: 'shipmentType',
        type: 'select',
        itemLabelResolver,
        items: [
          { label: 'euro_pallet', value: 'euro_pallet' },
          { label: 'packing_piece', value: 'packing_piece' },
          { label: 'package', value: 'package' }
        ]
      },
      {
        name: 'numOfPackagingPieces',
        label: 'numOfPackagingPieces',
        type: 'select',
        items: numOfPackagingPiecesOptions,
        labelFixed: true
      },
      { name: 'warehouseProperties', label: 'warehouseProperties', type: 'text' },
      { name: 'carrier', label: 'carrier', type: 'text' },
      { name: 'clientOrderNumber', label: 'clientOrderNumber', type: 'text' },
      { name: 'deliveryNoteNumber', label: 'deliveryNoteNumber', type: 'text' },
      { name: 'recipient', label: 'recipient', type: 'text' },
      {
        name: 'pickupTimeWindow',
        label: 'pickupTimeWindow',
        type: 'dateTime'
      }
    ],
    [clientsAsSelectItems]
  );

  const { status: storageSpaceIsAvailable, changeToSucceeded: makeStorageSpaceAvailable, changeToFailed: makeStorageSpaceUnavailable } = useStatus();
  const { status: orderIsCreated, changeToSucceeded: changeOrderCreatedToSucceeded, changeToFailed: changeOrderCreatedToFailed, changeToNotInitiated: changeOrderCreatedToNotInitiated } = useStatus();
  const [createdOrderNumber, setCreatedOrderNumber] = useState<number>();
  const [pdfURL, setPdfURL] = useState('');
  const [pdfVisible, setPdfVisible] = useState(false);

  const createNotification = useCreateNotification();

  const handleFormFieldChange = useCallback(() => {
    changeOrderCreatedToNotInitiated();
  }, [changeOrderCreatedToNotInitiated]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFieldsMeta,
      formFieldLabelResolver,
      handleFormFieldChange
    }),
    [formFieldsMeta, handleFormFieldChange]
  );

  const { formFieldsRendered, getFormFieldsWithValues, emptyAllFormFields } = useManageFormFields(manageFormFieldsParams);

  const [t] = useGlobalTranslation();

  const { doRequest } = useManageRequests();

  const showPdf = useCallback(() => {
    setPdfVisible(true);
  }, []);

  const checkStorageSpaceAvailability = useCallback(() => {
    const callbacks = {
      success: makeStorageSpaceAvailable,
      failure: makeStorageSpaceUnavailable
    };

    doRequest(new Request('get', ''), callbacks);
  }, [doRequest, makeStorageSpaceAvailable, makeStorageSpaceUnavailable]);

  const pdf = useMemo(() => (pdfVisible ? <iframe title="PDF Viewer" src={`data:application/pdf;base64,${pdfURL}`} width="100%" height="100%" /> : ''), [pdfURL, pdfVisible]);

  const handleSuccessfulOrderCreation = useCallback(
    (res: AxiosResponse<CreateNotificationResponse>) => {
      setCreatedOrderNumber(res.data.orderNumber);

      setPdfURL(res.data.orderPDF);
      showPdf();
      changeOrderCreatedToSucceeded();
    },
    [changeOrderCreatedToSucceeded, showPdf]
  );

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const callbacks = {
        success: handleSuccessfulOrderCreation,
        failure: changeOrderCreatedToFailed
      };

      const data = getFormFieldsWithValues() as unknown as CreateNotificationRequest;

      createNotification(data, callbacks);
    },
    [changeOrderCreatedToFailed, createNotification, getFormFieldsWithValues, handleSuccessfulOrderCreation]
  );

  const handleCreateNewOrderButtonClick = useCallback(() => {
    changeOrderCreatedToNotInitiated();
    emptyAllFormFields();
  }, [changeOrderCreatedToNotInitiated, emptyAllFormFields]);

  const createNewOrderButtonVisible = useMemo(() => orderIsCreated === 'succeeded', [orderIsCreated]);

  const createNewOrderButtonRendered = useMemo(
    () => (createNewOrderButtonVisible ? <CreateNewOrderButton onClick={handleCreateNewOrderButtonClick}>{t('feature.goodsNotification.form.button.newOrder.title')}</CreateNewOrderButton> : ''),
    [createNewOrderButtonVisible, handleCreateNewOrderButtonClick, t]
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormFieldsContainer>{formFieldsRendered}</FormFieldsContainer>
      <FormSubmissionContainer>
        <ButtonsSection>
          <QueryButton
            button={<PrimaryButtonLarge onClick={checkStorageSpaceAvailability}>{t('feature.goodsNotification.form.checkWarehouseCapacity')}</PrimaryButtonLarge>}
            StatusMessage={StatusMessage}
            statusMessageProps={{
              status: storageSpaceIsAvailable,
              failureText: t('feature.goodsNotification.form.noStorageSpace'),
              successText: t('feature.goodsNotification.form.storageSpaceAvailable')
            }}
            status={storageSpaceIsAvailable}
          />
          <SubmitContainer>
            <QueryButton
              button={<PrimaryButtonLarge type="submit">{t('feature.goodsNotification.form.button.title')}</PrimaryButtonLarge>}
              StatusMessage={StatusMessageLarge}
              statusMessageProps={{
                status: orderIsCreated,
                failureText: t('message.errorOccurred'),
                successText: t('feature.goodsNotification.form.submissionSuccess', { orderNumber: createdOrderNumber })
              }}
              status={orderIsCreated}
            />
            {createNewOrderButtonRendered}
          </SubmitContainer>
        </ButtonsSection>
        <PDFSection>{pdf}</PDFSection>
      </FormSubmissionContainer>
    </Form>
  );
};

export default withHasAccessToFeature(GoodsNotificationForm, 'notification.create');
