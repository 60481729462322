import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color, paths } from 'appConstants';
import CenteredTextSmall from 'components/UI/Text';
import useGetServerHealth from 'hooks/server/useGetServerHealth';

const StyledLink = styled(Link)`
  font-size: 0.875rem;
  color: ${Color.accentPrimary};
`;

interface Props {
  children: React.ReactNode;
}

const FormLink: React.FC<Props> = ({ children }) => {
  const { serverIsOn } = useGetServerHealth();

  if (serverIsOn) {
    return <StyledLink to={paths.resetPassword}>{children}</StyledLink>;
  }

  return <CenteredTextSmall>{children}</CenteredTextSmall>;
};

export default FormLink;
