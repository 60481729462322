import React from 'react';
import styled from 'styled-components';

export interface SVGProps {
  width?: number;
  marginLeft?: number;
  onClick?: () => void;
  fill?: string;
  className?: string;
}

const SVG = styled.svg<{ $marginLeft: number; width: number }>`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  cursor: pointer;
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
  transition: all 0.3s;

  &.rotate {
    transform: rotate(180deg);
  }
`;

const withSVG = (OriginalComponent: React.FC, viewBox = '0 0 512 512') => {
  const WithSVG: React.FC<SVGProps> = ({ width = 16, marginLeft = 0, onClick, fill = 'black', className }) => (
    <SVG $marginLeft={marginLeft} width={width} onClick={onClick} fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} className={className} aria-hidden="true">
      <OriginalComponent />
    </SVG>
  );

  return WithSVG;
};

export default withSVG;
