import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import StoreProvider from 'redux/StoreProvider';
import I18nextProvider from 'utils/i18n/I18nextProvider';
import LocalizationProvider from 'utils/muix/LocalizationProvider';
import SnackbarProvider from 'utils/snackbar/SnackbarProvider';
import MUIThemeProvider from 'utils/theme/MUIThemeProvider';

import App from './App';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider>
        <StoreProvider>
          <MUIThemeProvider>
            <SnackbarProvider>
              <LocalizationProvider>
                <App />
              </LocalizationProvider>
            </SnackbarProvider>
          </MUIThemeProvider>
        </StoreProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
