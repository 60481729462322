import { createSlice } from '@reduxjs/toolkit';

interface State {
  pageIsLoading: boolean;
}

const initialState: State = {
  pageIsLoading: false
};

export interface UpdateIsPageLoadingAction {
  payload: boolean;
}

const pageLoadingSlice = createSlice({
  name: 'pageLoading',
  initialState,
  reducers: {
    UpdateIsPageLoadingAction: (state, action: UpdateIsPageLoadingAction) => {
      state.pageIsLoading = action.payload;
    }
  }
});

export const { UpdateIsPageLoadingAction } = pageLoadingSlice.actions;

export default pageLoadingSlice.reducer;
