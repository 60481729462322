import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import type { Order } from 'features/orders/types';
import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import type { GetRecentNotificationsRequest } from '../types';

const useGetRecentNotifications = (data: GetRecentNotificationsRequest, callbacks?: PromiseCallbacks) => {
  const { doRequest } = useManageRequests();
  const { notificationGetRecent } = useGetRequests();

  const [notifications, setNotifications] = useState<Order[]>([]);

  const getNotificationsSuccessCallback = useCallback((res: AxiosResponse<Order[]>) => {
    setNotifications(res.data);
  }, []);

  const get = useCallback(() => {
    const extendedCallbacks = {
      ...callbacks,
      success: (res: AxiosResponse<Order[]>) => {
        callbacks?.success?.();
        getNotificationsSuccessCallback(res);
      }
    };

    doRequest(notificationGetRecent(data), extendedCallbacks);
  }, [callbacks, data, doRequest, getNotificationsSuccessCallback, notificationGetRecent]);

  useEffect(() => {
    get();
  }, [get]);

  return {
    notifications
  };
};

export default useGetRecentNotifications;
