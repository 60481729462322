const europeanCountries = [
  'albania',
  'andorra',
  'armenia',
  'austria',
  'azerbaijan',
  'belarus',
  'belgium',
  'bosnia_and_herzegovina',
  'bulgaria',
  'croatia',
  'cyprus',
  'czech_republic',
  'denmark',
  'estonia',
  'finland',
  'france',
  'georgia',
  'germany',
  'greece',
  'hungary',
  'iceland',
  'ireland',
  'italy',
  'kazakhstan',
  'kosovo',
  'latvia',
  'liechtenstein',
  'lithuania',
  'luxembourg',
  'malta',
  'moldova',
  'monaco',
  'montenegro',
  'netherlands',
  'north_macedonia',
  'norway',
  'poland',
  'portugal',
  'romania',
  'russia',
  'san_marino',
  'serbia',
  'slovakia',
  'slovenia',
  'spain',
  'sweden',
  'switzerland',
  'turkey',
  'ukraine',
  'united_kingdom',
  'vatican_city'
];

export default europeanCountries;
