import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { PromiseCallbacks } from 'types';

const useWarehouseOrder = () => {
  const { incomingGoodsCreate } = useGetRequests();
  const { doRequest } = useManageRequests();

  const create = useCallback(
    (data: FormData, callbacks: PromiseCallbacks, defaultPageLoading: boolean) => {
      doRequest(incomingGoodsCreate(data), callbacks, defaultPageLoading);
    },
    [doRequest, incomingGoodsCreate]
  );

  return create;
};

export default useWarehouseOrder;
