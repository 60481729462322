import styled from 'styled-components';
import Title from './Title';

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 32px;
`;

const Image = styled.img`
  width: 50px;
`;

const StyledTitle = styled(Title)`
  text-align: left;
`;

interface Props {
  imageSrc: string;
  titleText: string;
}

const PageTitle: React.FC<Props> = ({ imageSrc, titleText }) => {
  return (
    <Container>
      <Image src={imageSrc} />
      <StyledTitle>{titleText}</StyledTitle>
    </Container>
  );
};

export default PageTitle;
