import { styled } from 'styled-components';

import { useAuth } from 'auth';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import SettingsSection from './SettingSection';

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const InfoTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  width: 200px;
`;

const Info = styled.div`
  font-size: 1rem;
`;

const MyProfileSettings = () => {
  const [t] = useGlobalTranslation();

  const { user } = useAuth();

  return (
    <SettingsSection title={t('page.settings.section.myProfile.title')}>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.firstName')}</InfoTitle>
        <Info>{user.firstName}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.lastName')}</InfoTitle>
        <Info>{user.lastName}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.phoneNumber')}</InfoTitle>
        <Info>{user.phoneNumber}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.jobTitle')}</InfoTitle>
        <Info>{user.jobTitle}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.email')}</InfoTitle>
        <Info>{user.email}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.myProfile.label.username')}</InfoTitle>
        <Info>{user.name}</Info>
      </InfoContainer>
    </SettingsSection>
  );
};

export default MyProfileSettings;
