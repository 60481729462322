import { FormControlLabel, Switch } from 'lib/componentLibrary';
import { ChangeEvent, useCallback } from 'lib/react';

interface Props {
  value?: boolean;
  onChange?: (_value: boolean) => void;
  label?: string;
  disabled?: boolean;
}

const SwitchField: React.FC<Props> = ({ disabled, label, value = false, onChange }) => {
  const handleChange = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange?.(checked);
    },
    [onChange]
  );

  return <FormControlLabel className="switchField" disabled={disabled} label={label} control={<Switch onChange={handleChange} checked={value} />} />;
};

export default SwitchField;
