import type { Language } from 'types';

const LOCAL_STORAGE_LANGUAGE_KEY = 'LOCAL_STORAGE_LANGUAGE_KEY';

export const saveSelectedLanguage = (language: Language) => {
  localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, language);
};

export const getInitialLanguage = (): Language => {
  return (localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) as Language) || 'de';
};
