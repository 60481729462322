import styled from 'styled-components';

import Footer from 'components/Footer';
import { Topbar } from 'components/Navigation';

export interface Props {
  children: React.ReactNode;
}

const FullPageHeight = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Main = styled.div`
  padding: 16px;
`;

const Base: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Topbar />
      <FullPageHeight>
        <Main>{children}</Main>
        <Footer />
      </FullPageHeight>
    </>
  );
};

export default Base;
