import { Color } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, componentLibStyled } from 'lib/componentLibrary';
import { useCallback } from 'lib/react';

const StyledFormControl = componentLibStyled(FormControl)`
  &.error * {
    color: ${Color.failure} !important;
  }
`;

interface Props {
  items: { label: string; value: string }[];
  value?: string;
  onChange?: (_value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  labelFixed?: boolean;
  itemLabelResolver?: (_label: string) => string;
}

const SelectField: React.FC<Props> = ({ items, disabled, label, error, placeholder, value, onChange, labelFixed, itemLabelResolver }) => {
  const [t] = useGlobalTranslation();

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const printSelectMenuItemLabel = useCallback(
    (itemLabel: string) => {
      if (labelFixed) return itemLabel;
      if (!itemLabelResolver) throw new Error('A itemLabelResolver param must be provided or labelFixed param must be true');
      return t(itemLabelResolver?.(itemLabel));
    },
    [labelFixed, itemLabelResolver, t]
  );

  return (
    <StyledFormControl fullWidth disabled={disabled} error={!!error} className="selectField">
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select<string> labelId={`select-${label}`} variant="outlined" label={label} onChange={handleChange} value={value} placeholder={placeholder}>
        {items.map((item) => (
          <MenuItem key={`${label}-${item.value}`} value={item.value}>
            {printSelectMenuItemLabel(item.label)}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default SelectField;
