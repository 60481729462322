import { IconButton } from '@mui/material';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const EditImage = styled.img`
  width: 20px;
`;

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const EditButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <EditImage src="/images/icons/edit.png" />
    </IconButton>
  );
};

export default EditButton;
