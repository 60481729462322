import { MuiColorInput, MuiColorInputColors, matchIsValidColor } from 'mui-color-input';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const StyledMuiColorInput = styled(MuiColorInput)<{ $borderColor: string }>`
  .Mui-focused fieldset {
    border-color: ${({ $borderColor }) => $borderColor} !important;
  }
`;

const SELECTED_COLOR_IS_VALID_INITIAL_STATE = true;

interface Props {
  selectedColor: string;
  onChange: (_value: string, _colors: MuiColorInputColors) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const ColorInput: React.FC<Props> = ({ onChange, selectedColor, onClick }) => {
  const [t] = useGlobalTranslation();

  const [selectedColorIsValid, setSelectedColorIsValid] = useState(SELECTED_COLOR_IS_VALID_INITIAL_STATE);

  const handleColoInputChange = useCallback(
    (value: string, colors: MuiColorInputColors) => {
      onChange(value, colors);
      setSelectedColorIsValid(matchIsValidColor(value));
    },
    [onChange]
  );

  const colorInputErrorProps = useMemo(() => {
    const isError = !selectedColorIsValid;
    return {
      error: isError,
      helperText: isError ? t('form.error.invalidColor') : ''
    };
  }, [selectedColorIsValid, t]);

  return <StyledMuiColorInput onClick={onClick} format="rgb" $borderColor={selectedColor} value={selectedColor} onChange={handleColoInputChange} {...colorInputErrorProps} />;
};

export default ColorInput;
