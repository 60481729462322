import { ThemeProvider } from '@mui/material';
import theme from './initialize';

interface Props {
  children: React.ReactNode;
}

const MUIThemeProvider: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MUIThemeProvider;
