import { useSelector } from 'react-redux';

import type { RootState } from 'redux/types';

const serverHealthSelector = (state: RootState) => state.serverHealth.serverIsOn;

const useGetServerHealth = () => {
  const serverIsOn = useSelector(serverHealthSelector);

  return {
    serverIsOn
  };
};

export default useGetServerHealth;
