import { useHasAccess } from 'auth';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { AddUserForm, UsersList } from 'features/usersManagement';

import SettingsSection from '../SettingSection';
import EditUserFormWrapper from './components/EditUserFormWrapper';

const Container = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 40px 40px 16px;
  position: relative;

  @media (max-width: 375px) {
    padding: 40px 8px 16px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 1160px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

const activeTabRules = `
 background: ${Color.accentPrimary};
 color: ${Color.white};
`;

const Tab = styled.div<{ $active: boolean }>`
  padding: 8px 40px;
  font-size: 1.125rem;
  background: transparent;
  border: 2px solid ${Color.accentPrimary};
  color: ${Color.accentPrimary};
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s;
  white-space: nowrap;

  ${({ $active }) => ($active ? activeTabRules : '')}

  &:hover {
    ${activeTabRules}
  }

  &:not(:last-child) {
    border-right: none;
  }

  @media (max-width: 880px) {
    &:not(:last-child) {
      border-right: 2px solid ${Color.accentPrimary};
    }
  }

  @media (max-width: 550px) {
    white-space: wrap;
  }

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 8px 33px;
  }
`;

const ViewContainer = styled.div`
  margin-top: 32px;
`;

type OpenedView = 'usersList' | 'addUserForm' | 'editUserForm' | 'no_view';

const UsersManagement = () => {
  const [t] = useGlobalTranslation();

  const userHasAccessToSeeUsersList = useHasAccess('user.getAll');
  const userHasAccessToAddUser = useHasAccess('user.create');

  const [openedView, setOpenedView] = useState<OpenedView>('no_view');

  const [selectedEditableUserId, setSelectedEditableUserId] = useState<number | null>(null);
  const [selectedEditableUsername, setSelectedEditableUsername] = useState('');

  const userHasMinAccess = useMemo(() => userHasAccessToAddUser || userHasAccessToSeeUsersList, [userHasAccessToAddUser, userHasAccessToSeeUsersList]);

  const openUsersListView = useCallback(() => {
    setOpenedView('usersList');
  }, []);

  const openAddUserFormView = useCallback(() => {
    setOpenedView('addUserForm');
  }, []);

  const openEditUserFormView = useCallback((userId: number, username: string) => {
    setOpenedView('editUserForm');
    setSelectedEditableUserId(userId);
    setSelectedEditableUsername(username);
  }, []);

  const view = useMemo(() => {
    if (openedView === 'usersList') {
      return <UsersList openEdit={openEditUserFormView} />;
    }
    if (openedView === 'addUserForm') {
      return <AddUserForm />;
    }
    if (openedView === 'editUserForm' && selectedEditableUserId !== null) {
      return <EditUserFormWrapper userId={selectedEditableUserId} username={selectedEditableUsername} />;
    }
    if (openedView === 'no_view') return '';

    throw new Error('Not a valid form');
  }, [openEditUserFormView, openedView, selectedEditableUserId, selectedEditableUsername]);

  const usersListViewActive = useMemo(() => openedView === 'usersList', [openedView]);
  const addUserFormViewActive = useMemo(() => openedView === 'addUserForm', [openedView]);

  const usersListTabRendered = useMemo(() => {
    if (userHasAccessToSeeUsersList) {
      return (
        <Tab $active={usersListViewActive} onClick={openUsersListView}>
          {t('page.settings.section.userManagement.tab.usersList')}
        </Tab>
      );
    }
    return '';
  }, [openUsersListView, t, userHasAccessToSeeUsersList, usersListViewActive]);

  const addUserTabRendered = useMemo(() => {
    if (userHasAccessToAddUser) {
      return (
        <Tab $active={addUserFormViewActive} onClick={openAddUserFormView}>
          {t('page.settings.section.userManagement.tab.addNewUser')}
        </Tab>
      );
    }

    return '';
  }, [addUserFormViewActive, openAddUserFormView, t, userHasAccessToAddUser]);

  const setInitialView = useCallback(() => {
    if (userHasAccessToSeeUsersList) {
      openUsersListView();
      return;
    }
    if (userHasAccessToAddUser) {
      openAddUserFormView();
    }
  }, [openAddUserFormView, openUsersListView, userHasAccessToAddUser, userHasAccessToSeeUsersList]);

  useEffect(() => {
    setInitialView();
  }, [setInitialView]);

  if (!userHasMinAccess) return <div />;

  return (
    <SettingsSection title={t('page.settings.section.userManagement.title')}>
      <Container>
        <ActionsContainer>
          <Tabs>
            {usersListTabRendered}
            {addUserTabRendered}
          </Tabs>
        </ActionsContainer>
        <ViewContainer>{view}</ViewContainer>
      </Container>
    </SettingsSection>
  );
};

export default UsersManagement;
