import { citiesAndPostCodes } from 'features/warehouses/data';

const findCityWithPostCode = (postCode: string) => {
  let left = 0;
  let right = citiesAndPostCodes.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (citiesAndPostCodes[mid].postCode === postCode) {
      return citiesAndPostCodes[mid].city;
    }

    if (postCode < citiesAndPostCodes[mid].postCode) {
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  return -1;
};

export default findCityWithPostCode;
