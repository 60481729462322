import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { useHasAccess } from 'auth';
import Message from 'components/UI/Message';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import PageTitle from 'components/UI/Title/PageTitle';
import { IncomingGoodsTable } from 'features/incomingGoods/components';
import { GetIncomingGoodsResponse } from 'features/incomingGoods/types';
import { Order } from 'features/orders/types';
import { OutgoingGoodsForm } from 'features/outgoingGoods/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useTrigger } from 'hooks/trigger';

const Container = styled.div`
  padding: 0 32px;
  display: flex;
  gap: 48px;

  @media (max-width: 1240px) {
    display: block;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const TableContainer = styled.div`
  width: calc(50% - 16px);

  @media (max-width: 1240px) {
    width: 100%;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PDFContainer = styled.div`
  margin-top: 39px;
  width: calc(50% - 16px);
  height: 400px;

  @media (max-width: 1240px) {
    width: 100%;
    margin: 24px auto;
    max-width: 500px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 40px;

  @media (max-width: 675px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Content = () => {
  const [t] = useGlobalTranslation();

  const [highlightedIncomingGood, setHighlightedIncomingGood] = useState<GetIncomingGoodsResponse | null>(null);
  const [clickedIncomingGood, setClickedIncomingGood] = useState<GetIncomingGoodsResponse | null>(null);
  const [outgoingGoodsFormOpen, setOutgoingGoodsFormOpen] = useState(false);

  const { triggerState: incomingGoodsTableTriggerRefetchState, trigger: triggerIncomingGoodsTableState } = useTrigger();

  const openOutgoingGoodsForm = useCallback(() => setOutgoingGoodsFormOpen(true), []);
  const closeOutgoingGoodsForm = useCallback(() => setOutgoingGoodsFormOpen(false), []);

  const pdfVisible = useMemo(() => highlightedIncomingGood !== null, [highlightedIncomingGood]);
  const pdfURL = useMemo(() => highlightedIncomingGood?.orderPdf || '', [highlightedIncomingGood?.orderPdf]);

  const pdf = useMemo(() => (pdfVisible ? <iframe title="PDF Viewer" src={`data:application/pdf;base64,${pdfURL}`} width="100%" height="100%" /> : ''), [pdfURL, pdfVisible]);

  const orderNumber = useMemo(() => clickedIncomingGood?.orderData.orderNumber, [clickedIncomingGood?.orderData.orderNumber]);
  const order = useMemo(() => clickedIncomingGood?.orderData, [clickedIncomingGood?.orderData]);

  const actionsVisible = useMemo(() => clickedIncomingGood !== null, [clickedIncomingGood]);
  const currentOrderMessage = useMemo(() => `${t('message.order')} ${orderNumber}`, [orderNumber, t]);

  const hasAccessToCreateLoadingReport = useHasAccess('pick.create');
  const createLoadingReportButtonRendered = useMemo(() => {
    if (hasAccessToCreateLoadingReport) return <PrimaryButtonLarge onClick={openOutgoingGoodsForm}>{t('message.createLoadingReport')}</PrimaryButtonLarge>;
    return '';
  }, [hasAccessToCreateLoadingReport, openOutgoingGoodsForm, t]);

  const actions = useMemo(() => {
    return actionsVisible ? (
      <ActionsContainer>
        <Message>{currentOrderMessage}</Message>
        {createLoadingReportButtonRendered}
      </ActionsContainer>
    ) : (
      ''
    );
  }, [actionsVisible, createLoadingReportButtonRendered, currentOrderMessage]);

  const outgoingGoodsFormRequestCallbacks = useMemo(
    () => ({
      success: () => {
        triggerIncomingGoodsTableState();
      }
    }),
    [triggerIncomingGoodsTableState]
  );

  const outgoingGoodsForm = useMemo(
    () => (outgoingGoodsFormOpen ? <OutgoingGoodsForm scopeOfShipmentCallbacks={outgoingGoodsFormRequestCallbacks} order={order as Order} /> : ''),
    [outgoingGoodsFormOpen, outgoingGoodsFormRequestCallbacks, order]
  );

  const handleIncomingGoodHover = useCallback((e: MouseEvent, item: GetIncomingGoodsResponse) => {
    setHighlightedIncomingGood(item);
  }, []);

  const handleIncomingGoodClick = useCallback(
    (_e: MouseEvent, item: GetIncomingGoodsResponse) => {
      setClickedIncomingGood(item);
      closeOutgoingGoodsForm();
    },
    [closeOutgoingGoodsForm]
  );

  return (
    <div>
      <PageTitle imageSrc="/images/features/outgoing_package.png" titleText={t('page.outgoingGoods.header')} />
      <Container>
        <TableContainer>
          <IncomingGoodsTable refetchTrigger={incomingGoodsTableTriggerRefetchState} height={210} handleIncomingGoodHover={handleIncomingGoodHover} handleIncomingGoodClick={handleIncomingGoodClick} />
          {actions}
          {outgoingGoodsForm}
        </TableContainer>
        <PDFContainer>{pdf}</PDFContainer>
      </Container>
    </div>
  );
};

export default Content;
