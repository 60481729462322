import styled from 'styled-components';

import { SectionTitleWithCloseButton } from 'components/UI/Title';
import { AddWarehouseForm } from 'features/warehouses/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const AddWarehouseFormContainer = styled.div`
  margin: 32px 0 16px;
`;

interface Props {
  close: () => void;
}

const AddWarehouse: React.FC<Props> = ({ close }) => {
  const [t] = useGlobalTranslation();

  return (
    <div>
      <SectionTitleWithCloseButton title={t('feature.warehouse.addWarehouse')} close={close} />
      <AddWarehouseFormContainer>
        <AddWarehouseForm cancel={close} />
      </AddWarehouseFormContainer>
    </div>
  );
};

export default AddWarehouse;
