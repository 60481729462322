import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

const useResetPassword = () => {
  const { doRequest } = useManageRequests();
  const { resetPassword, resetPasswordEMail } = useGetRequests();

  const sendEMail = useCallback(
    (email: string, callbacks?: PromiseCallbacks) => {
      const data = { email };
      const request = resetPasswordEMail(data);

      doRequest(request, callbacks);
    },
    [doRequest, resetPasswordEMail]
  );

  const reset = useCallback(
    (password: string, passwordRepetition: string, token: string, callbacks?: PromiseCallbacks) => {
      const data = {
        password,
        passwordRepetition,
        token
      };
      const request = resetPassword(data);
      doRequest(request, callbacks);
    },
    [doRequest, resetPassword]
  );

  return {
    reset,
    sendEMail
  };
};

export default useResetPassword;
