import { createSlice } from '@reduxjs/toolkit';

import { Accesses, Roles } from 'auth/types';

interface State {
  isAuthenticated: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  jobTitle: string;
  name: string;
  email: string;
  roles: Roles;
  accesses: Accesses;
}

const initialState: State = {
  isAuthenticated: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  jobTitle: '',
  name: '',
  email: '',
  roles: [],
  accesses: []
};

interface UpdateUserInfoAction {
  payload: { firstName: string; lastName: string; phoneNumber: string; jobTitle: string; name: string; email: string; roles: Roles; accesses: Accesses };
}

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authenticateAction: (state) => {
      state.isAuthenticated = true;
    },
    updateUserInfo: (state, action: UpdateUserInfoAction) => {
      const { email, name, roles, firstName, lastName, phoneNumber, jobTitle, accesses } = action.payload;
      state.email = email;
      state.name = name;
      state.roles = roles;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phoneNumber = phoneNumber;
      state.jobTitle = jobTitle;
      state.accesses = accesses;
    },
    logoutAction: () => {
      return initialState;
    }
  }
});

export const { authenticateAction, updateUserInfo, logoutAction } = authenticationSlice.actions;

export default authenticationSlice.reducer;
