import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/Home/Content';

const Home = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(Home);
