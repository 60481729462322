import { FormControl, Select, styled as muiStyled, TextField, TextFieldProps } from '@mui/material';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { Color, paths } from 'appConstants';
import { UploadInputWithIcon } from 'components/Files';
import { Form } from 'components/Form';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import { SectionTitle } from 'components/UI/Title';
import { GoodsAcceptanceData } from 'features/incomingGoods/types';
import { Order } from 'features/orders/types';
import { useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import { preventEventDefault } from 'utils/event';
import { generateCompletenessSelectOptions, generateNumberSelectOptions } from 'utils/form';

const Container = styled.div`
  margin: 24px auto;
`;

const TitleContainer = styled.div`
  margin-bottom: 8px;
  text-align: left;

  * {
    text-align: left;
  }
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  max-width: calc(50% - 16px);
  height: 80px;

  @media(max-width: 767px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  max-width: calc(50% - 16px);
  height: 80px;

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 767px) {
    max-width: none;
    height: auto;
    margin-bottom: 16px
  }
`;

const FormSubmissionContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledUploadInputWithIcon = styled(UploadInputWithIcon)`
  max-width: calc(50% - 16px);
  align-self: self-start;
  min-height: 80px;

  @media (max-width: 767px) {
    max-width: none;
    min-heigh: none;
    margin-bottom: 16px;
  }
`;

const DenyGoodsButton = styled(PrimaryButtonLarge)`
  background: ${Color.failure};
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const numOfPalletsOptions = generateNumberSelectOptions(10);
const numOfPackagingPiecesOptions = generateNumberSelectOptions(20);
const numOfPackagesOptions = generateNumberSelectOptions(50);

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;

const selectItemLabelResolver = (itemName: string) => `message.${itemName}`;

interface Props {
  setGoodsAcceptanceData: (_goodsAcceptanceData: GoodsAcceptanceData) => void;
  order: Order;
}

const GoodsAcceptanceForm: React.FC<Props> = ({ setGoodsAcceptanceData, order }) => {
  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'recipient', name: 'recipient', type: 'text', initialValue: order.recipient },
      {
        name: 'numOfPallets',
        label: 'numOfPallets',
        type: 'select',
        items: numOfPalletsOptions,
        labelFixed: true
      },
      {
        name: 'numOfPackagingPieces',
        label: 'numOfPackagingPieces',
        type: 'select',
        items: numOfPackagingPiecesOptions,
        labelFixed: true
      },
      {
        name: 'numOfPackages',
        label: 'numOfPackages',
        type: 'select',
        items: numOfPackagesOptions,
        labelFixed: true
      },
      { name: 'deliveryNote', type: 'file', label: 'deliveryNote', labelFixed: true, iconSrc: '/images/icons/file.png' },
      { name: 'damages', type: 'file', label: 'damages', iconSrc: '/images/icons/camera.png', multipleFiles: true },
      { name: 'completeness', label: 'completeness', type: 'select', items: generateCompletenessSelectOptions() },
      { name: 'warehouseProperties', label: 'warehouseProperties', type: 'text', initialValue: order.warehouseProperties }
    ];
  }, [order.recipient, order.warehouseProperties]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormField: Select,
      SelectFormControl: StyledSelectFormControl,
      UploadFileComponent: StyledUploadInputWithIcon,
      selectItemLabelResolver
    }),
    [formFields]
  );

  const { formFieldsJSX, getFormFieldsWithValues, getFileFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const [t] = useGlobalTranslation();

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: GoodsAcceptanceData = getFormFieldsWithValues() as unknown as GoodsAcceptanceData;

      // These values should be changed to numbers to be compatible with the type GoodsAcceptanceData
      data.numOfPallets = Number(data.numOfPallets);
      data.numOfPackages = Number(data.numOfPackages);
      data.numOfPackagingPieces = Number(data.numOfPackagingPieces);

      const files = getFileFormFieldsWithValues();

      data.damages = files.damages;
      data.deliveryNote = files.deliveryNote[0];

      setGoodsAcceptanceData(data);
    },
    [getFileFormFieldsWithValues, getFormFieldsWithValues, setGoodsAcceptanceData]
  );

  const { enqueueErrorSnackbar } = useCustomSnackbar();
  const navigate = useNavigate();

  const enqueueGoodsDeniedSnackbar = useCallback(() => {
    enqueueErrorSnackbar(t('page.incomingGoods.goodsAcceptance.message.goodsDenied'));
  }, [enqueueErrorSnackbar, t]);

  const handleDenyButtonClick = useCallback(() => {
    enqueueGoodsDeniedSnackbar();
    navigate(paths.home);
  }, [enqueueGoodsDeniedSnackbar, navigate]);

  return (
    <Container>
      <TitleContainer>
        <SectionTitle>{t('page.incomingGoods.goodsAcceptance.form.title')}</SectionTitle>
      </TitleContainer>
      <Form onSubmit={handleFormSubmit}>
        <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
        <FormSubmissionContainer>
          <PrimaryButtonLarge type="submit">{t('page.incomingGoods.goodsAcceptance.form.label.acceptGoods')}</PrimaryButtonLarge>
          <DenyGoodsButton onClick={handleDenyButtonClick}>{t('page.incomingGoods.goodsAcceptance.form.label.denyGoods')}</DenyGoodsButton>
        </FormSubmissionContainer>
      </Form>
    </Container>
  );
};

export default GoodsAcceptanceForm;
