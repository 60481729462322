import { FormFieldsState } from '../types';

const extractFormFieldsWithValues = (state: FormFieldsState) => {
  const formFieldsWithValues: { [_x: string]: string } = {};

  const formFieldsEntries = Object.entries(state);

  formFieldsEntries.forEach((field) => {
    const key = field[0];
    formFieldsWithValues[key] = field[1].value as string;
  });

  return formFieldsWithValues;
};

export default extractFormFieldsWithValues;
