import { TextField } from '@mui/material';
import { ChangeEvent, FormEventHandler, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import { useResetPassword } from 'auth';
import {
  Content as ContentUI,
  FooterContainer,
  FormInnerContainer,
  Header,
  IconsSection,
  Image,
  InnerBox,
  OuterBox,
  PageWithFooterContainer,
  Title,
  TitleContainer
} from 'components/AuthenticationPages';
import Footer from 'components/Footer';
import { Form } from 'components/Form';
import LanguageSwitcher from 'components/Language/LanguageSwitcher';
import Logo from 'components/Logo';
import { FullWidthPrimaryButtonLarge } from 'components/UI/PrimaryButton';
import CenteredTextSmall from 'components/UI/Text';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useManagePageLoading from 'hooks/page/useMangePageLoading';
import useGetServerHealth from 'hooks/server/useGetServerHealth';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import type { PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';

import SuccessDialog from './SuccessDialog';

const TextContainer = styled.div`
  padding: 24px;
`;

const validate = (value: string) => {
  return value.length > 0;
};

const Content = () => {
  const [emailErrorState, setEmailErrorState] = useState(false);

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const { sendEMail } = useResetPassword();

  const { enablePageLoading, disablePageLoading } = useManagePageLoading();

  const [t] = useGlobalTranslation();

  const { serverIsOn } = useGetServerHealth();

  const serverStatusIcon = useMemo(() => (serverIsOn ? <Image src="/images/icons/check.png" /> : <Image src="/images/icons/cancel.png" />), [serverIsOn]);

  const elementDisabled = useMemo(() => !serverIsOn, [serverIsOn]);

  const openSuccessDialog = useCallback(() => {
    setSuccessDialogOpen(true);
  }, []);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailErrorState(!validate(e.target.value));
  }, []);

  const prepareEmail = useCallback((form: HTMLFormElement) => {
    const email = form.email.value as string;
    const isEmailValid = validate(email);

    if (!isEmailValid) {
      setEmailErrorState(true);
    }

    return { email, isEmailValid };
  }, []);

  const failedRequestCallback = useCallback(() => {
    enqueueErrorSnackbar(t('message.errorOccurred'));
  }, [enqueueErrorSnackbar, t]);

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      preventEventDefault(e);

      const form = e.currentTarget;

      const { isEmailValid, email } = prepareEmail(form);

      if (!isEmailValid) {
        return;
      }

      enablePageLoading();

      const callbacks: PromiseCallbacks = {
        finally: disablePageLoading,
        failure: failedRequestCallback,
        success: openSuccessDialog
      };

      sendEMail(email, callbacks);
    },
    [disablePageLoading, enablePageLoading, failedRequestCallback, openSuccessDialog, prepareEmail, sendEMail]
  );

  const emptyFieldErrorMessage = useMemo(() => t('form.error.empty'), [t]);

  const emailErrorMessage = useMemo(() => (emailErrorState ? emptyFieldErrorMessage : ''), [emptyFieldErrorMessage, emailErrorState]);

  return (
    <PageWithFooterContainer>
      <SuccessDialog isOpen={successDialogOpen} />
      <Header>
        <Logo />
      </Header>
      <ContentUI>
        <OuterBox>
          <InnerBox>
            <IconsSection>
              <LanguageSwitcher color={Color.typography} />
              {serverStatusIcon}
            </IconsSection>
            <TitleContainer>
              <Title>{t('page.resetPassword.title')}</Title>
            </TitleContainer>
            <TextContainer>
              <CenteredTextSmall>{t('page.resetPassword.enterEmail')}</CenteredTextSmall>
            </TextContainer>
            <Form onSubmit={handleFormSubmit}>
              <FormInnerContainer>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  name="email"
                  label={t('form.label.email')}
                  error={emailErrorState}
                  helperText={emailErrorMessage}
                  onChange={handleEmailChange}
                  autoFocus
                  disabled={elementDisabled}
                />
                <FullWidthPrimaryButtonLarge disabled={elementDisabled} type="submit">
                  {t('message.continue')}
                </FullWidthPrimaryButtonLarge>
              </FormInnerContainer>
            </Form>
          </InnerBox>
        </OuterBox>
      </ContentUI>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </PageWithFooterContainer>
  );
};

export default Content;
