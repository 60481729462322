import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { MouseEventHandler, useMemo } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import useManageLanguage from 'hooks/language/useManageLanguage';

const StyledIconButton = styled(IconButton)<{ $marginLeft: number }>`
  margin-left: ${({ $marginLeft }) => $marginLeft}px;
`;

const StyledEdit = styled(Edit)`
  fill: ${Color.typography};
`;

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const EditUserButton: React.FC<Props> = ({ onClick }) => {
  const { selectedLanguage } = useManageLanguage();

  const marginLeft = useMemo(() => (selectedLanguage === 'de' ? 16 : 0), [selectedLanguage]);

  return (
    <StyledIconButton $marginLeft={marginLeft} onClick={onClick}>
      <StyledEdit />
    </StyledIconButton>
  );
};

export default EditUserButton;
