import { Dialog, DialogContent, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { useHasAccessToSettingsSectionClientsMasterData } from 'components/Pages/Settings/Content';
import OrderEditForm from 'features/orders/components/OrderEditForm';
import { Order } from 'features/orders/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import { PromiseCallbacks } from 'types';

const StyledDialogContent = styled(DialogContent)`
  @media (max-width: 365px) {
    padding: 0;

    .order_edit_form_inner_container {
      padding: 0;
    }
  }
`;

interface Props {
  isOpen: boolean;
  close: () => void;
  order: Order;
  parentRequestCallbacks?: PromiseCallbacks;
}

const OrderEditDialog: React.FC<Props> = ({ order, isOpen, close, parentRequestCallbacks }) => {
  const [t] = useGlobalTranslation();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCustomSnackbar();

  const enqueueOrderEditedSuccessSnackbar = useCallback(() => {
    enqueueSuccessSnackbar(t('feature.order.message.orderEdited'));
  }, [enqueueSuccessSnackbar, t]);

  const enqueueOrderEditedFailureSnackbar = useCallback(() => {
    enqueueErrorSnackbar(t('message.errorOccurred'));
  }, [enqueueErrorSnackbar, t]);

  const requestSuccessCallback = useCallback(() => {
    enqueueOrderEditedSuccessSnackbar();
    close();
    parentRequestCallbacks?.success?.();
  }, [enqueueOrderEditedSuccessSnackbar, close, parentRequestCallbacks]);

  const requestFailedCallback = useCallback(() => {
    enqueueOrderEditedFailureSnackbar();
    parentRequestCallbacks?.failure?.();
  }, [enqueueOrderEditedFailureSnackbar, parentRequestCallbacks]);

  const requestCallbacks = useMemo(
    () => ({
      success: requestSuccessCallback,
      failure: requestFailedCallback,
      finally: parentRequestCallbacks?.finally
    }),
    [parentRequestCallbacks?.finally, requestFailedCallback, requestSuccessCallback]
  );

  const userHasAccessToEditClients = useHasAccessToSettingsSectionClientsMasterData();

  return (
    <Dialog onClose={close} fullWidth maxWidth="lg" open={isOpen}>
      <StyledDialogContent>
        <OrderEditForm order={order} callbacks={requestCallbacks} showEditClientsButton={userHasAccessToEditClients} />
      </StyledDialogContent>
    </Dialog>
  );
};

export default OrderEditDialog;
