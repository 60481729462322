import { useCallback } from 'react';

import { Access } from 'auth/types';
import { hasAccessChecker } from 'auth/util';

import useAuth from './useAuth';

const useHasAccess = (requiredAccess: Access) => {
  const { userAccesses } = useAuth();

  const hasAccess = useCallback(() => {
    return hasAccessChecker(userAccesses, requiredAccess);
  }, [requiredAccess, userAccesses]);

  return hasAccess();
};

export default useHasAccess;
