import { TextFieldProps } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';

import { EmailFormField, FileFormFieldState, FormFieldsState, GetFormFieldValueFun } from '../types';

interface Props {
  field: EmailFormField;
  TextField: React.FC<TextFieldProps>;
  label: string;
  getFormFieldValue: GetFormFieldValueFun;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  error: string;
}

const FormEmailField: React.FC<Props> = ({ field, TextField, label, getFormFieldValue, changeFormFieldState, formFieldsState, fileFormFieldState, handleFormFieldChange, error }) => {
  const handleTextFormFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, textFieldName: string, propsOnChange?: ChangeEventHandler<HTMLInputElement>) => {
      const { value } = e.target;
      changeFormFieldState(textFieldName, value);

      propsOnChange?.(e);
      handleFormFieldChange?.(value, textFieldName);
    },
    [changeFormFieldState, handleFormFieldChange]
  );

  return (
    <TextField
      disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)}
      fullWidth
      type="email"
      variant="outlined"
      label={label}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleTextFormFieldChange(e, field.name, field.onChange)}
      value={getFormFieldValue(field.name)}
      error={!!error}
      helperText={error || ''}
      placeholder={field.placeholder}
    />
  );
};

export default FormEmailField;
