import { Check, Clear } from '@mui/icons-material';
import { useMemo } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import type { Status } from 'types';

import type { StatusMessageProps } from './types';

const Container = styled.div<{ $status: Status }>`
  border: 2px solid;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  text-align: center;

  svg {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }

  ${({ $status }) => {
    if ($status === 'pending') {
      return 'display: none;';
    }
    if ($status === 'failed') {
      return `border-color: ${Color.failure};
              color: ${Color.failure};
              svg {
                border-color: ${Color.failure};
                fill: ${Color.failure};
              }
      `;
    }
    if ($status === 'succeeded') {
      return `border-color: ${Color.accentPrimary};
              color: ${Color.accentPrimary};
              svg {
                border-color: ${Color.accentPrimary};
                fill: ${Color.accentPrimary};
              }
      `;
    }
    return '';
  }}
`;

const StatusMessage: React.FC<StatusMessageProps> = ({ className, status, pendingText, successText, failureText }) => {
  const text = useMemo(() => {
    if (status === 'failed') return failureText;
    if (status === 'succeeded') return successText;
    if (status === 'pending' && pendingText) return pendingText;
    return '';
  }, [status, failureText, successText, pendingText]);

  const icon = useMemo(() => {
    if (status === 'failed') return <Clear />;
    if (status === 'succeeded') return <Check />;
    return '';
  }, [status]);

  return status !== 'not_initiated' ? (
    <Container $status={status} className={className}>
      {icon}
      {text}
    </Container>
  ) : (
    <div />
  );
};

export default StatusMessage;
