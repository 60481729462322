import { useCallback } from 'react';

import { Accesses } from 'auth/types';
import { hasAccessChecker } from 'auth/util';

import useAuth from './useAuth';

const useHasEveryAccess = (requiredAccess: Accesses) => {
  const { userAccesses } = useAuth();

  const hasAccess = useCallback(() => {
    return requiredAccess.length === 0 || requiredAccess.every((rAccess) => hasAccessChecker(userAccesses, rAccess));
  }, [requiredAccess, userAccesses]);

  return hasAccess();
};

export default useHasEveryAccess;
