import { useMemo } from 'react';

import { europeanCountries } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const resolveCountryLabelPath = (country: string) => `country.label.${country}`;

const useCountryList = () => {
  const [t] = useGlobalTranslation();

  const europeanCountiesSelectItems = useMemo(() => {
    return europeanCountries.map((country) => ({
      label: t(resolveCountryLabelPath(country)),
      value: country
    }));
  }, [t]);

  const europeanCountriesTranslated = useMemo(() => europeanCountries.map((country) => t(resolveCountryLabelPath(country))), [t]);

  return {
    europeanCountriesTranslated,
    europeanCountiesSelectItems
  };
};

export default useCountryList;
