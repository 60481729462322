import { ChangeEvent, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { useHasAccess } from 'auth';
import { UploadInputWithIcon } from 'components/Files';
import { StatusMessage } from 'components/StatusMessage';
import { withHasAccessToFeature } from 'features/hocComponents';
import { Order } from 'features/orders/types';
import useUploadCarrierPickupReceipt from 'features/outgoingGoods/hooks/useUploadCarrierPickupReceipt';
import { useManageFormFields } from 'hooks/form';
import type { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import { useStatus } from 'hooks/status';
import { PromiseCallbacks, RequestFile } from 'types';
import { appendFileToFormData, getFileType } from 'utils/file';

import ScopeOfShipmentForm from './ScopeOfShipmentForm';

const OutgoingGoodsFormContainer = styled.div`
  margin: 80px auto 24px;
`;

const OutgoingGoodsFormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const StyledUploadInputWithIcon = styled(UploadInputWithIcon)`
  max-width: calc(50% - 16px);
  align-self: self-start;
  min-height: 80px;

  img {
    height: 45px;
  }

  @media (max-width: 767px) {
    max-width: none;
    min-heigh: none;
    margin-bottom: 16px;
  }
`;

const StatusMessageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;

interface Props {
  order: Order;
  scopeOfShipmentCallbacks?: PromiseCallbacks;
}

const OutgoingGoodsForm: React.FC<Props> = ({ order, scopeOfShipmentCallbacks }) => {
  const { status: scopeOfShipmentRequestStatus, changeToSucceeded: changeScopeOfShipmentRequestStatusToSucceeded, changeToFailed: changeScopeOfShipmentRequestStatusToFailed } = useStatus();

  const [t] = useGlobalTranslation();

  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const requestUploadCarrierPickupReceipt = useUploadCarrierPickupReceipt();

  const enqueueCarrierPickupReceiptUploadSuccess = useCallback(() => {
    enqueueSuccessSnackbar(t('message.fileUploaded'));
  }, [enqueueSuccessSnackbar, t]);

  const enqueueCarrierPickupReceiptUploadFailure = useCallback(() => {
    enqueueErrorSnackbar(t('message.errorOccurred'));
  }, [enqueueErrorSnackbar, t]);

  const uploadCarrierPickupReceipt = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const requestCallbacks = {
        success: enqueueCarrierPickupReceiptUploadSuccess,
        failure: enqueueCarrierPickupReceiptUploadFailure
      };

      const formData = new FormData();

      formData.append('orderId', `${order.id}`);

      const name = e.currentTarget.name;
      const file = e.currentTarget.files?.[0];

      if (!file) return;

      const appFile: RequestFile = {
        file,
        name: file.name,
        type: getFileType(file),
        lastModified: new Date(file.lastModified)
      };

      appendFileToFormData(appFile, formData, name);

      requestUploadCarrierPickupReceipt(formData, requestCallbacks);
    },
    [enqueueCarrierPickupReceiptUploadFailure, enqueueCarrierPickupReceiptUploadSuccess, order, requestUploadCarrierPickupReceipt]
  );

  const formFields: FormFields = useMemo(
    () => [{ name: 'carrierPickupReceipt', type: 'file', label: 'carrierPickupReceipt', iconSrc: '/images/icons/file1.png', onChange: uploadCarrierPickupReceipt }],
    [uploadCarrierPickupReceipt]
  );

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      UploadFileComponent: StyledUploadInputWithIcon
    }),
    [formFields]
  );

  const { formFieldsJSX } = useManageFormFields(manageFormFieldsParams);

  const goodIsPicked = useMemo(() => scopeOfShipmentRequestStatus === 'succeeded', [scopeOfShipmentRequestStatus]);

  const hasAccessToUploadCarrierPickupReceipt = useHasAccess('pick.uploadCarrierPickupReceipt');

  const outgoingGoodsFormSectionRendered = useMemo(() => {
    return goodIsPicked && hasAccessToUploadCarrierPickupReceipt ? (
      <OutgoingGoodsFormContainer>
        <OutgoingGoodsFormFieldsContainer>{formFieldsJSX}</OutgoingGoodsFormFieldsContainer>
      </OutgoingGoodsFormContainer>
    ) : (
      ''
    );
  }, [formFieldsJSX, goodIsPicked, hasAccessToUploadCarrierPickupReceipt]);

  return (
    <div>
      <ScopeOfShipmentForm
        callbacks={scopeOfShipmentCallbacks}
        order={order}
        changeRequestStatus={{ changeRequestStatusToFailed: changeScopeOfShipmentRequestStatusToFailed, changeRequestStatusToSucceeded: changeScopeOfShipmentRequestStatusToSucceeded }}
      />
      {outgoingGoodsFormSectionRendered}
      <StatusMessageContainer>
        <StatusMessage status={scopeOfShipmentRequestStatus} successText={t('page.outgoingGoods.form.orderIsPicked', { orderNumber: order.orderNumber })} failureText={t('message.errorOccurred')} />
      </StatusMessageContainer>
    </div>
  );
};

export default withHasAccessToFeature(OutgoingGoodsForm, 'pick.create');
