import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { UsersList, UsersSearchData } from '../types';

const useGetUsers = (refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { userGetAll, userSearch } = useGetRequests();

  const [users, setUsers] = useState<UsersList>([]);

  const getUsersSuccessCallback = useCallback((res: AxiosResponse<UsersList>) => {
    setUsers(res.data);
  }, []);

  const getUsers = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<UsersList>) => {
          callbacks?.success?.();
          getUsersSuccessCallback(res);
        }
      };

      doRequest(userGetAll(), extendedCallbacks);
    },
    [doRequest, getUsersSuccessCallback, userGetAll]
  );

  const search = useCallback(
    (data: UsersSearchData) => {
      const callbacks = {
        success: getUsersSuccessCallback
      };

      doRequest(userSearch(data), callbacks);
    },
    [doRequest, getUsersSuccessCallback, userSearch]
  );

  useEffect(() => {
    getUsers();
  }, [getUsers, refetchTrigger]);

  return {
    users,
    search
  };
};

export default useGetUsers;
