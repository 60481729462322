import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Color } from 'appConstants';
import { styled } from 'styled-components';

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border-bottom: 1px solid ${Color.accordionAccent};
  background-color: ${Color.accordionBackground};
  margin: 0 !important;
  margin-top: 8px !important;

  &::before {
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  &:hover {
    background-color: ${Color.accordionAccent};
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
`;

const Content = styled.div`
  border-top: 1px solid ${Color.accordionAccent};
  padding: 16px 0;
`;

interface Props {
  title: string;
  children: React.ReactNode;
  expanded?: boolean;
}

const SettingsSection: React.FC<Props> = ({ title, children, expanded }) => {
  return (
    <StyledAccordion defaultExpanded={expanded}>
      <StyledAccordionSummary expandIcon={<ExpandMore />}>
        <Title>{title}</Title>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Content>{children}</Content>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default SettingsSection;
