import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, Drawer, styled as muiStyled } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from 'appConstants';
import { stopEventPropagation } from 'utils/event';

import useManageSideNan from '../hooks/manageSideNav';
import useNavLinks from '../hooks/useNavLinks';

const Container = styled.nav`
  padding: 24px 8px;
`;

const NavItem = styled(Link)`
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 8px;
  transition: background-color 0.3s;

  &:hover {
    background: ${Color.hover};
  }
`;

const NavItemIcon = styled.img`
  width: 32px;
`;

const NavItemText = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  margin: 0 !important;

  &.Mui-expanded {
    background: ${Color.hover};
  }
`;

const StyledAccordionSummary = muiStyled(AccordionSummary)`
  padding: 8px;

  .MuiAccordionSummary-content {
    display: flex;
    gap: 24px;
    align-items: center;
    margin: 0;
  }
`;

const StyledAccordionDetails = styled.div`
  padding-top: 0;
`;

const Submenu = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-left: 64px;
  padding-bottom: 16px;
`;

const SubmenuItem = styled(Link)`
  font-weight: 500;
  font-size: 1rem;
`;

const mapToSubmenu = (title: string, iconUrl: string, submenu: { title: string; path: string; accessible: boolean }[]) => {
  const someSubMenuItemsAccessible = submenu.some((item) => item.accessible);

  if (!someSubMenuItemsAccessible) return '';

  return (
    <div key={`sideNav-navItem-${title}`}>
      <StyledAccordion>
        <StyledAccordionSummary onClick={stopEventPropagation} expandIcon={<ExpandMore />}>
          <NavItemIcon src={iconUrl} />
          <NavItemText>{title}</NavItemText>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Submenu>
            {submenu.map(({ title: submenuItemTitle, path: submenuItemPath, accessible }) => {
              if (!accessible) return '';
              return (
                <SubmenuItem to={submenuItemPath} key={`submenuItem-${submenuItemTitle}`}>
                  {submenuItemTitle}
                </SubmenuItem>
              );
            })}
          </Submenu>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

const mapToNavItem = (title: string, iconUrl: string, path: string) => {
  return (
    <NavItem to={path} key={`sideNav-navItem-${title}`}>
      <NavItemIcon src={iconUrl} />
      <NavItemText>{title}</NavItemText>
    </NavItem>
  );
};

const SideNav = () => {
  const { sideNavLinks: links } = useNavLinks();

  const { sideNavIsOpen } = useManageSideNan();

  return (
    <Drawer anchor="left" open={sideNavIsOpen}>
      <Container>
        {links.map(({ title, path = '', iconUrl, submenu, accessible }) => {
          if (!accessible) return '';
          if (submenu) {
            return mapToSubmenu(title, iconUrl, submenu);
          }
          return mapToNavItem(title, iconUrl, path);
        })}
      </Container>
    </Drawer>
  );
};

export default SideNav;
