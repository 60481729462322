/* eslint-disable indent */
import { FormFieldMeta, FormFieldsMeta, FormFieldsState } from '../types';

const getInitialValue = (field: FormFieldMeta) => {
  let value;
  switch (field.type) {
    case 'multiSelect':
    case 'file':
      value = [];
      break;
    case 'switch':
      value = false;
      break;
    default:
      value = '';
  }

  return value;
};

const generateEmptyState = (formFieldsMeta: FormFieldsMeta) => {
  const result: FormFieldsState = {};

  formFieldsMeta.forEach((field) => {
    const value = getInitialValue(field);

    result[field.name] = { value };
  });

  return result;
};

export default generateEmptyState;
