import { Autorenew, IconButton, InputAdornment, TextField as LibTextField, Visibility, VisibilityOff } from 'lib/componentLibrary';
import { passwordGenerator } from 'lib/generatePassword';
import { ChangeEvent, useCallback, useMemo, useState } from 'lib/react';
import { preventEventDefault } from 'utils/event';

const generatePassword = () => {
  return passwordGenerator.generate({
    length: 8,
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: true,
    strict: true
  });
};

interface Props {
  value?: string;
  onChange?: (_value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  autoGenerate?: boolean;
}

const PasswordField: React.FC<Props> = ({ disabled, label, error, placeholder, value, onChange, autoGenerate }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((pre) => !pre);
  }, []);

  const setShowPasswordToTrue = useCallback(() => {
    setShowPassword(true);
  }, []);

  const type = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword]);

  const handleAutoGenerateButtonClick = useCallback(() => {
    const password = generatePassword();
    onChange?.(password);
    setShowPasswordToTrue();
  }, [onChange, setShowPasswordToTrue]);

  return (
    <LibTextField
      value={value}
      onChange={handleChange}
      disabled={disabled}
      className="passwordField"
      fullWidth
      type={type}
      variant="outlined"
      label={label}
      error={!!error}
      helperText={error || ''}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword} onMouseDown={preventEventDefault} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            {autoGenerate ? (
              <IconButton onClick={handleAutoGenerateButtonClick} onMouseDown={preventEventDefault} edge="end">
                <Autorenew />
              </IconButton>
            ) : (
              ''
            )}
          </InputAdornment>
        )
      }}
    />
  );
};

export default PasswordField;
