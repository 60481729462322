import { pagesMinAccesses } from 'appConstants';
import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/GoodsNotification/Content';

const GoodsNotification = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(GoodsNotification, pagesMinAccesses.goodsNotification);
