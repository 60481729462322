import { FileType } from 'appConstants';

const getFileType = (file: File) => {
  if (file.type.includes('image')) {
    return FileType.IMAGE;
  }
  if (file.type === 'application/pdf') {
    return FileType.PDF;
  }
  if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword') {
    return FileType.WORD;
  }
  return FileType.NO_TYPE;
};

export default getFileType;
