import { styled as muiStyled, TextField, TextFieldProps } from '@mui/material';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { GoodsAcceptanceData, GoodsWarehousingData, GoodsWarehousingRequest } from 'features/incomingGoods/types';
import { useManageFormFields } from 'hooks/form';
import type { FormFields } from 'hooks/form/types';

import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import { SectionTitle } from 'components/UI/Title';
import { withHasAccessToFeature } from 'features/hocComponents';
import useWarehouseOrder from 'features/incomingGoods/hooks/useWarehouseOrder';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { AppFile, PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';
import { appendFileToFormData } from 'utils/file';

import { Order } from 'features/orders/types';
import GoodsAcceptanceForm from './GoodsAcceptanceForm';

const IncomingGoodsFormContainer = styled.div`
  margin: 80px auto 24px;
`;

const IncomingGoodsTitleContainer = styled.div`
  margin-bottom: 8px;
  text-align: left;

  * {
    text-align: left;
  }
`;

const IncomingGoodsFormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  max-width: calc(50% - 16px);
  height: 80px;

  @media(max-width: 767px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;

const formFields: FormFields = [
  { label: 'warehouseLocation', name: 'warehouseLocation', type: 'text' },
  { label: 'parkingSpace', name: 'parkingSpace', type: 'text' }
];

interface Props {
  order: Order;
  callbacks?: PromiseCallbacks;
}

const IncomingGoodsForm: React.FC<Props> = ({ order, callbacks }) => {
  const {
    status: requestStatus,
    changeToPending: changeRequestStatusToPending,
    changeToSucceeded: changeRequestStatusToSucceeded,
    changeToFailed: changeRequestStatusToFailed,
    changeToNotInitiated: changeRequestStatusToNotInitiated
  } = useStatus();

  const wareHouseOrder = useWarehouseOrder();

  const [t] = useGlobalTranslation();

  const [goodsAcceptanceData, setGoodsAcceptanceData] = useState<GoodsAcceptanceData>();

  const handleFormFieldChange = useCallback(() => {
    changeRequestStatusToNotInitiated();
  }, [changeRequestStatusToNotInitiated]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      handleFormFieldChange
    }),
    [handleFormFieldChange]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const goodsAreAccepted = useMemo(() => !!goodsAcceptanceData, [goodsAcceptanceData]);

  const handleIncomingGoodsFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const goodsWarehousingData = getFormFieldsWithValues() as unknown as GoodsWarehousingData;

      goodsWarehousingData.orderId = order.id;

      const requestInitialData: GoodsWarehousingRequest = { ...goodsWarehousingData, ...(goodsAcceptanceData as GoodsAcceptanceData) };

      changeRequestStatusToPending();

      const successCallback = () => {
        callbacks?.success?.();
        changeRequestStatusToSucceeded();
      };

      const failureCallback = () => {
        callbacks?.failure?.();
        changeRequestStatusToFailed();
      };

      const finallyCallbacks = () => {
        callbacks?.finally?.();
      };

      const extendedCallbacks = {
        success: successCallback,
        failure: failureCallback,
        finally: finallyCallbacks
      };

      const requestFormData = new FormData();

      Object.keys(requestInitialData).forEach((key) => {
        const value = (requestInitialData as any)[key];
        if (key === 'damages') {
          value.forEach((file: AppFile) => {
            appendFileToFormData(file, requestFormData, 'damages');
          });
        } else if (key === 'deliveryNote') {
          const file: AppFile = value;
          if (value) {
            appendFileToFormData(file, requestFormData, 'deliveryNote');
          }
        } else {
          requestFormData.append(key, value);
        }
      });

      wareHouseOrder(requestFormData, extendedCallbacks, false);
    },
    [callbacks, changeRequestStatusToFailed, changeRequestStatusToPending, changeRequestStatusToSucceeded, getFormFieldsWithValues, goodsAcceptanceData, order.id, wareHouseOrder]
  );

  const incomingGoodsFormSectionRendered = useMemo(() => {
    return goodsAreAccepted ? (
      <Form onSubmit={handleIncomingGoodsFormSubmit}>
        <IncomingGoodsFormContainer>
          <IncomingGoodsTitleContainer>
            <SectionTitle>{t('page.incomingGoods.form.title')}</SectionTitle>
          </IncomingGoodsTitleContainer>
          <IncomingGoodsFormFieldsContainer>{formFieldsJSX}</IncomingGoodsFormFieldsContainer>
        </IncomingGoodsFormContainer>
        <SubmitButtonContainer>
          <QueryButton
            StatusMessage={StatusMessage}
            button={<PrimaryButtonLarge type="submit">{t('page.incomingGoods.form.label.submit')}</PrimaryButtonLarge>}
            status={requestStatus}
            statusMessageProps={{
              status: requestStatus,
              successText: t('page.incomingGoods.form.orderIsWarehoused', { orderNumber: order.orderNumber }),
              pendingText: t('page.incomingGoods.form.orderBeingWarehoused', { orderNumber: order.orderNumber }),
              failureText: t('message.errorOccurred')
            }}
          />
        </SubmitButtonContainer>
      </Form>
    ) : (
      ''
    );
  }, [formFieldsJSX, goodsAreAccepted, handleIncomingGoodsFormSubmit, requestStatus, order.orderNumber, t]);

  return (
    <div>
      <GoodsAcceptanceForm setGoodsAcceptanceData={setGoodsAcceptanceData} order={order} />
      {incomingGoodsFormSectionRendered}
    </div>
  );
};

export default withHasAccessToFeature(IncomingGoodsForm, 'warehouse.create');
