import { createSlice } from '@reduxjs/toolkit';

interface State {
  sideNavIsOpen: boolean;
}

const initialState: State = {
  sideNavIsOpen: false
};

export interface UpdateIsSideNavIsOpenAction {
  payload: boolean;
}

const pageSideNavSlice = createSlice({
  name: 'pageSideNav',
  initialState,
  reducers: {
    UpdateIsSideNavIsOpenAction: (state, action: UpdateIsSideNavIsOpenAction) => {
      state.sideNavIsOpen = action.payload;
    }
  }
});

export const { UpdateIsSideNavIsOpenAction } = pageSideNavSlice.actions;

export default pageSideNavSlice.reducer;
