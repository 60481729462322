import { RawAxiosRequestHeaders } from 'axios';

class Request {
  constructor(
    private _method: 'post' | 'put' | 'get' | 'delete',
    private _path: string,
    private _data?: { [x: string]: any },
    private _headers?: Partial<RawAxiosRequestHeaders>
  ) {}

  get method() {
    return this._method;
  }

  get path() {
    return this._path;
  }

  get data() {
    return this._data;
  }

  get headers() {
    return this._headers;
  }
}

export default Request;
