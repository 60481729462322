import { useMemo } from 'react';

import { pagesMinAccesses, paths } from 'appConstants';
import { useGetAccessChecker } from 'auth';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const resolveTitlePath = (pageName: string) => `page.${pageName}.title`;

const resolveIconPath = (iconName: string) => `/images/features/${iconName}.png`;

const useNavLinks = () => {
  const [t] = useGlobalTranslation();
  const { accessCheckerSome } = useGetAccessChecker();

  const sideNavLinks = useMemo(
    () => [
      {
        title: t(resolveTitlePath('home')),
        path: paths.home,
        iconUrl: resolveIconPath('compass'),
        accessible: accessCheckerSome(pagesMinAccesses.home)
      },
      {
        title: t(resolveTitlePath('goodsNotification')),
        path: paths.goodsNotification,
        iconUrl: resolveIconPath('notification'),
        accessible: accessCheckerSome(pagesMinAccesses.goodsNotification)
      },
      {
        title: t(resolveTitlePath('incomingGoods')),
        path: paths.incomingGoods,
        iconUrl: resolveIconPath('package'),
        accessible: accessCheckerSome(pagesMinAccesses.incomingGoods)
      },
      {
        title: t(resolveTitlePath('warehousing')),
        path: paths.warehousing,
        iconUrl: resolveIconPath('warehouse'),
        accessible: accessCheckerSome(pagesMinAccesses.warehouses)
      },
      {
        title: t(resolveTitlePath('picking')),
        path: paths.picking,
        iconUrl: resolveIconPath('picking'),
        accessible: true
      },
      {
        title: t(resolveTitlePath('outgoingGoods')),
        path: paths.outgoingGoods,
        iconUrl: resolveIconPath('outgoing_package'),
        accessible: accessCheckerSome(pagesMinAccesses.outgoingGoods)
      },
      {
        title: t(resolveTitlePath('inventoryManagement')),
        path: paths.inventoryManagement,
        iconUrl: resolveIconPath('order'),
        accessible: accessCheckerSome(pagesMinAccesses.inventoryManagement)
      },
      {
        title: t(resolveTitlePath('management')),
        iconUrl: resolveIconPath('management'),
        accessible: true,
        submenu: [
          {
            title: t(resolveTitlePath('masterData')),
            path: paths.management.masterData,
            accessible: true
          },
          {
            title: t(resolveTitlePath('users')),
            path: paths.management.users,
            accessible: true
          },
          {
            title: t(resolveTitlePath('warehouses')),
            path: paths.management.warehouses,
            accessible: accessCheckerSome(pagesMinAccesses.warehouses)
          }
        ]
      }
    ],
    [accessCheckerSome, t]
  );

  const topNavLinks = useMemo(
    () => [
      {
        title: t(resolveTitlePath('goodsNotification')),
        path: paths.goodsNotification,
        accessible: accessCheckerSome(pagesMinAccesses.goodsNotification)
      },
      {
        title: t(resolveTitlePath('incomingGoods')),
        path: paths.incomingGoods,
        accessible: accessCheckerSome(pagesMinAccesses.incomingGoods)
      },
      {
        title: t(resolveTitlePath('warehousing')),
        path: paths.warehousing,
        accessible: accessCheckerSome(pagesMinAccesses.warehouses)
      },
      {
        title: t(resolveTitlePath('picking')),
        path: paths.picking,
        accessible: true
      },
      {
        title: t(resolveTitlePath('outgoingGoods')),
        path: paths.outgoingGoods,
        accessible: accessCheckerSome(pagesMinAccesses.outgoingGoods)
      },
      {
        title: t(resolveTitlePath('inventoryManagement')),
        path: paths.inventoryManagement,
        accessible: accessCheckerSome(pagesMinAccesses.inventoryManagement)
      },
      {
        title: t(resolveTitlePath('management')),
        accessible: true,
        submenu: [
          {
            title: t(resolveTitlePath('masterData')),
            path: paths.management.masterData,
            accessible: true
          },
          {
            title: t(resolveTitlePath('users')),
            path: paths.management.users,
            accessible: true
          },
          {
            title: t(resolveTitlePath('warehouses')),
            path: paths.management.warehouses,
            accessible: accessCheckerSome(pagesMinAccesses.warehouses)
          }
        ]
      }
    ],
    [accessCheckerSome, t]
  );

  return { topNavLinks, sideNavLinks };
};

export default useNavLinks;
