import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

type Props = object;

const withRedirectToHomeIfAuth = (WrappedComponent: React.FC<Props>) => (props: Props) => {
  const { userIsAuthenticated } = useAuth();

  const location = useLocation();

  const redirectUrl = useMemo(() => location.state?.from || '/', [location.state?.from]);

  if (userIsAuthenticated) {
    return <Navigate to={redirectUrl} />;
  }

  return <WrappedComponent {...props} />;
};

export default withRedirectToHomeIfAuth;
