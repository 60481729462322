import { useCallback, useMemo } from 'react';

import type { LoginRequest, ResetPasswordEmailRequest, ResetPasswordRequest } from 'auth';
import { CreateClientRequest, UpdateClientRequest } from 'features/clients/types';
import type { CreateNotificationRequest } from 'features/goodsNotification/types';
import { GetRecentIncomingGoodsRequest } from 'features/incomingGoods/types';
import { EditOrderRequest, GetRecentOrdersRequest, SearchOrderRequest } from 'features/orders/types';
import type { PickGoodRequest } from 'features/outgoingGoods/types';
import { AddUserRequest, EditUserRequest, UsersSearchData } from 'features/usersManagement';
import { IDRequestData, Request } from 'types';

const useGetRequests = () => {
  const createRequest = useCallback((method: 'post' | 'put' | 'get' | 'delete', path: string, data?: any, headers?: Record<string, string>) => {
    return new Request(method, path, data, headers);
  }, []);

  const requests = useMemo(() => {
    return {
      addBackgroundColorToImage: (data: FormData) => createRequest('post', '/images/add_background', data),
      checkServerHealth: () => createRequest('get', '/application/health'),
      getAppVersion: () => createRequest('get', '/application/version'),
      clientsCreate: (data: CreateClientRequest) => createRequest('post', '/client', data),
      clientsDelete: (data: IDRequestData) => createRequest('delete', `/client/${data.id}`),
      clientsGetAll: () => createRequest('get', '/client'),
      clientIsAssociatedWithRunningOrder: (data: IDRequestData) => createRequest('get', `/client/${data.id}/associated_with_running_order`),
      clientsUpdate: (data: UpdateClientRequest) => createRequest('put', '/client', data),
      identifyUser: () => createRequest('get', '/user/whoami'),
      incomingGoodsCreate: (data: FormData) => createRequest('post', '/warehouse', data, { 'Content-Type': 'multipart/form-data;' }),
      incomingGoodsGetAll: () => createRequest('get', '/warehouse'),
      incomingGoodsGetRecent: (data: GetRecentIncomingGoodsRequest) => createRequest('get', `/warehouse/recent/${data.count}`),
      login: (data: LoginRequest) => createRequest('post', '/user/login', data),
      notificationCreate: (data: CreateNotificationRequest) => createRequest('post', '/notification', data),
      notificationGetAll: () => createRequest('get', '/notification'),
      notificationGetRecent: (data: GetRecentOrdersRequest) => createRequest('get', `/notification/recent/${data.count}`),
      orderEdit: (data: EditOrderRequest) => createRequest('post', '/order/edit', data),
      orderGetRecent: (data: GetRecentOrdersRequest) => createRequest('get', `/order/recent/${data.count}`),
      orderSearch: (data: SearchOrderRequest) => createRequest('post', '/order/search', data),
      pickOrderCreate: (data: PickGoodRequest) => createRequest('post', '/pick', data),
      pickUploadCarrierPickupReceipt: (data: FormData) => createRequest('post', '/pick/carrier_pickup_receipt', data, { 'Content-Type': 'multipart/form-data;' }),
      roleGetAll: () => createRequest('get', '/role'),
      resetPassword: (data: ResetPasswordRequest) => createRequest('post', '/user/reset-password', data),
      resetPasswordEMail: (data: ResetPasswordEmailRequest) => createRequest('post', '/user/reset-password-mail', data),
      transformWordToPDF: (data: FormData) => createRequest('post', '/files/convert_word_to_pdf', data),
      userAdd: (data: AddUserRequest) => createRequest('post', '/user', data),
      userEdit: (data: EditUserRequest) => createRequest('put', '/user', data),
      userGet: (data: number) => createRequest('get', `/user/${data}`),
      userGetAll: () => createRequest('get', '/user/getAll'),
      userSearch: (data: UsersSearchData) => createRequest('post', '/user/search', data),
      userSendResetPasswordLink: (data: IDRequestData) => createRequest('get', `/user/${data.id}/reset-password-mail`),
      warehouseAdd: (data: FormData) => createRequest('post', '/warehouses', data),
      warehouseDisable: (data: IDRequestData) => createRequest('get', `/warehouses/${data.id}/disable`),
      warehouseEdit: (data: FormData) => createRequest('put', '/warehouses', data),
      warehouseEnable: (data: IDRequestData) => createRequest('get', `/warehouses/${data.id}/enable`),
      warehouseGet: (data: number) => createRequest('get', `/warehouses/${data}`),
      warehouseGetList: () => createRequest('get', '/warehouses/list')
    };
  }, [createRequest]);

  return requests;
};

export default useGetRequests;
