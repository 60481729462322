import { FC } from 'react';
import styled from 'styled-components';

const Image = styled.img<{ width: number }>`
  width: ${({ width }) => width}px;
`;

interface Props {
  className?: string;
  width?: number;
}

const Logo: FC<Props> = ({ className, width = 200 }) => <Image width={width} className={className} src="/images/logos/logo_white.png" alt="Logo" placeholder="blur" />;

export default Logo;
