import { useEffect, useRef, useState } from 'react';

const useCombineTriggersState = (...triggers: boolean[]) => {
  const [state, setState] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setState((pre) => !pre);
    }

    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...triggers]);

  return state;
};

export default useCombineTriggersState;
