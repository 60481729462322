import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { PickGoodRequest } from '../types';

const usePickOrder = () => {
  const { pickOrderCreate } = useGetRequests();
  const { doRequest } = useManageRequests();

  const create = useCallback(
    (data: PickGoodRequest, callbacks: PromiseCallbacks, defaultPageLoading?: boolean) => {
      doRequest(pickOrderCreate(data), callbacks, defaultPageLoading);
    },
    [doRequest, pickOrderCreate]
  );

  return create;
};

export default usePickOrder;
