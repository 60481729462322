import { DateTimePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { DateTimeFormField, FileFormFieldState, FormFieldsState, GetFormFieldValueFun } from '../types';

interface Props {
  field: DateTimeFormField;
  DateTimeField: React.FC<DateTimePickerProps<Dayjs>>;
  label: string;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  getFormFieldValue: GetFormFieldValueFun;
  error: string;
}

const FormDateTimeField: React.FC<Props> = ({ field, DateTimeField, label, changeFormFieldState, formFieldsState, fileFormFieldState, handleFormFieldChange, getFormFieldValue }) => {
  const handleDateTimeFormFieldChange = useCallback(
    (newValue: Dayjs | null) => {
      let dateTime = '';
      if (newValue !== null) {
        dateTime = newValue.format('YYYY-MM-DDTHH:mm:00');
      }
      changeFormFieldState(field.name, dateTime);
      handleFormFieldChange?.(newValue, field.name);
    },
    [changeFormFieldState, field.name, handleFormFieldChange]
  );

  const value = useMemo(() => {
    const stateValue = getFormFieldValue(field.name);
    if (typeof stateValue !== 'string') throw new Error('invalid type');
    return stateValue === null || stateValue === undefined || !stateValue.length ? null : dayjs(stateValue);
  }, [field.name, getFormFieldValue]);

  return (
    <DateTimeField disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)} label={label} onChange={handleDateTimeFormFieldChange} format="DD.MM.YYYY HH:mm" ampm={false} value={value} />
  );
};

export default FormDateTimeField;
