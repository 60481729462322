import { DeleteForever } from '@mui/icons-material';
import { IconButton, styled as muiStyled } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { AppFile } from 'types';
import { stopEventPropagation } from 'utils/event';

import FileViewer from './components/FileViewer';

const Container = styled.div``;

const UploadedFilesList = styled.ul`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UploadedFileListItem = styled.li`
  font-weight: 500;
  cursor: pointer;
  transition: color 0.4s;
  min-height: 28px;

  &:hover {
    color: ${Color.linkBlue};

    button {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const DeleteButton = muiStyled(IconButton)`
  visibility: hidden;
  opacity: 0;
  margin-left: 8px;
  transition: all 0.3s;
  padding: 2px;
  svg {
    color: ${Color.failure};
    font-size: 1.5rem;
  }
`;

const EmptyFilesListMessage = styled.div`
  padding: 8px 16px;
  font-style: italic;
  background: ${Color.grey1};
`;

export interface UploadFileProps {
  className?: string;
  files?: AppFile[];
  deleteFile?: (_file: AppFile) => void;
  withDelete?: boolean;
  feedbackIfEmpty?: boolean;
}

const FilesList: React.FC<UploadFileProps> = ({ className, files = [], deleteFile, withDelete = false, feedbackIfEmpty = false }) => {
  const [filesDialogIsOpen, setFilesDialogIsOpen] = useState(false);
  const [fileToShow, setFileToShow] = useState<AppFile | null>(null);

  const [t] = useGlobalTranslation();

  const openFilesDialog = useCallback(() => {
    setFilesDialogIsOpen(true);
  }, []);

  const closeFilesDialog = useCallback(() => {
    setFilesDialogIsOpen(false);
    setFileToShow(null);
  }, []);

  const openFile = useCallback(
    (file: AppFile) => {
      openFilesDialog();
      setFileToShow(file);
    },
    [openFilesDialog]
  );

  const handleDeleteButtonClick = useCallback(
    (e: MouseEvent, file: AppFile) => {
      stopEventPropagation(e);
      if (!deleteFile) {
        throw new Error('Either set withDelete to false or provide a deleteFile method');
      }
      deleteFile(file);
    },
    [deleteFile]
  );

  const deleteButtonRendered = useCallback(
    (file: AppFile) => {
      return withDelete ? (
        <DeleteButton onClick={(event) => handleDeleteButtonClick(event, file)}>
          <DeleteForever />
        </DeleteButton>
      ) : (
        ''
      );
    },
    [handleDeleteButtonClick, withDelete]
  );

  const emptyMessageVisible = useMemo(() => feedbackIfEmpty && files.length === 0, [feedbackIfEmpty, files.length]);

  const emptyMessageRendered = useMemo(() => (emptyMessageVisible ? <EmptyFilesListMessage>{t('message.noFiles')}</EmptyFilesListMessage> : ''), [emptyMessageVisible, t]);

  const filesListRendered = useMemo(() => {
    return files.map((file) => (
      <UploadedFileListItem onClick={() => openFile(file)} key={file.id}>
        {file.name}
        {deleteButtonRendered(file)}
      </UploadedFileListItem>
    ));
  }, [deleteButtonRendered, files, openFile]);

  return (
    <Container className={className}>
      <FileViewer isOpen={filesDialogIsOpen} close={closeFilesDialog} fileToShow={fileToShow} />
      <UploadedFilesList>{filesListRendered}</UploadedFilesList>
      {emptyMessageRendered}
    </Container>
  );
};

export default FilesList;
