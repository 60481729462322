import { Button, ButtonProps, styled } from '@mui/material';

import { Color } from 'appConstants';

const StyledButtonLarge = styled(Button)`
  padding: 8px 40px;
  font-size: 1.125rem;
  background: ${Color.accentPrimary};

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 8px 33px;
  }
`;

export const PrimaryButtonLarge: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonLarge variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonLarge>
  );
};

export const FullWidthPrimaryButtonLarge: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonLarge fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonLarge>
  );
};

const StyledButtonMedium = styled(Button)`
  padding: 8px 24px;
  font-size: 1rem;
  background: ${Color.accentPrimary};
  color: ${Color.white};

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 0 33px;
  }
`;

export const PrimaryButtonMedium: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonMedium variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonMedium>
  );
};

export const FullWidthPrimaryButtonMedium: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonMedium fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonMedium>
  );
};

const StyledButtonSmall = styled(Button)`
  padding: 4px 8px;
  font-size: 0.875rem;
  background: ${Color.accentPrimary};
  color: ${Color.white};

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 0 8px;
  }
`;

export const PrimaryButtonSmall: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonSmall variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonSmall>
  );
};

export const FullWidthPrimaryButtonSmall: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonSmall fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonSmall>
  );
};
