import { Check, Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { ErrorButtonMedium } from 'components/UI/ErrorButton';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { PromiseCallbacks } from 'types';

import { useDeleteClient, useIsClientAssociatedWithRunningOrders } from '../hooks';
import { Client } from '../types';

const StatusContainer = styled.div`
  border: 2px solid ${Color.failure};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  text-align: center;
  color: ${Color.failure};

  svg {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }
`;

const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  justify-content: center;
`;

const SecondAssertionStatusContainer = styled(StatusContainer)`
  display: block;
`;

const StatusAssertionActionSection = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    gap: 0;
    flex-direction: column;
  }
`;

type Status = 'not_initiated' | 'first_assertion' | 'not_deletable' | 'second_assertion' | 'success' | 'failure';

const initialStatus: Status = 'not_initiated';

interface Props {
  client: Client;
  deleteCallbacks?: PromiseCallbacks;
  resetStatusTriggerState?: boolean;
}

const DeleteClientButton: React.FC<Props> = ({ client, deleteCallbacks, resetStatusTriggerState }) => {
  const [status, setStatus] = useState<Status>(initialStatus);

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setStatus(initialStatus);
    }

    isMounted.current = true;
  }, [resetStatusTriggerState]);

  const { isClientAssociated } = useIsClientAssociatedWithRunningOrders();
  const deleteClient = useDeleteClient();

  const changeStatusToNotInitiated = useCallback(() => setStatus('not_initiated'), []);
  const changeStatusToFirstAssertion = useCallback(() => setStatus('first_assertion'), []);
  const changeStatusToNotDeletable = useCallback(() => setStatus('not_deletable'), []);
  const changeStatusToSecondAssertion = useCallback(() => setStatus('second_assertion'), []);
  const changeStatusToSuccess = useCallback(() => setStatus('success'), []);
  const changeStatusToFailure = useCallback(() => setStatus('failure'), []);

  const [t] = useGlobalTranslation();

  const clientName = useMemo(() => client.name, [client.name]);

  const deleteButtonRendered = useMemo(
    () => <ErrorButtonMedium onClick={changeStatusToFirstAssertion}>{t('feature.client.message.deleteExistentClient')}</ErrorButtonMedium>,
    [changeStatusToFirstAssertion, t]
  );

  const isClientAssociatedSuccessCallback = useCallback(
    (res: AxiosResponse<{ clientIsAssociated: boolean }>) => {
      if (res.data.clientIsAssociated) changeStatusToNotDeletable();
      else changeStatusToSecondAssertion();
    },
    [changeStatusToNotDeletable, changeStatusToSecondAssertion]
  );

  const failureCallback = useCallback(() => {
    changeStatusToFailure();
  }, [changeStatusToFailure]);

  const handleFirstDeleteAcceptance = useCallback(() => {
    const isClientAssociatedCallbacks = {
      success: isClientAssociatedSuccessCallback,
      failure: failureCallback
    };

    isClientAssociated({ id: client.id }, isClientAssociatedCallbacks);
  }, [client.id, failureCallback, isClientAssociated, isClientAssociatedSuccessCallback]);

  const handleLastAssertionDeleteAcceptance = useCallback(() => {
    const extendedDeleteClientCallbacks = {
      success: () => {
        changeStatusToSuccess();
        deleteCallbacks?.success?.();
      },
      failure: () => {
        failureCallback();
        deleteCallbacks?.failure?.();
      },
      finally: deleteCallbacks?.finally
    };

    const deleteClientData = { id: client.id };

    deleteClient(deleteClientData, extendedDeleteClientCallbacks);
  }, [changeStatusToSuccess, client.id, deleteCallbacks, deleteClient, failureCallback]);

  const firstAssertionStatusMessageRendered = useMemo(
    () => (
      <StatusContainer>
        {t('feature.client.message.shouldClientBeDeleted', { clientName })}
        <IconButtonsContainer>
          <IconButton color="primary" onClick={handleFirstDeleteAcceptance}>
            <Check />
          </IconButton>
          <IconButton color="error" onClick={changeStatusToNotInitiated}>
            <Clear />
          </IconButton>
        </IconButtonsContainer>
      </StatusContainer>
    ),
    [t, clientName, handleFirstDeleteAcceptance, changeStatusToNotInitiated]
  );

  const notDeletableStatusMessageRendered = useMemo(() => <StatusContainer>{t('feature.client.message.deleteFailed.clientAssociatedWithOrders', { clientName })}</StatusContainer>, [clientName, t]);

  const failureMessageRendered = useMemo(() => <StatusContainer>{t('message.errorOccurred')}</StatusContainer>, [t]);

  const secondAssertionStatusMessageRendered = useMemo(
    () => (
      <SecondAssertionStatusContainer>
        <p>{t('feature.client.message.clientNotAssociatedWithOrders', { clientName })}</p>
        <StatusAssertionActionSection>
          <p>{t('feature.client.message.shouldClientBeDefinitelyDeleted', { clientName })}</p>
          <IconButtonsContainer>
            <IconButton color="primary" onClick={handleLastAssertionDeleteAcceptance}>
              <Check />
            </IconButton>
            <IconButton color="error" onClick={changeStatusToNotInitiated}>
              <Clear />
            </IconButton>
          </IconButtonsContainer>
        </StatusAssertionActionSection>
      </SecondAssertionStatusContainer>
    ),
    [t, clientName, handleLastAssertionDeleteAcceptance, changeStatusToNotInitiated]
  );

  const successStatusMessageRendered = useMemo(
    () => (
      <StatusContainer>
        <Check color="primary" />
        {t('feature.client.message.clientSuccessfullyDeleted', { clientName })}
      </StatusContainer>
    ),
    [t, clientName]
  );

  if (status === 'not_initiated') {
    return deleteButtonRendered;
  }

  if (status === 'first_assertion') {
    return firstAssertionStatusMessageRendered;
  }

  if (status === 'not_deletable') {
    return notDeletableStatusMessageRendered;
  }

  if (status === 'failure') {
    return failureMessageRendered;
  }

  if (status === 'second_assertion') {
    return secondAssertionStatusMessageRendered;
  }

  if (status === 'success') {
    return successStatusMessageRendered;
  }

  throw new Error('Not a valid status');
};

export default withHasAccessToFeature(DeleteClientButton, 'client.delete');
