import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { ServerWarehouse } from '../types';

const useGetWarehouse = (warehouseId: number, refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { warehouseGet } = useGetRequests();

  const [warehouse, setWarehouse] = useState<ServerWarehouse | null>(null);

  const getWarehouseSuccessCallback = useCallback((res: AxiosResponse<ServerWarehouse>) => {
    setWarehouse(res.data);
  }, []);

  const getWarehouse = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<ServerWarehouse>) => {
          callbacks?.success?.();
          getWarehouseSuccessCallback(res);
        }
      };

      doRequest(warehouseGet(warehouseId), extendedCallbacks);
    },
    [doRequest, getWarehouseSuccessCallback, warehouseGet, warehouseId]
  );

  useEffect(() => {
    getWarehouse();
  }, [getWarehouse, refetchTrigger]);

  return {
    warehouse
  };
};

export default useGetWarehouse;
