import { useCallback, useMemo } from 'react';

import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { PrimaryButtonSmall } from 'components/UI/PrimaryButton';
import useEnableWarehouse from 'features/warehouses/hooks/useEnableWarehouse';
import { ServerWarehouse } from 'features/warehouses/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { PromiseCallbacks } from 'types';

interface Props {
  callbacks?: PromiseCallbacks;
  warehouse: ServerWarehouse;
}

const EnableWarehouseButton: React.FC<Props> = ({ callbacks, warehouse }) => {
  const [t] = useGlobalTranslation();

  const enableWarehouse = useEnableWarehouse();

  const { status: requestStatus, changeToFailed: changeRequestStatusToFailed } = useStatus();

  const statusMessageProps = useMemo(
    () => ({
      status: requestStatus,
      successText: t('feature.warehouse.message.warehouseSuccessfullyEnabled', { warehouseName: warehouse.locationName }),
      failureText: t('message.errorOccurred')
    }),
    [requestStatus, t, warehouse.locationName]
  );

  const handleButtonClick = useCallback(() => {
    const data = { id: warehouse.id };

    const extendedCallbacks = {
      success: callbacks?.success,
      failure: () => {
        changeRequestStatusToFailed();
        callbacks?.failure?.();
      },
      finally: callbacks?.finally
    };

    enableWarehouse(data, extendedCallbacks);
  }, [callbacks, changeRequestStatusToFailed, enableWarehouse, warehouse.id]);

  return (
    <QueryButton
      StatusMessage={StatusMessage}
      button={<PrimaryButtonSmall onClick={handleButtonClick}>{t('message.enable')}</PrimaryButtonSmall>}
      status={requestStatus}
      statusMessageProps={statusMessageProps}
    />
  );
};

export default EnableWarehouseButton;
