import { ArrowRightAlt } from '@mui/icons-material';
import { FormControl, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { AcceptTypes, Color } from 'appConstants';
import { FilesList, UploadInputWithIcon } from 'components/Files';
import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useAddWarehouse } from 'features/warehouses/hooks';
import { AddWarehouseRequest } from 'features/warehouses/types';
import { findCityWithPostCode } from 'features/warehouses/util';
import { useCountryList } from 'hooks/features';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { AppFile, AxiosError, PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';
import { appendFileToFormData, composeFileAcceptTypes } from 'utils/file';
import convertImageElementToPngAppFile from 'utils/file/convertImageElementToPngAppFile';

import UploadImageButton from '../Common/WarehouseImageUploadButton';

const hiddenImageId = uuidv4();

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const UploadWarehouseImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const WarehouseImageContainer = styled.div`
  margin-left: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;
  max-width: calc(25% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const TextareaEl = muiStyled(TextField)`
  height: 148px;
  max-width: calc(50% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  min-height: 80px;
  max-width: calc(25% - 16px);

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1070px) {
    min-height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const StyledUploadInputWithIcon = styled(UploadInputWithIcon)`
  width: fit-content;
  min-height: 80px;

  img {
    height: 45px;
  }

  @media (max-width: 767px) {
    min-heigh: none;
    margin-bottom: 16px;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
  flex-wrap: wrap;
`;

const CancelButton = styled(PrimaryButtonLarge)`
  background: ${Color.failure};
  width: 282px;
`;

const SubmitButton = muiStyled(PrimaryButtonLarge)`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center
  width: 282px;
`;

const RequiredFieldsMessage = styled.p`
  margin: 32px 0;
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const TextareaCo: React.FC<TextFieldProps> = (props) => {
  return <TextareaEl {...props} />;
};

const securityFeatures = [
  { label: 'surveillanceCameras', value: 'surveillanceCameras' },
  { label: 'accessControlSystems', value: 'accessControlSystems' },
  { label: 'alarmSystems', value: 'alarmSystems' },
  { label: 'fireDetectors', value: 'fireDetectors' },
  { label: 'sprinklerSystems', value: 'sprinklerSystems' },
  { label: 'emergencyExits', value: 'emergencyExits' },
  { label: 'firstAidEquipment', value: 'firstAidEquipment' },
  { label: 'hazardousMaterialSecurity', value: 'hazardousMaterialSecurity' },
  { label: 'smokeDetectors', value: 'smokeDetectors' },
  { label: 'gasDetectors', value: 'gasDetectors' },
  { label: 'vehicleSafetyPrecautions', value: 'vehicleSafetyPrecautions' },
  { label: 'floorMarkingsAndSignage', value: 'floorMarkingsAndSignage' },
  { label: 'securityBarriersAndRailings', value: 'securityBarriersAndRailings' },
  { label: 'loadAndWeightRestrictions', value: 'loadAndWeightRestrictions' }
];

const formFieldLabelResolver = (formFieldName: string) => `feature.warehouse.label.${formFieldName}`;
const securityFeaturesLabelResolver = (feature: string) => `feature.warehouse.label.${feature}`;

interface Props {
  cancel: () => void;
  callbacks?: PromiseCallbacks;
}

const AddWarehouseForm: React.FC<Props> = ({ cancel, callbacks }) => {
  const [t] = useGlobalTranslation();

  const [postCode, setPostCode] = useState('');

  const [warehouseImage, setWarehouseImage] = useState<AppFile | null>(null);

  const add = useAddWarehouse();

  const { europeanCountiesSelectItems } = useCountryList();

  const {
    status: requestStatus,
    changeToSucceeded: changeRequestStatusToSucceeded,
    changeToFailed: changeRequestStatusToFailed,
    changeToNotInitiated: changeRequestStatusToNotInitiated
  } = useStatus();

  const handlePostCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPostCode(value);
  }, []);

  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'locationName', name: 'locationName', type: 'text', required: true },
      { label: 'addressStreetAndHouseNumber', name: 'addressStreetAndHouseNumber', type: 'text', required: true },
      { label: 'addressPostCode', name: 'addressPostCode', type: 'number', required: true, onChange: handlePostCodeChange },
      { label: 'addressCity', name: 'addressCity', type: 'text', required: true },
      { label: 'addressCountry', name: 'addressCountry', type: 'select', required: true, items: europeanCountiesSelectItems, labelFixed: true },
      { label: 'warehouseSpaceInSquareMeters', name: 'warehouseSpaceInSquareMeters', type: 'number' },
      { label: 'securityFeatures', name: 'securityFeatures', type: 'multiSelect', items: securityFeatures },
      { label: 'additionalSecurityFeatures', name: 'additionalSecurityFeatures', type: 'text' },
      {
        label: 'accessRestrictions',
        name: 'accessRestrictions',
        type: 'textarea',
        placeholder: 'Zum Beispiel: Zutritt nur für autorisiertes Personal. Zugangskontrolle durch Sicherheitskarte oder PIN-Code. Überwachung der Eingänge während der Geschäftszeiten'
      },
      {
        label: 'technicalEquipment',
        name: 'technicalEquipment',
        type: 'textarea',
        placeholder: 'Zum Beispiel: Automatische Lagersysteme, RFID-Scanner, Förderbänder,…'
      },
      {
        name: 'emergencyAndEvacuationPlans',
        type: 'file',
        label: 'emergencyAndEvacuationPlans',
        iconSrc: '/images/icons/file.png',
        multipleFiles: true,
        accept: composeFileAcceptTypes([AcceptTypes.IMAGE, AcceptTypes.PDF, AcceptTypes.WORD])
      },
      {
        name: 'maintenanceSchedule',
        type: 'file',
        label: 'maintenanceSchedule',
        iconSrc: '/images/icons/file.png',
        accept: composeFileAcceptTypes([AcceptTypes.IMAGE, AcceptTypes.PDF, AcceptTypes.WORD])
      }
    ];
  }, [europeanCountiesSelectItems, handlePostCodeChange]);

  const handleFormFieldChange = useCallback(() => {
    changeRequestStatusToNotInitiated();
  }, [changeRequestStatusToNotInitiated]);

  const manageFormFieldsHookParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      handleFormFieldChange,
      formFieldsErrorsState,
      removeErrorFromFormField,
      UploadFileComponent: StyledUploadInputWithIcon,
      selectItemLabelResolver: securityFeaturesLabelResolver,
      Textarea: TextareaCo
    }),
    [formFields, formFieldsErrorsState, handleFormFieldChange, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues, getFileFormFieldsWithValues, initializeAllFormFieldState, changeFormFieldState } = useManageFormFields(manageFormFieldsHookParams);

  useEffect(() => {
    if (postCode.length === 5) {
      const city = findCityWithPostCode(postCode);
      if (city !== -1) {
        changeFormFieldState('addressCity', city);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCode]);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const requestInitialData: AddWarehouseRequest = getFormFieldsWithValues() as unknown as AddWarehouseRequest;
      const additionalSecurityFeatures = getFormFieldsWithValues().additionalSecurityFeatures;

      // These values should be changed to numbers to be compatible with the type GoodsAcceptanceData
      requestInitialData.warehouseSpaceInSquareMeters = Number(requestInitialData.warehouseSpaceInSquareMeters);

      const files = getFileFormFieldsWithValues();

      requestInitialData.maintenanceSchedule = files.maintenanceSchedule[0];
      requestInitialData.emergencyAndEvacuationPlans = files.emergencyAndEvacuationPlans;

      if (additionalSecurityFeatures) {
        requestInitialData.securityFeatures += `,${additionalSecurityFeatures}`;
      }

      const requestFormData = new FormData();

      let warehouseImageEndValue: AppFile;

      if (warehouseImage) {
        warehouseImageEndValue = warehouseImage;
      } else {
        const image = document.getElementById(hiddenImageId) as HTMLImageElement;
        warehouseImageEndValue = convertImageElementToPngAppFile(image, '1.png');
      }

      appendFileToFormData(warehouseImageEndValue, requestFormData, 'warehouseImage');

      Object.keys(requestInitialData).forEach((key) => {
        const value = (requestInitialData as any)[key];
        if (key === 'emergencyAndEvacuationPlans') {
          value.forEach((file: AppFile) => {
            appendFileToFormData(file, requestFormData, 'emergencyAndEvacuationPlans');
          });
        } else if (key === 'maintenanceSchedule') {
          const file: AppFile = value;
          if (value) {
            appendFileToFormData(file, requestFormData, 'maintenanceSchedule');
          }
        } else {
          requestFormData.append(key, value);
        }
      });

      const successCallback = () => {
        callbacks?.success?.();
        changeRequestStatusToSucceeded();
        initializeAllFormFieldState();
      };

      const failureCallback = (errors: AxiosError) => {
        callbacks?.failure?.();
        changeRequestStatusToFailed();
        updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
      };

      const finallyCallback = () => {
        callbacks?.finally?.();
      };

      const extendedCallbacks = {
        success: successCallback,
        failure: failureCallback,
        finally: finallyCallback
      };

      add(requestFormData, extendedCallbacks);
    },
    [
      add,
      callbacks,
      changeRequestStatusToFailed,
      changeRequestStatusToSucceeded,
      getFileFormFieldsWithValues,
      getFormFieldsWithValues,
      initializeAllFormFieldState,
      updateFormFieldsErrorsState,
      warehouseImage
    ]
  );

  const deleteWarehouseImage = useCallback(() => {
    setWarehouseImage(null);
  }, []);

  const selectedWarehouseImageRendered = useMemo(() => {
    if (warehouseImage) {
      return <FilesList files={[warehouseImage]} withDelete deleteFile={deleteWarehouseImage} />;
    }
    return '';
  }, [deleteWarehouseImage, warehouseImage]);

  const cancelButtonRendered = useMemo(() => {
    if (requestStatus === 'succeeded') {
      return '';
    }
    return <CancelButton onClick={cancel}>{t('message.cancel')}</CancelButton>;
  }, [cancel, requestStatus, t]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <img src="/images/warehouseIcons/1.png" alt="" style={{ display: 'none' }} id={hiddenImageId} />
      <RequiredFieldsMessage>{t('form.message.asteriskIndicatesRequiredField')}</RequiredFieldsMessage>
      <FormFieldsContainer>
        {formFieldsJSX.map((formFieldJsx, index) => {
          if (index >= 10) return '';
          return formFieldJsx;
        })}
        <UploadContainer>
          {formFieldsJSX[10]}
          {formFieldsJSX[11]}
        </UploadContainer>
        <UploadWarehouseImageContainer>
          <UploadImageButton setWarehouseImage={setWarehouseImage} warehouseImage={warehouseImage} />
          <WarehouseImageContainer>{selectedWarehouseImageRendered}</WarehouseImageContainer>
        </UploadWarehouseImageContainer>
        <SubmitButtonContainer>
          <QueryButton
            StatusMessage={StatusMessage}
            button={
              <SubmitButton type="submit">
                <ArrowRightAlt />
                {t('feature.warehouse.addWarehouse')}
              </SubmitButton>
            }
            status={requestStatus}
            statusMessageProps={{
              status: requestStatus,
              successText: t('feature.warehouse.addWarehouseForm.message.lagerAdded'),
              pendingText: t('feature.warehouse.addWarehouseForm.message.lagerIsBeingAdded'),
              failureText: t('message.errorOccurred')
            }}
          />
          {cancelButtonRendered}
        </SubmitButtonContainer>
      </FormFieldsContainer>
    </Form>
  );
};

export default withHasAccessToFeature(AddWarehouseForm, 'warehouses.create');
