import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

const useAddBackgroundColorToImage = () => {
  const { addBackgroundColorToImage } = useGetRequests();
  const { doRequest } = useManageRequests();

  const addBackground = useCallback(
    (data: FormData, callbacks: PromiseCallbacks) => {
      doRequest(addBackgroundColorToImage(data), callbacks);
    },
    [doRequest, addBackgroundColorToImage]
  );

  return addBackground;
};

export default useAddBackgroundColorToImage;
