import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { PromiseCallbacks } from 'types';
import { CreateClientRequest } from '../types';

const useCreateClient = () => {
  const { clientsCreate } = useGetRequests();
  const { doRequest } = useManageRequests();

  const create = useCallback(
    (data: CreateClientRequest, callbacks: PromiseCallbacks) => {
      doRequest(clientsCreate(data), callbacks);
    },
    [clientsCreate, doRequest]
  );

  return create;
};

export default useCreateClient;
