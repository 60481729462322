import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { IDRequestData, PromiseCallbacks } from 'types';

const useEnableWarehouse = () => {
  const { warehouseEnable } = useGetRequests();
  const { doRequest } = useManageRequests();

  const enable = useCallback(
    (data: IDRequestData, callbacks?: PromiseCallbacks) => {
      doRequest(warehouseEnable(data), callbacks);
    },
    [warehouseEnable, doRequest]
  );

  return enable;
};

export default useEnableWarehouse;
