import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { PromiseCallbacks } from 'types';
import { UpdateClientRequest } from '../types';

const useUpdateClient = () => {
  const { clientsUpdate } = useGetRequests();
  const { doRequest } = useManageRequests();

  const create = useCallback(
    (data: UpdateClientRequest, callbacks: PromiseCallbacks) => {
      doRequest(clientsUpdate(data), callbacks);
    },
    [clientsUpdate, doRequest]
  );

  return create;
};

export default useUpdateClient;
