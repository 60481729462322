import { Check, Clear, LockReset } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { preventEventDefault } from 'utils/event';

import useSendLinkToRestPassword from '../hooks/useSendLinkToResetPassword';

const StatusContainer = styled.div`
  border: 2px solid ${Color.failure};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  text-align: center;
  color: ${Color.failure};

  svg {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }
`;

const SuccessStatusContainer = styled(StatusContainer)`
  color: ${Color.accentPrimary};
  border-color: ${Color.accentPrimary};
`;

const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  justify-content: center;
`;

const StyledLockReset = styled(LockReset)`
  margin-right: 8px;
`;

type Status = 'not_initiated' | 'assertion' | 'success' | 'failure';

const INITIAL_SUBMIT_BUTTON_STATUS = 'not_initiated';

interface Props {
  userId: number;
}

const ResetPasswordMail: React.FC<Props> = ({ userId }) => {
  const { send } = useSendLinkToRestPassword();

  const [status, setStatus] = useState<Status>(INITIAL_SUBMIT_BUTTON_STATUS);

  const changeStatusToNotInitiated = useCallback(() => {
    setStatus('not_initiated');
  }, []);

  const changeStatusToAssertion = useCallback(() => {
    setStatus('assertion');
  }, []);

  const changeStatusToSuccess = useCallback(() => {
    setStatus('success');
  }, []);

  const changeStatusToFailure = useCallback(() => {
    setStatus('failure');
  }, []);

  const [t] = useGlobalTranslation();

  const handleInitialSubmitButtonClick = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const callbacks = {
        success: changeStatusToSuccess,
        failure: changeStatusToFailure
      };

      send(userId, callbacks);
    },
    [changeStatusToFailure, changeStatusToSuccess, send, userId]
  );

  const buttonRendered = useMemo(
    () => (
      <PrimaryButtonMedium onClick={changeStatusToAssertion} type="submit">
        <StyledLockReset />
        {t('feature.usersManagement.editUser.message.sendResetPasswordLink')}
      </PrimaryButtonMedium>
    ),
    [changeStatusToAssertion, t]
  );

  const assertionStatusMessageRendered = useMemo(
    () => (
      <StatusContainer>
        {t('feature.usersManagement.editUser.message.shouldResetPasswordLinkBeSent')}
        <IconButtonsContainer>
          <IconButton color="primary" onClick={handleInitialSubmitButtonClick} type="submit">
            <Check />
          </IconButton>
          <IconButton color="error" onClick={changeStatusToNotInitiated}>
            <Clear />
          </IconButton>
        </IconButtonsContainer>
      </StatusContainer>
    ),
    [t, handleInitialSubmitButtonClick, changeStatusToNotInitiated]
  );

  const failureMessageRendered = useMemo(() => <StatusContainer>{t('message.errorOccurred')}</StatusContainer>, [t]);

  const successStatusMessageRendered = useMemo(
    () => (
      <SuccessStatusContainer>
        <Check color="primary" />
        {t('feature.usersManagement.editUser.message.linkSentSuccessfully')}
      </SuccessStatusContainer>
    ),
    [t]
  );

  if (status === 'not_initiated') {
    return buttonRendered;
  }

  if (status === 'assertion') {
    return assertionStatusMessageRendered;
  }

  if (status === 'failure') {
    return failureMessageRendered;
  }

  if (status === 'success') {
    return successStatusMessageRendered;
  }

  throw new Error('Not a valid status');
};

export default ResetPasswordMail;
