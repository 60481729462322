import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { FileType } from 'appConstants';
import { AppFile, FileData } from 'types';
import { appendFileToFormData } from 'utils/file';

import { DefaultDialog } from 'components/Dialog';
import useTransformWordToPDF from '../hooks/useTransformWordToPDF';

const FileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 32px 0;
`;

const Image = styled.img`
  width: 254px;
  display: block;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 400px;
`;

interface Props {
  fileToShow: AppFile | null;
  isOpen: boolean;
  close: () => void;
}

const FileViewer: React.FC<Props> = ({ fileToShow, isOpen, close }) => {
  const transform = useTransformWordToPDF();

  const [wordTransformedToPDF, setWordTransformedToPDF] = useState('');

  const requestWordToPDFTransformation = useCallback(() => {
    if (fileToShow === null) return;
    const formData = new FormData();
    appendFileToFormData(fileToShow, formData, 'file');

    const requestCallbacks = {
      success: (res: AxiosResponse<FileData>) => {
        setWordTransformedToPDF(res.data.file.toString());
      }
    };

    transform(formData, requestCallbacks);
  }, [fileToShow, transform]);

  const fileRendered = useMemo(() => {
    if (fileToShow === null) return <div />;
    if (fileToShow.type === FileType.IMAGE) {
      return <Image src={fileToShow.dataUri} alt={fileToShow.name} />;
    }
    if (fileToShow.type === FileType.PDF) {
      return <Iframe title="PDF Viewer" src={`${fileToShow.dataUri}`} />;
    }
    if (fileToShow.type === FileType.WORD) {
      return wordTransformedToPDF ? <Iframe title="Word Viewer" src={`data:application/pdf;base64,${wordTransformedToPDF}`} /> : '';
    }

    throw new Error('Not a valid file type');
  }, [fileToShow, wordTransformedToPDF]);

  useEffect(() => {
    if (fileToShow?.type === FileType.WORD) {
      requestWordToPDFTransformation();
    }
  }, [fileToShow?.type, requestWordToPDFTransformation]);

  return (
    <DefaultDialog close={close} maxWidth="sm" isOpen={isOpen} title={fileToShow?.name || ''}>
      <FileContainer>{fileRendered}</FileContainer>
    </DefaultDialog>
  );
};

export default FileViewer;
