import { Close } from '@mui/icons-material';
import { Breakpoint, Dialog, DialogContent, DialogTitle, IconButton, styled as muiStyled } from '@mui/material';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { SectionTitle } from 'components/UI/Title';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDialogTitle = muiStyled(DialogTitle)`
  background: ${Color.grey1};
`;

const StyledDialogContent = muiStyled(DialogContent)`
    padding: 16px 30px 28px !important;
`;

interface Props {
  isOpen: boolean;
  close: () => void;
  title: string;
  children: React.ReactNode;
  maxWidth?: Breakpoint;
  className?: string;
}

const DefaultDialog: React.FC<Props> = ({ isOpen, close, className, title, children, maxWidth = 'md' }) => {
  return (
    <Dialog onClose={close} fullWidth maxWidth={maxWidth} open={isOpen} className={className}>
      <StyledDialogTitle>
        <HeaderContainer>
          <SectionTitle>{title}</SectionTitle>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </HeaderContainer>
      </StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
    </Dialog>
  );
};

export default DefaultDialog;
