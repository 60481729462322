const transformDataUriToJSFile = (data: string, type: string, name: string) => {
  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type });

  return new File([blob], `${name}`, { type });
};

export default transformDataUriToJSFile;
