import styled from 'styled-components';

import { Color } from 'appConstants';
import { EditUserForm } from 'features/usersManagement';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const Container = styled.div``;

const Title = styled.div`
  color: ${Color.typography};
  border-bottom: 2px solid ${Color.accentPrimary};
  font-size: 1.125rem;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 40px;
`;

interface Props {
  userId: number;
  username: string;
}

const EditUserFormWrapper: React.FC<Props> = ({ userId, username }) => {
  const [t] = useGlobalTranslation();

  return (
    <Container>
      <Title>{t('page.settings.section.userManagement.editUser.title.uppercase', { username })}</Title>
      <EditUserForm userId={userId} />
    </Container>
  );
};

export default EditUserFormWrapper;
