import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from 'lib/componentLibrary';
import { useCallback } from 'lib/react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const getMenuItemStyle = (itemValue: string, inputValue: readonly string[]) => {
  return {
    fontWeight: inputValue.indexOf(itemValue) === -1 ? 400 : 500
  };
};

interface Props {
  items: { label: string; value: string }[];
  value?: string[];
  onChange?: (_value: string[]) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  labelFixed?: boolean;
  itemLabelResolver?: (_label: string) => string;
}

const MultiSelectField: React.FC<Props> = ({ disabled, label, error, placeholder, value = [], onChange, labelFixed, itemLabelResolver, items }) => {
  const [t] = useGlobalTranslation();

  const handleChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const fieldValue = e.target.value;

      let endValue: string[] = [];

      if (typeof fieldValue === 'string') {
        endValue.push(fieldValue);
      } else {
        endValue = fieldValue;
      }

      onChange?.(endValue);
    },
    [onChange]
  );

  const printSelectMenuItemLabel = useCallback(
    (itemLabel: string) => {
      if (labelFixed) return itemLabel;
      if (!itemLabelResolver) throw new Error('An itemLabelResolver param must be provided or labelFixed param must be true');
      return t(itemLabelResolver?.(itemLabel));
    },
    [labelFixed, itemLabelResolver, t]
  );

  const getLabelFromValue = useCallback(
    (v: string) => {
      const item = items.find((arrayItem) => arrayItem.value === v);
      return item?.label;
    },
    [items]
  );

  return (
    <FormControl fullWidth disabled={disabled} error={!!error} className="multiSelectField">
      <InputLabel id={`multi-select-${label}`}>{label}</InputLabel>
      <Select<string[]>
        multiple
        labelId={`multi-select-${label}`}
        variant="outlined"
        label={label}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {(selected as unknown as string[]).map((selectedItem) => (
              <Chip key={selectedItem} label={printSelectMenuItemLabel(getLabelFromValue(selectedItem) || '')} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {items.map((item) => (
          <MenuItem key={`${label}-${item.value}`} value={item.value} style={getMenuItemStyle(printSelectMenuItemLabel(item.value), value)}>
            {printSelectMenuItemLabel(item.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectField;
