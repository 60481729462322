import { Upload } from '@mui/icons-material';
import { ChangeEventHandler } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import FilesList from 'components/Files/FilesList';
import { SecondaryButtonMedium } from 'components/UI/SecondaryButton';
import { AppFile } from 'types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  &:hover {
    border-color: ${Color.typography};
  }
`;

const Label = styled.label`
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Image = styled.img`
  height: 54px;
`;

export interface UploadFileProps {
  label?: string;
  className?: string;
  iconSrc: string;
  multipleFiles?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  files?: AppFile[];
  deleteFile: (_file: AppFile) => void;
  disabled?: boolean;
  accept?: string;
}

const UploadInputWithIcon: React.FC<UploadFileProps> = ({ accept = '.jpg, .jpeg, .png, .gif, .bmp, .pdf', label, className, iconSrc, multipleFiles = false, onChange, name, files, deleteFile }) => {
  return (
    <Container className={className}>
      <Label>
        <InputContainer>
          <SecondaryButtonMedium component="div">
            <Upload />
            {label}
            <input name={name} type="file" accept={accept} style={{ display: 'none' }} value="" onChange={onChange} multiple={multipleFiles} />
          </SecondaryButtonMedium>
          <Image src={iconSrc} />
        </InputContainer>
      </Label>
      <FilesList files={files} deleteFile={deleteFile} withDelete />
    </Container>
  );
};

export default UploadInputWithIcon;
