import { useCallback, useState } from 'react';

const useTrigger = () => {
  const [triggerState, setTriggerState] = useState(false);

  const trigger = useCallback(() => {
    setTriggerState((pre) => !pre);
  }, []);

  return { triggerState, trigger };
};

export default useTrigger;
