import { Accesses, ServerRoles } from 'auth/types';

const extractAccessesFromServerRole = (roles: ServerRoles) => {
  const accesses: Accesses = [];
  roles.forEach((role) => {
    role.accesses.forEach((access) => {
      const { name: accessName } = access;
      if (!accesses.includes(accessName)) {
        accesses.push(accessName);
      }
    });
  });

  return accesses;
};

export default extractAccessesFromServerRole;
