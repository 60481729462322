import { useCallback } from 'react';

import axios from 'axios';
import useManagePageLoading from 'hooks/page/useMangePageLoading';
import type { PromiseCallbacks, Request } from 'types';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const useManageRequests = () => {
  const { enablePageLoading, disablePageLoading } = useManagePageLoading();

  const beforeRequest = useCallback(
    (defaultPageLoading: boolean) => {
      if (defaultPageLoading) {
        enablePageLoading();
      }
    },
    [enablePageLoading]
  );

  const afterRequest = useCallback(
    (defaultPageLoading: boolean) => {
      if (defaultPageLoading) {
        disablePageLoading();
      }
    },
    [disablePageLoading]
  );

  const doRequest = useCallback(
    (request: Request, callbacks?: PromiseCallbacks, defaultPageLoading = true) => {
      beforeRequest(defaultPageLoading);

      return axios[request.method](request.path, request.data, { headers: request.headers })
        .then((data) => {
          callbacks?.success?.(data);
        })
        .catch((err) => {
          callbacks?.failure?.(err);
        })
        .finally(() => {
          callbacks?.finally?.();
          afterRequest(defaultPageLoading);
        });
    },
    [afterRequest, beforeRequest]
  );

  return {
    doRequest
  };
};

export default useManageRequests;
