import { v4 as uuidv4 } from 'uuid';

import { FileType } from 'appConstants';
import { AppFile, ResponseFile } from 'types';

import getFileType from './getFileType';
import transformDataUriToJSFile from './transformDataUriToJSFile';

const getDataUri = (responseFile: ResponseFile, type: FileType) => {
  let dataUri = '';
  if (type === FileType.IMAGE) {
    dataUri = `data:image/jpeg;base64,${responseFile.data}`;
  } else if (type === FileType.PDF) {
    dataUri = `data:application/pdf;base64,${responseFile.data}`;
  } else if (type === FileType.WORD) {
    dataUri = `TO BE EDITED ${responseFile.data}`;
  }

  return dataUri;
};

const transformResponseFilesToAppFiles = (responseFiles: ResponseFile[]): AppFile[] => {
  if (!responseFiles) return [];
  const result: AppFile[] = [];
  responseFiles.forEach((file) => {
    if (!file) return;

    const jsFile = transformDataUriToJSFile(file.data, file.type, file.name);
    const appFileType = getFileType(jsFile);

    result.push({
      dataUri: getDataUri(file, appFileType),
      file: jsFile,
      id: uuidv4(),
      lastModified: new Date(file.created_at),
      name: file.name,
      type: appFileType
    });
  });

  return result;
};

export default transformResponseFilesToAppFiles;
