import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueErrorSnackbar = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const enqueueSuccessSnackbar = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  return {
    enqueueErrorSnackbar,
    enqueueSuccessSnackbar
  };
};

export default useCustomSnackbar;
