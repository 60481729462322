import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { Order } from 'features/orders/types';
import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';
import { GetRecentIncomingGoodsRequest } from '../types';

const useGetRecentIncomingGoods = (data: GetRecentIncomingGoodsRequest, callbacks?: PromiseCallbacks) => {
  const { doRequest } = useManageRequests();
  const { incomingGoodsGetRecent } = useGetRequests();

  const [incomingGoods, setIncomingGoods] = useState<Order[]>([]);

  const getIncomingGoodsSuccessCallback = useCallback((res: AxiosResponse<Order[]>) => {
    setIncomingGoods(res.data);
  }, []);

  const get = useCallback(() => {
    const extendedCallbacks = {
      ...callbacks,
      success: (res: AxiosResponse<Order[]>) => {
        callbacks?.success?.();
        getIncomingGoodsSuccessCallback(res);
      }
    };

    doRequest(incomingGoodsGetRecent(data), extendedCallbacks);
  }, [callbacks, doRequest, getIncomingGoodsSuccessCallback, incomingGoodsGetRecent, data]);

  useEffect(() => {
    get();
  }, [get]);

  return {
    incomingGoods
  };
};

export default useGetRecentIncomingGoods;
