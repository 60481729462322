import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { updateServerHealthCheckAction } from 'redux/features/server/serverHealthSlice';

const INTERVAL_VALUE = 10000;

// Only use in App.tsx
const useCheckServerHealth = () => {
  const dispatch = useDispatch();
  const { doRequest } = useManageRequests();
  const { checkServerHealth } = useGetRequests();

  const dispatchUpdateServerHealthCheckActionToTrue = useCallback(() => {
    dispatch(updateServerHealthCheckAction(true));
  }, [dispatch]);

  const dispatchUpdateServerHealthCheckActionToFalse = useCallback(() => {
    dispatch(updateServerHealthCheckAction(false));
  }, [dispatch]);

  const successCallback = useCallback(() => {
    dispatchUpdateServerHealthCheckActionToTrue();
  }, [dispatchUpdateServerHealthCheckActionToTrue]);

  const failureCallback = useCallback(() => {
    dispatchUpdateServerHealthCheckActionToFalse();
  }, [dispatchUpdateServerHealthCheckActionToFalse]);

  const callbacks = useMemo(
    () => ({
      success: successCallback,
      failure: failureCallback
    }),
    [failureCallback, successCallback]
  );

  const doCheckServerHealthRequest = useCallback(() => {
    doRequest(checkServerHealth(), callbacks, false);
  }, [callbacks, checkServerHealth, doRequest]);

  useEffect(() => {
    doCheckServerHealthRequest();

    const interval = setInterval(() => {
      doCheckServerHealthRequest();
    }, INTERVAL_VALUE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCheckServerHealth;
