import type { StatusMessageProps } from 'components/StatusMessage/types';
import type { Status } from 'types';

export interface QueryButtonProps {
  button: JSX.Element;
  StatusMessage: React.FC<StatusMessageProps>;
  statusMessageProps: StatusMessageProps;
  status: Status;
}

const QueryButton: React.FC<QueryButtonProps> = ({ button, StatusMessage, status, statusMessageProps }) => {
  if (status === 'not_initiated') {
    return button;
  }
  if (status === 'succeeded' || status === 'failed' || status === 'pending') {
    return <StatusMessage {...statusMessageProps} />;
  }

  throw new Error('Invalid status');
};

export default QueryButton;
