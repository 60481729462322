import { Box, Chip, FormControlProps, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Theme, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';

import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import { FileFormFieldState, FormFieldsState, GetFormFieldValueFun, MultiSelectFormField } from '../types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const getStyles = (name: string, personName: readonly string[], theme: Theme) => {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
};

interface Props {
  field: MultiSelectFormField;
  SelectFormControl: React.FC<FormControlProps>;
  label: string;
  getFormFieldValue: GetFormFieldValueFun;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  selectItemLabelResolver?: (_selectItemName: string) => string;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  error: string;
}

const FormMultiSelect: React.FC<Props> = ({
  field,
  label,
  getFormFieldValue,
  changeFormFieldState,
  selectItemLabelResolver,
  formFieldsState,
  fileFormFieldState,
  handleFormFieldChange,
  SelectFormControl
}) => {
  const [t] = useGlobalTranslation();
  const theme = useTheme();

  const printSelectMenuItemLabel = useCallback(
    (itemLabel: string, labelFixed?: boolean) => {
      if (labelFixed) return itemLabel;
      if (!selectItemLabelResolver) throw new Error('A selectItemLabelResolver param must be provided or labelFixed param must be true');
      return t(selectItemLabelResolver?.(itemLabel));
    },
    [selectItemLabelResolver, t]
  );

  const handleSelectFormFieldChange = useCallback(
    (e: SelectChangeEvent<string[]>, textFieldName: string) => {
      const {
        target: { value }
      } = e;

      let endValue = '';

      if (typeof value === 'string') {
        endValue = value;
      } else if (Array.isArray(value)) {
        endValue = value.join(',');
      }

      field.onChange?.({ ...e, target: { ...e.target, value: endValue } } as SelectChangeEvent);

      handleFormFieldChange?.(endValue, textFieldName);

      changeFormFieldState(field.name, endValue);
    },
    [changeFormFieldState, field, handleFormFieldChange]
  );

  const value = useMemo(() => {
    const stateValue = getFormFieldValue(field.name);
    if (typeof stateValue !== 'string') throw new Error('invalid type');
    return stateValue;
  }, [field.name, getFormFieldValue]);

  const formFieldValueToArray = useMemo(() => {
    return value.length === 0 ? [] : value.split(',');
  }, [value]);

  const getLabelFromValue = useCallback(
    (v: string) => {
      const item = field.items.find((arrayItem) => arrayItem.value === v);
      return item?.label;
    },
    [field.items]
  );

  return (
    <SelectFormControl fullWidth disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)}>
      <InputLabel id={`formField-MultiSelect-${label}`}>{label}</InputLabel>
      <Select
        variant="outlined"
        labelId={`formField-MultiSelect-${label}`}
        label={label}
        multiple
        value={formFieldValueToArray}
        onChange={(e) => handleSelectFormFieldChange(e, field.name)}
        input={<OutlinedInput id={`formField-MultiSelect-${label}-chip`} label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {(selected as unknown as string[]).map((selectedItem) => (
              <Chip key={selectedItem} label={printSelectMenuItemLabel(getLabelFromValue(selectedItem) || '')} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {field.items.map((item) => (
          <MenuItem key={`${field.name}-${item.value}`} value={item.value} style={getStyles(printSelectMenuItemLabel(item.label, item.value), value.split(','), theme)}>
            {printSelectMenuItemLabel(item.label, field.labelFixed)}
          </MenuItem>
        ))}
      </Select>
    </SelectFormControl>
  );
};

export default FormMultiSelect;
