import { AddLocationAlt } from '@mui/icons-material';
import { useMemo } from 'react';
import styled from 'styled-components';

import { useHasAccess } from 'auth';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import { WarehousesList as WarehousesListFeature } from 'features/warehouses/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const AddWarehouseButtonContainer = styled.div`
  margin-top: 48px;
  width: 100%;
`;

const StyledPrimaryButtonLarge = styled(PrimaryButtonLarge)`
  gap: 16px;
  padding-left: 24px;
  padding-right: 24px;
`;

interface Props {
  changeToAddWarehouseView: () => void;
  selectWarehouse: (_warehouseId: number) => void;
}

const WarehousesList: React.FC<Props> = ({ selectWarehouse, changeToAddWarehouseView }) => {
  const [t] = useGlobalTranslation();

  const userHasAccessToSeeWarehouseList = useHasAccess('warehouses.getList');
  const userHasAccessToCreateWarehouse = useHasAccess('warehouses.create');

  const warehousesListFeatureRendered = useMemo(() => {
    if (userHasAccessToSeeWarehouseList) return <WarehousesListFeature onSelect={selectWarehouse} />;
    return '';
  }, [selectWarehouse, userHasAccessToSeeWarehouseList]);

  const createWarehouseButtonRendered = useMemo(() => {
    if (userHasAccessToCreateWarehouse) {
      return (
        <AddWarehouseButtonContainer>
          <StyledPrimaryButtonLarge onClick={changeToAddWarehouseView}>
            <AddLocationAlt />
            {t('feature.warehouse.addWarehouse')}
          </StyledPrimaryButtonLarge>
        </AddWarehouseButtonContainer>
      );
    }

    return '';
  }, [changeToAddWarehouseView, t, userHasAccessToCreateWarehouse]);

  return (
    <div>
      {warehousesListFeatureRendered}
      {createWarehouseButtonRendered}
    </div>
  );
};

export default WarehousesList;
