import { Dayjs, DateTimeField as LibDateTimeField, dayjs } from 'lib/componentLibrary';
import { useCallback, useMemo } from 'lib/react';

interface Props {
  value?: string;
  onChange?: (_value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
}

const DateTimeField: React.FC<Props> = ({ disabled, label, error, value, onChange }) => {
  const handleChange = useCallback(
    (newValue: Dayjs | null) => {
      let date = '';
      if (newValue !== null) {
        date = newValue.format('YYYY-MM-DDTHH:mm:00');
      }
      onChange?.(date);
    },
    [onChange]
  );

  const adjustedValue = useMemo(() => {
    return value === null || value === undefined || !value.length ? null : dayjs(value);
  }, [value]);

  return (
    <LibDateTimeField
      onChange={handleChange}
      value={adjustedValue}
      disabled={disabled}
      className="dateTimeField"
      fullWidth
      variant="outlined"
      label={label}
      helperText={error || ''}
      format="DD.MM.YYYY HH:mm"
      ampm={false}
    />
  );
};

export default DateTimeField;
