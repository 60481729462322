import { useHasAccess } from 'auth';
import { useMemo } from 'react';

const useHasAccessToSection = () => {
  const userHasAccessToAddClient = useHasAccess('client.create');
  const userHasAccessToEditClient = useHasAccess('client.update');
  const userHasAccessToGetClients = useHasAccess('client.getAll');
  const userHasAccessToEditClientForm = useMemo(() => userHasAccessToGetClients && userHasAccessToEditClient, [userHasAccessToEditClient, userHasAccessToGetClients]);
  const userHasMinAccess = useMemo(() => userHasAccessToAddClient || userHasAccessToEditClientForm, [userHasAccessToEditClientForm, userHasAccessToAddClient]);

  return userHasMinAccess;
};

export default useHasAccessToSection;
