import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { paths } from 'appConstants';
import { FullWidthPrimaryButtonMedium } from 'components/UI/PrimaryButton';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const Title = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 1rem;
  font-family: 'Roboto', 'Arial';
  line-height: 1.5;
`;

const ButtonContainer = styled.div`
  margin: 16px 0;
`;

interface Props {
  isOpen: boolean;
}

const SuccessDialog: React.FC<Props> = ({ isOpen }) => {
  const [t] = useGlobalTranslation();

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <DialogTitle>
        <Title>{t('page.resetPassword.dialog.emailSentTitle')}</Title>
      </DialogTitle>
      <DialogContent>
        <Text>{t('page.resetPassword.dialog.emailSentText')}</Text>
        <ButtonContainer>
          <Link to={paths.login}>
            <FullWidthPrimaryButtonMedium>{t('page.resetPassword.dialog.goToLogin')}</FullWidthPrimaryButtonMedium>
          </Link>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;
