import { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authenticateAction, logoutAction, updateUserInfo } from 'auth/authFeature/authenticationSlice';
import type { Accesses, IdentifyUserResponse, LoginResponse, Roles } from 'auth/types';
import { extractAccessesFromServerRole, extractServerRoleName } from 'auth/util';
import { addAxiosAuthHeader, removeAxiosAuthHeader } from 'config';
import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { RootState } from 'redux/types';
import type { PromiseCallbacks } from 'types';
import { addWarnUserBeforeUnloadEventHandler, removeWarnUserBeforeUnloadEventHandler } from 'utils/event';

const isAuthenticatedSelector = (state: RootState) => state.authentication.isAuthenticated;
const userSelector = (state: RootState) => state.authentication;

const useAuth = () => {
  const userIsAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  const { doRequest } = useManageRequests();
  const { login, identifyUser } = useGetRequests();

  const dispatchAuthenticateAction = useCallback(() => {
    dispatch(authenticateAction());
  }, [dispatch]);

  const dispatchUpdateUserInfoAction = useCallback(
    (name: string, email: string, roles: Roles, firstName: string, lastName: string, phoneNumber: string, jobTitle: string, accesses: Accesses) => {
      dispatch(updateUserInfo({ name, email, roles, firstName, lastName, phoneNumber, jobTitle, accesses }));
    },
    [dispatch]
  );

  const dispatchLogoutAction = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const doIdentifyUserRequest = useCallback(() => {
    const callbacks = {
      success: (res: AxiosResponse<IdentifyUserResponse>) => {
        const { email, name, roles, firstName, lastName, phoneNumber, jobTitle } = res.data;
        const roleNames = extractServerRoleName(roles);
        const accesses = extractAccessesFromServerRole(roles);
        dispatchUpdateUserInfoAction(name, email, roleNames, firstName, lastName, phoneNumber, jobTitle, accesses);
      }
    };

    doRequest(identifyUser(), callbacks);
  }, [dispatchUpdateUserInfoAction, doRequest, identifyUser]);

  const authenticate = useCallback(
    (email: string, password: string, callbacks?: PromiseCallbacks) => {
      const data = { email, password };
      const request = login(data);

      const extendedCallbacks = {
        failure: callbacks?.failure,
        finally: callbacks?.finally,
        success: (res: AxiosResponse<LoginResponse>) => {
          addAxiosAuthHeader(res.data.token);
          callbacks?.success?.();
          dispatchAuthenticateAction();
          doIdentifyUserRequest();
          addWarnUserBeforeUnloadEventHandler();
        }
      };

      doRequest(request, extendedCallbacks);
    },
    [dispatchAuthenticateAction, doIdentifyUserRequest, doRequest, login]
  );

  const logout = useCallback(() => {
    removeAxiosAuthHeader();
    dispatchLogoutAction();
    removeWarnUserBeforeUnloadEventHandler();
  }, [dispatchLogoutAction]);

  const userRole = useMemo(() => user.roles, [user.roles]);
  const userAccesses = useMemo(() => user.accesses, [user.accesses]);

  return {
    userIsAuthenticated,
    authenticate,
    logout,
    user,
    userRole,
    userAccesses
  };
};

export default useAuth;
