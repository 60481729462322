import { ArrowRightAlt } from '@mui/icons-material';
import { ButtonProps, FormControl, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { StatusMessageProps } from 'components/StatusMessage/types';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { AxiosError, Status } from 'types';
import { preventEventDefault } from 'utils/event';

import clientFormRatingSelectItems from '../constants/clientFormRatingSelectItems';
import { useCreateClient } from '../hooks';
import { CreateClientRequest } from '../types';
import { preventPostCodeChange } from '../util';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;
  max-width: calc(25% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  height: 80px;
  max-width: calc(25% - 16px);

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const TextareaEl = muiStyled(TextField)`
  height: 148px;
  max-width: calc(50%);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const SubmitButtonContainerEl = styled.div<{ $maxWidth: string }>`
max-width: ${({ $maxWidth }) => $maxWidth};
height: 80px;
width: calc(50% - 16px);
margin-right: auto;

padding: 0 16px;

@media(max-width: 1000px) {
  max-width: none;
  width: 100%;
  height: auto
  margin-bottom: 16px
}
`;

const StyledPrimaryButtonMedium = muiStyled(PrimaryButtonMedium)`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center
`;

const submitButtonContainerWithStatus = (status: Status, statusMessageProps: StatusMessageProps, maxWidth: string): React.FC<ButtonProps> => {
  return ({ children, ...otherProps }) => (
    <SubmitButtonContainerEl $maxWidth={maxWidth}>
      <QueryButton
        StatusMessage={StatusMessage}
        button={
          <StyledPrimaryButtonMedium {...otherProps} fullWidth>
            <ArrowRightAlt />
            {children}
          </StyledPrimaryButtonMedium>
        }
        status={status}
        statusMessageProps={statusMessageProps}
      />
    </SubmitButtonContainerEl>
  );
};

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const TextareaCo: React.FC<TextFieldProps> = (props) => {
  return <TextareaEl {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.client.label.${formFieldName}`;

const ClientAddForm = () => {
  const [t] = useGlobalTranslation();

  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const [clientName, setClientName] = useState('');

  const createClient = useCreateClient();

  const {
    status: requestStatus,
    changeToSucceeded: changeRequestStatusToSucceeded,
    changeToFailed: changeRequestStatusToFailed,
    changeToNotInitiated: changeRequestStatusToNotInitiated
  } = useStatus();

  const queryButtonMaxWidth = useMemo(() => (requestStatus === 'not_initiated' ? '316px' : '100%'), [requestStatus]);

  const handleFormFieldChange = useCallback(() => {
    changeRequestStatusToNotInitiated();
  }, [changeRequestStatusToNotInitiated]);

  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'name', name: 'name', type: 'text', required: true },
      { label: 'companyName', name: 'companyName', type: 'text' },
      { label: 'businessManagement', name: 'businessManagement', type: 'text' },
      { label: 'customerAdviser', name: 'customerAdviser', type: 'text' },
      { label: 'street', name: 'street', type: 'text', required: true },
      { label: 'houseNumber', name: 'houseNumber', type: 'text', required: true },
      { label: 'otherLocationInformation', name: 'otherLocationInformation', type: 'text' },
      { label: 'city', name: 'city', type: 'text', required: true },
      { label: 'postCode', name: 'postCode', type: 'number', required: true, preventChange: preventPostCodeChange },
      { label: 'country', name: 'country', type: 'text', required: true },
      { label: 'rating', name: 'rating', type: 'select', labelFixed: true, items: clientFormRatingSelectItems },
      { label: 'iban', name: 'iban', type: 'text' },
      { label: 'commentsAndNotes', name: 'commentsAndNotes', type: 'textarea' },
      { type: 'submit', fixedLabel: t('feature.client.label.createClient') }
    ];
  }, [t]);

  const formFieldSubmitButton = useCallback(() => {
    return submitButtonContainerWithStatus(
      requestStatus,
      {
        status: requestStatus,
        successText: t('feature.client.message.clientAdded', { clientName }),
        failureText: t('message.errorOccurred')
      },
      queryButtonMaxWidth
    );
  }, [clientName, queryButtonMaxWidth, requestStatus, t]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      Textarea: TextareaCo,
      SubmitButton: formFieldSubmitButton(),
      handleFormFieldChange,
      formFieldsErrorsState,
      removeErrorFromFormField
    }),
    [formFieldSubmitButton, formFields, formFieldsErrorsState, handleFormFieldChange, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues, initializeAllFormFieldState } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: CreateClientRequest = getFormFieldsWithValues() as unknown as CreateClientRequest;

      const successCallback = () => {
        setClientName(data.name);
        changeRequestStatusToSucceeded();
        initializeAllFormFieldState();
      };

      const failureCallback = (errors: AxiosError) => {
        changeRequestStatusToFailed();
        updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
      };

      const callbacks = {
        success: successCallback,
        failure: failureCallback
      };

      createClient(data, callbacks);
    },
    [changeRequestStatusToFailed, changeRequestStatusToSucceeded, createClient, getFormFieldsWithValues, initializeAllFormFieldState, updateFormFieldsErrorsState]
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
    </Form>
  );
};

export default withHasAccessToFeature(ClientAddForm, 'client.create');
