import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UpdateIsPageLoadingAction } from 'redux/features/page/pageLoadingSlice';
import type { RootState } from 'redux/types';

const pageIsLoadingSelector = (state: RootState) => state.pageLoading.pageIsLoading;

const useManagePageLoading = () => {
  const pageIsLoading = useSelector(pageIsLoadingSelector);
  const dispatch = useDispatch();

  const updateIsPageLoading = useCallback(
    (isLoading: boolean) => {
      dispatch(UpdateIsPageLoadingAction(isLoading));
    },
    [dispatch]
  );

  const enablePageLoading = useCallback(() => {
    updateIsPageLoading(true);
  }, [updateIsPageLoading]);

  const disablePageLoading = useCallback(() => {
    updateIsPageLoading(false);
  }, [updateIsPageLoading]);

  return {
    pageIsLoading,
    enablePageLoading,
    disablePageLoading
  };
};

export default useManagePageLoading;
