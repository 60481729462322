import { MouseEvent, useCallback, useMemo } from 'react';

import Table from 'components/Table';
import { TableDataItem } from 'components/Table/types';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { formatDate, getShipmentTypeLabel, printPickupTimeWindow } from 'utils/strings';

import { useGetNotifications } from '../hooks';
import { GetNotificationsResponse } from '../types';

const columnHeaders = ['order', 'client', 'shipmentCompany', 'date', 'pickupTimeWindow', 'shipmentType'];

const headersLabelResolver = (label: string) => `feature.order.label.${label}`;

interface Props {
  height: number;
  handleNotificationHover?: (_e: MouseEvent, _item: GetNotificationsResponse) => void;
  handleNotificationClick?: (_e: MouseEvent, _item: GetNotificationsResponse) => void;
  refetchTrigger?: boolean;
}

const GoodsNotificationTable: React.FC<Props> = ({ height, handleNotificationHover, handleNotificationClick, refetchTrigger }) => {
  const { notifications } = useGetNotifications(refetchTrigger);

  const [t] = useGlobalTranslation();

  const tableTitle = useMemo(() => t('feature.notifications.table.title'), [t]);

  const tableData = useMemo(() => {
    return notifications.map(({ orderData: { orderNumber, client, shipmentType, carrier, id, notificationDate, pickupTimeWindow } }) => {
      return {
        client: client?.name,
        id,
        order: orderNumber,
        shipmentCompany: carrier,
        date: formatDate(notificationDate),
        pickupTimeWindow: printPickupTimeWindow(pickupTimeWindow),
        shipmentType: getShipmentTypeLabel(shipmentType, t)
      };
    });
  }, [notifications, t]);

  const findGetNotificationResponseItem = useCallback((tableItem: TableDataItem) => notifications.find((item) => item.orderData.id === tableItem.id), [notifications]);

  const handleTableBodyRowHover = useCallback(
    (e: MouseEvent, item: TableDataItem) => {
      const notificationResponseItem = findGetNotificationResponseItem(item);
      if (!notificationResponseItem) {
        throw new Error('Notification not found');
      }
      handleNotificationHover?.(e, notificationResponseItem);
    },
    [findGetNotificationResponseItem, handleNotificationHover]
  );

  const handleTableBodyRowClick = useCallback(
    (e: MouseEvent, item: TableDataItem) => {
      const notificationResponseItem = findGetNotificationResponseItem(item);
      if (!notificationResponseItem) {
        throw new Error('Notification not found');
      }
      handleNotificationClick?.(e, notificationResponseItem);
    },
    [findGetNotificationResponseItem, handleNotificationClick]
  );

  const TableProps = useMemo(
    () => ({
      title: tableTitle,
      height,
      columnHeaders,
      tableData,
      headerLabelResolver: headersLabelResolver,
      onTableBodyRowHover: handleTableBodyRowHover,
      onTableBodyRowClick: handleTableBodyRowClick
    }),
    [handleTableBodyRowClick, handleTableBodyRowHover, height, tableData, tableTitle]
  );

  return <Table {...TableProps} noDataMessage={t('message.noOrders')} />;
};

export default withHasAccessToFeature(GoodsNotificationTable, 'notification.getAll');
