import { ArrowRightAlt, Check, Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { FormEvent, FormEventHandler, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import { preventEventDefault } from 'utils/event';
import { SubmitEditButtonStatus } from '../types';

const StatusContainer = styled.div`
  border: 2px solid ${Color.failure};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  text-align: center;
  color: ${Color.failure};

  svg {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }
`;

const SuccessStatusContainer = styled(StatusContainer)`
  color: ${Color.accentPrimary};
  border-color: ${Color.accentPrimary};
`;

const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  justify-content: center;
`;

interface Props {
  username: string;
  submit: FormEventHandler;
  changeStatusToNotInitiated: () => void;
  changeStatusToAssertion: () => void;
  status: SubmitEditButtonStatus;
  shouldAssert: () => boolean;
}

const SubmitButton: React.FC<Props> = ({ username, submit, changeStatusToAssertion, changeStatusToNotInitiated, status, shouldAssert }) => {
  const [t] = useGlobalTranslation();

  const handleInitialSubmitButtonClick = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);
      if (shouldAssert()) {
        changeStatusToAssertion();
      } else {
        submit(e);
      }
    },
    [changeStatusToAssertion, shouldAssert, submit]
  );

  const buttonRendered = useMemo(
    () => (
      <PrimaryButtonMedium onClick={handleInitialSubmitButtonClick} type="submit">
        <ArrowRightAlt />
        {t('message.saveChanges')}
      </PrimaryButtonMedium>
    ),
    [handleInitialSubmitButtonClick, t]
  );

  const assertionStatusMessageRendered = useMemo(
    () => (
      <StatusContainer>
        {t('feature.usersManagement.editUser.message.shouldUserBeDisabled', { username })}
        <IconButtonsContainer>
          <IconButton color="primary" onClick={submit} type="submit">
            <Check />
          </IconButton>
          <IconButton color="error" onClick={changeStatusToNotInitiated}>
            <Clear />
          </IconButton>
        </IconButtonsContainer>
      </StatusContainer>
    ),
    [t, username, submit, changeStatusToNotInitiated]
  );

  const failureMessageRendered = useMemo(() => <StatusContainer>{t('message.errorOccurred')}</StatusContainer>, [t]);

  const successStatusMessageRendered = useMemo(
    () => (
      <SuccessStatusContainer>
        <Check color="primary" />
        {t('feature.usersManagement.editUser.message.userSuccessfullyEdited', { username })}
      </SuccessStatusContainer>
    ),
    [t, username]
  );

  if (status === 'not_initiated') {
    return buttonRendered;
  }

  if (status === 'assertion') {
    return assertionStatusMessageRendered;
  }

  if (status === 'failure') {
    return failureMessageRendered;
  }

  if (status === 'success') {
    return successStatusMessageRendered;
  }

  throw new Error('Not a valid status');
};

export default SubmitButton;
