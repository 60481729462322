import { AxiosResponse } from 'axios';
import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback, useEffect, useState } from 'react';

const useAppVersions = () => {
  const frontendVersion = process.env.REACT_APP_VERSION;
  const [backendVersion, setBackendVersion] = useState('');

  const { doRequest } = useManageRequests();
  const { getAppVersion } = useGetRequests();

  const getBackendVersionSuccessCallback = useCallback((res: AxiosResponse<string>) => {
    setBackendVersion(res.data);
  }, []);

  const getBackendVersionRequest = useCallback(() => {
    const callbacks = {
      success: getBackendVersionSuccessCallback
    };

    doRequest(getAppVersion(), callbacks);
  }, [doRequest, getAppVersion, getBackendVersionSuccessCallback]);

  useEffect(() => {
    getBackendVersionRequest();
  }, [getBackendVersionRequest]);

  return { frontendVersion, backendVersion };
};

export default useAppVersions;
