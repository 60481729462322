import { Navigate, useLocation } from 'react-router-dom';

import { paths } from 'appConstants';
import { Accesses } from 'auth/types';

import { useAuth, useHasSomeAccess } from '../hooks';

type Props = object;

const withAuthentication = (WrappedComponent: React.FC<Props>, minAccesses: Accesses = []) => {
  return (props: Props) => {
    const { userIsAuthenticated } = useAuth();

    const userHasAccess = useHasSomeAccess(minAccesses);

    const location = useLocation();

    if (!userIsAuthenticated) {
      return <Navigate to={paths.login} state={{ from: location.pathname }} />;
    }

    if (!userHasAccess) {
      return <div>Forbidden</div>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthentication;
