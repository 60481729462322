const handler = (e: BeforeUnloadEvent) => {
  const confirmationMessage = 'bla bla';

  (e || window.event).returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
};

const addWarnUserBeforeUnloadEventHandler = () => {
  window.addEventListener('beforeunload', handler);
};

const removeWarnUserBeforeUnloadEventHandler = () => {
  window.removeEventListener('beforeunload', handler);
};

export { addWarnUserBeforeUnloadEventHandler, removeWarnUserBeforeUnloadEventHandler };
