import { useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { ServerListWarehouse } from 'features/warehouses/types';
import { composeWarehouseAddress } from 'features/warehouses/util';

const ADDRESS_HEIGHT = 40;
const TITLE_HEIGHT = 45;
const CONTAINER_HEIGHT = 350;
const IMAGE_CONTAINER_HEIGHT = 305;

const Container = styled.div`
  height: ${CONTAINER_HEIGHT}px;
  width: 350px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    .management-warehouses-warehouseBox-address {
      top: calc(100% - ${ADDRESS_HEIGHT}px);
    }
  }
`;

const ImageContainer = styled.div<{ $backgroundImage: string; $disabled: boolean }>`
  background: url(${({ $backgroundImage }) => $backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: ${IMAGE_CONTAINER_HEIGHT}px;
  width: 100%;

  ${({ $disabled }) => {
    if ($disabled) {
      return `
    filter: brightness(0.3);
  `;
    }
    return '';
  }}
`;

const Title = styled.div<{ $disabled: boolean }>`
  font-size: 1.5rem;
  font-weight: 500;
  background: ${Color.accentPrimary};
  color: ${Color.white};
  width: 100%;
  padding: 8px 16px;
  height: ${TITLE_HEIGHT}px;

  ${({ $disabled }) => {
    if ($disabled) {
      return `
    background: ${Color.typography_input};
  `;
    }
    return '';
  }}
`;

const Address = styled.div`
  position: absolute;
  transition: all 0.3s;
  top: 100%;
  left: 0;
  font-size: 1.25rem;
  font-weight: 500;
  background: ${Color.white};
  color: ${Color.accentPrimary};
  height: ${ADDRESS_HEIGHT}px;
  padding: 8px 16px;
  border-top-right-radius: 8px;
`;

interface Props {
  warehouse: ServerListWarehouse;
  onSelect: (_warehouseId: number) => void;
}

const WarehouseBox: React.FC<Props> = ({ warehouse, onSelect }) => {
  const handleContainerClick = useCallback(() => {
    onSelect(warehouse.id);
  }, [onSelect, warehouse]);

  const disabled = useMemo(() => warehouse.disabled, [warehouse.disabled]);

  return (
    <Container onClick={handleContainerClick}>
      <Title $disabled={disabled}>{warehouse.locationName}</Title>
      <ImageContainer $disabled={disabled} $backgroundImage={`data:image/jpeg;base64,${warehouse.warehouseImage.data}`} />
      <Address className="management-warehouses-warehouseBox-address">{composeWarehouseAddress(warehouse.addressStreetAndHouseNumber, `${warehouse.addressPostCode}`, warehouse.addressCity)}</Address>
    </Container>
  );
};

export default WarehouseBox;
