import { FormEventHandler, ReactNode } from 'lib/react';

interface Props {
  children: ReactNode;
  onSubmit: FormEventHandler;
}

const Form: React.FC<Props> = ({ children, onSubmit }) => {
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
