import { Button, ButtonProps, styled } from '@mui/material';

import { Color } from 'appConstants';

const StyledButtonLarge = styled(Button)`
  padding: 8px 40px;
  font-size: 1.125rem;
  background: transparent;
  border: 2px solid ${Color.accentPrimary};
  color: ${Color.accentPrimary};
  box-shadow: none;

  &:hover {
    background: transparent;
  }

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 8px 33px;
  }
`;

export const SecondaryButtonLarge: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonLarge variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonLarge>
  );
};

export const FullWidthSecondaryButtonLarge: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonLarge fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonLarge>
  );
};

const StyledButtonMedium = styled(Button)`
  padding: 8px 24px;
  font-size: 1rem;
  background: transparent;
  border: 2px solid ${Color.accentPrimary};
  color: ${Color.accentPrimary};
  box-shadow: none;

  &:hover {
    background: transparent;
  }

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 0 33px;
  }
`;

export const SecondaryButtonMedium: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonMedium variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonMedium>
  );
};

export const FullWidthSecondaryButtonMedium: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonMedium fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonMedium>
  );
};

const StyledButtonSmall = styled(Button)`
  padding: 4px 8px;
  font-size: 0.875rem;
  background: transparent;
  border: 2px solid ${Color.accentPrimary};
  color: ${Color.accentPrimary};
  box-shadow: none;

  &:hover {
    background: transparent;
  }

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 0 8px;
  }
`;

export const SecondaryButtonSmall: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonSmall variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonSmall>
  );
};

export const FullWidthSecondaryButtonSmall: React.FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <StyledButtonSmall fullWidth variant="contained" color="primary" {...otherProps}>
      {children}
    </StyledButtonSmall>
  );
};
