import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { SearchOrderRequest } from '../types';

const useSearchOrder = () => {
  const { orderSearch } = useGetRequests();
  const { doRequest } = useManageRequests();

  const search = useCallback(
    (data: SearchOrderRequest, callbacks?: PromiseCallbacks, defaultPageLoading?: boolean) => {
      doRequest(orderSearch(data), callbacks, defaultPageLoading);
    },
    [doRequest, orderSearch]
  );

  return search;
};

export default useSearchOrder;
