import { Upload } from '@mui/icons-material';
import { IconButton, styled as muiStyled } from '@mui/material';
import { ChangeEvent, MouseEventHandler, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FileType } from 'appConstants';
import { AppFile } from 'types';
import { getFileType, readFile } from 'utils/file';

const StyledUploadIcon = muiStyled(Upload)`
  width: 74px;
  height: 74px;
`;

interface Props {
  setState: (_: AppFile) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const UploadButton: React.FC<Props> = ({ setState, onClick }) => {
  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        throw new Error('Not a file input');
      }
      const file = event.target.files[0];

      if (!file) return;

      readFile(file).then((dataUri) => {
        const type = getFileType(file);
        if (type === FileType.NO_TYPE) {
          return;
        }

        const { name, lastModified } = file;
        const newFile = { lastModified: new Date(lastModified), dataUri, type, name, file, id: uuidv4() };

        setState(newFile);
      });
    },
    [setState]
  );

  return (
    <label htmlFor="management-warehouse-upload-image-input">
      <IconButton component="div" onClick={onClick}>
        <StyledUploadIcon />
        <input value="" id="management-warehouse-upload-image-input" type="file" accept=".jpg, .jpeg, .png, .gif" style={{ display: 'none' }} onChange={handleFileUpload} />
      </IconButton>
    </label>
  );
};

export default UploadButton;
