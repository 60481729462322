import { Cancel, CheckCircle } from '@mui/icons-material';
import { styled } from '@mui/material';
import { Color } from 'appConstants';
import React from 'react';

const commonStyles = `margin-left: 16px;
                      display: block`;

const StyledCheckCircle = styled(CheckCircle)`
  fill: ${Color.accentPrimary};
  ${commonStyles}
`;

const StyledCancel = styled(Cancel)`
  fill: ${Color.failure};
  ${commonStyles}
`;

interface Props {
  isActive: boolean;
}

const IsUserActiveIcon: React.FC<Props> = ({ isActive }) => {
  return isActive ? <StyledCheckCircle /> : <StyledCancel />;
};

export default IsUserActiveIcon;
