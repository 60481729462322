import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { PromiseCallbacks } from 'types';

const useSendLinkToRestPassword = () => {
  const { userSendResetPasswordLink } = useGetRequests();
  const { doRequest } = useManageRequests();

  const send = useCallback(
    (userId: number, callbacks?: PromiseCallbacks) => {
      doRequest(userSendResetPasswordLink({ id: userId }), callbacks);
    },
    [doRequest, userSendResetPasswordLink]
  );

  return { send };
};

export default useSendLinkToRestPassword;
