import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { PromiseCallbacks } from 'types';
import { EditUserRequest } from '../types';

const useEditUser = () => {
  const { userEdit } = useGetRequests();
  const { doRequest } = useManageRequests();

  const add = useCallback(
    (data: EditUserRequest, callbacks: PromiseCallbacks) => {
      doRequest(userEdit(data), callbacks);
    },
    [userEdit, doRequest]
  );

  return add;
};

export default useEditUser;
