import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { AxiosResponse } from 'axios';
import { GetRecentOrdersRequest, Order } from '../types';

const useGetRecentOrders = (data: GetRecentOrdersRequest, callbacks?: PromiseCallbacks, defaultPageLoading?: boolean, refetchTrigger?: boolean) => {
  const { orderGetRecent } = useGetRequests();
  const { doRequest } = useManageRequests();

  const [recentOrders, setRecentOrders] = useState<Order[]>([]);

  const getRecentOrdersSuccessCallback = useCallback((res: AxiosResponse<Order[]>) => {
    setRecentOrders(res.data);
  }, []);

  const GetRecentOrders = useCallback(() => {
    const extendedCallbacks = {
      ...callbacks,
      success: (res: AxiosResponse<Order[]>) => {
        callbacks?.success?.();
        getRecentOrdersSuccessCallback(res);
      }
    };

    doRequest(orderGetRecent(data), extendedCallbacks, defaultPageLoading);
  }, [callbacks, data, defaultPageLoading, doRequest, getRecentOrdersSuccessCallback, orderGetRecent]);

  useEffect(() => {
    GetRecentOrders();
  }, [GetRecentOrders, refetchTrigger]);

  return recentOrders;
};

export default useGetRecentOrders;
