import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { Color, paths } from 'appConstants';
import { useHasAccess } from 'auth';
import { Client, ClientAddForm, ClientEditForm, DeleteClientButton } from 'features/clients';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useTrigger } from 'hooks/trigger';

import SettingsSection from '../SettingSection';

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const InnerContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 40px 40px 16px;
  position: relative;

  @media (max-width: 375px) {
    padding: 40px 8px 16px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 1160px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

const activeTabRules = `
 background: ${Color.accentPrimary};
 color: ${Color.white};
`;

const Tab = styled.div<{ $active: boolean }>`
  padding: 8px 40px;
  font-size: 1.125rem;
  background: transparent;
  border: 2px solid ${Color.accentPrimary};
  color: ${Color.accentPrimary};
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.4s;
  white-space: nowrap;

  ${({ $active }) => ($active ? activeTabRules : '')}

  &:hover {
    ${activeTabRules}
  }

  &:not(:last-child) {
    border-right: none;
  }

  @media (max-width: 880px) {
    &:not(:last-child) {
      border-right: 2px solid ${Color.accentPrimary};
    }
  }

  @media (max-width: 550px) {
    white-space: wrap;
  }

  @media (max-width: 350px) {
    font-size: 0.875rem;
    padding: 8px 33px;
  }
`;

const FormContainer = styled.div`
  margin-top: 32px;
`;

type OpenedForm = 'create' | 'edit' | 'no_form';

const initialSelectedEditableClientState = null;

const ClientsMasterData = () => {
  const userHasAccessToAddClient = useHasAccess('client.create');
  const userHasAccessToEditClient = useHasAccess('client.update');
  const userHasAccessToDeleteClient = useHasAccess('client.delete');
  const userHasAccessToGetClients = useHasAccess('client.getAll');
  const userHasAccessToEditClientForm = useMemo(() => userHasAccessToGetClients && userHasAccessToEditClient, [userHasAccessToEditClient, userHasAccessToGetClients]);
  const userHasMinAccess = useMemo(() => userHasAccessToAddClient || userHasAccessToEditClientForm, [userHasAccessToEditClientForm, userHasAccessToAddClient]);

  const [t] = useGlobalTranslation();

  const location = useLocation();
  const settingsExpanded = useMemo(() => location.pathname === paths.settingsEditClientSection, [location.pathname]);

  const { trigger: triggerResetDeleteClientStatus, triggerState: deleteClientStatusTriggerState } = useTrigger();

  const { trigger: triggerClientsFetchRequest, triggerState: clientsTriggerState } = useTrigger();

  const [selectedEditableClient, setSelectedEditableClient] = useState<Client | null>(initialSelectedEditableClientState);

  const resetSelectedEditableClient = useCallback(() => {
    setSelectedEditableClient(initialSelectedEditableClientState);
  }, []);

  const [openedForm, setOpenedForm] = useState<OpenedForm>('no_form');

  const openCreateForm = useCallback(() => {
    resetSelectedEditableClient();
    setOpenedForm('create');
  }, [resetSelectedEditableClient]);

  const openEditForm = useCallback(() => {
    setOpenedForm('edit');
  }, []);

  const deleteButtonVisible = useMemo(() => !!selectedEditableClient, [selectedEditableClient]);

  const deleteSuccessCallback = useCallback(() => {
    triggerClientsFetchRequest();
  }, [triggerClientsFetchRequest]);

  const deleteCallbacks = useMemo(
    () => ({
      success: deleteSuccessCallback
    }),
    [deleteSuccessCallback]
  );

  const createFormIsActive = useMemo(() => openedForm === 'create', [openedForm]);
  const editFormIsActive = useMemo(() => openedForm === 'edit', [openedForm]);

  const form = useMemo(() => {
    if (openedForm === 'create' && userHasAccessToAddClient) {
      return <ClientAddForm />;
    }
    if (openedForm === 'edit' && userHasAccessToEditClientForm) {
      return (
        <ClientEditForm clientDeletedTriggerState={clientsTriggerState} parentSetSelectedEditableClient={setSelectedEditableClient} triggerResetDeleteClientStatus={triggerResetDeleteClientStatus} />
      );
    }

    if (openedForm === 'no_form') return '';

    throw new Error('Not a valid form');
  }, [clientsTriggerState, userHasAccessToEditClientForm, openedForm, triggerResetDeleteClientStatus, userHasAccessToAddClient]);

  const createClientTabRendered = useMemo(() => {
    if (userHasAccessToAddClient) {
      return (
        <Tab $active={createFormIsActive} onClick={openCreateForm}>
          {t('feature.client.label.createNewClient')}
        </Tab>
      );
    }

    return '';
  }, [createFormIsActive, openCreateForm, t, userHasAccessToAddClient]);

  const editClientTabRendered = useMemo(() => {
    if (userHasAccessToEditClientForm) {
      return (
        <Tab $active={editFormIsActive} onClick={openEditForm}>
          {t('feature.client.label.editExistentClient')}
        </Tab>
      );
    }

    return '';
  }, [userHasAccessToEditClientForm, editFormIsActive, openEditForm, t]);

  const deleteButtonRendered = useMemo(() => {
    if (deleteButtonVisible && userHasAccessToDeleteClient) {
      return <DeleteClientButton resetStatusTriggerState={deleteClientStatusTriggerState} deleteCallbacks={deleteCallbacks} client={selectedEditableClient as Client} />;
    }
    return '';
  }, [deleteButtonVisible, deleteCallbacks, deleteClientStatusTriggerState, selectedEditableClient, userHasAccessToDeleteClient]);

  const setInitialForm = useCallback(() => {
    if (userHasAccessToAddClient) {
      openCreateForm();
      return;
    }
    if (userHasAccessToEditClientForm) {
      openEditForm();
    }
  }, [userHasAccessToAddClient, userHasAccessToEditClientForm, openCreateForm, openEditForm]);

  useEffect(() => {
    setInitialForm();
  }, [setInitialForm]);

  if (!userHasMinAccess) return <div />;

  return (
    <SettingsSection title={t('page.settings.section.clientsMasterData.title')} expanded={settingsExpanded}>
      <Title>{t('page.settings.section.clientsMasterData.subTitle')}</Title>
      <InnerContainer>
        <ActionsContainer>
          <Tabs>
            {createClientTabRendered}
            {editClientTabRendered}
          </Tabs>
          {deleteButtonRendered}
        </ActionsContainer>
        <FormContainer>{form}</FormContainer>
      </InnerContainer>
    </SettingsSection>
  );
};

export default ClientsMasterData;
