import { styled } from 'styled-components';

import AboutTracePort from './components/AboutTracePort';
import ClientsMasterData, { useHasAccessToSettingsSectionClientsMasterData } from './components/ClientsMasterData';
import MyProfileSettings from './components/MyProfileSettings';
import { UsersManagement } from './components/UsersManagement';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = () => {
  return (
    <Container>
      <MyProfileSettings />
      <ClientsMasterData />
      <UsersManagement />
      <AboutTracePort />
    </Container>
  );
};

export { useHasAccessToSettingsSectionClientsMasterData };

export default Content;
