import { CheckCircle } from '@mui/icons-material';
import { styled as muiStyled } from '@mui/material';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const List = styled.ul`
  list-style: none;
  font-size: 0.8rem;
  font-weight: 500;
`;

const ListItem = styled.li<{ $fulfilled: boolean }>`
  padding: 4px 0;
  color: ${({ $fulfilled }) => ($fulfilled ? Color.accentPrimary : Color.typography)};
  transition: color 0.4s;
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: space-between;
  position: relative;
  padding-left: 12px;

  &::before {
    content: '-';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const CheckIcon = muiStyled(CheckCircle)<{ $show: boolean }>`
  width: 20px;
  fill: ${Color.accentPrimary};
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;

  ${({ $show }) => {
    if ($show) {
      return `
            visibility: visible;
            opacity: 1
        `;
    }
    return '';
  }}
`;

interface Props {
  password: string;
}

const PasswordValidationHelper: React.FC<Props> = ({ password }) => {
  const [t] = useGlobalTranslation();

  const passwordAtLeast8CharactersLong = useMemo(() => password.length >= 8, [password.length]);
  const passwordContainsAtLeast1CapitalLetter = useMemo(() => /[A-Z]/.test(password), [password]);
  const passwordContainsAtLeast1Digit = useMemo(() => /\d/.test(password), [password]);
  const passwordContainsAtLeast1SpecialCharacter = useMemo(() => /[^\w\d]/.test(password), [password]);

  return (
    <List>
      <ListItem $fulfilled={passwordAtLeast8CharactersLong}>
        {t('password.requirement.atLeastEightCharacters')}
        <CheckIcon $show={passwordAtLeast8CharactersLong} />
      </ListItem>
      <ListItem $fulfilled={passwordContainsAtLeast1CapitalLetter}>
        {t('password.requirement.atLeastOneCapitalLetter')}
        <CheckIcon $show={passwordContainsAtLeast1CapitalLetter} />
      </ListItem>
      <ListItem $fulfilled={passwordContainsAtLeast1Digit}>
        {t('password.requirement.atLeastOneDigit')}
        <CheckIcon $show={passwordContainsAtLeast1Digit} />
      </ListItem>
      <ListItem $fulfilled={passwordContainsAtLeast1SpecialCharacter}>
        {t('password.requirement.atLeastOneSpecialCharacter')}
        <CheckIcon $show={passwordContainsAtLeast1SpecialCharacter} />
      </ListItem>
    </List>
  );
};

export default PasswordValidationHelper;
