import { ButtonProps, FormControl, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { AxiosResponse } from 'axios';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { useGetClients } from 'features/clients/hooks';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { preventEventDefault } from 'utils/event';

import { ShipmentType } from '../constants';
import useSearchOrder from '../hooks/useSearchOrder';
import { Order, SearchOrderRequest } from '../types';

const OuterContainer = styled.div``;

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const InnerContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 40px 40px 16px;
  position: relative;
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  max-width: calc(25% - 16px);
  height: 80px;

  @media(max-width: 1000px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const StyledDateField = muiStyled(DateField)`
  max-width: calc(25% - 16px);
  height: 80px;

  @media(max-width: 1000px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  max-width: calc(25% - 16px);
  height: 80px;

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1000px) {
    max-width: none;
    height: auto;
    margin-bottom: 16px
  }
`;

const SubmitButtonContainerEl = styled.div`
max-width: calc(25% - 16px);
height: 80px;
width: 100%;

padding: 0 16px;

@media(max-width: 1000px) {
  max-width: none;
  height: auto
  margin-bottom: 16px
}
`;

const SubmitButtonContainerCo: React.FC<ButtonProps> = ({ children, ...otherProps }) => {
  return (
    <SubmitButtonContainerEl>
      <PrimaryButtonMedium {...otherProps} fullWidth>
        {children}
      </PrimaryButtonMedium>
    </SubmitButtonContainerEl>
  );
};

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;
const selectItemLabelResolver = (itemName: string) => `feature.order.label.select.item.${itemName}`;

interface Props {
  setFoundOrders: (_orders: Order[]) => void;
  refetchTrigger?: boolean;
}

const OrderSearchForm: React.FC<Props> = ({ setFoundOrders, refetchTrigger }) => {
  const [t] = useGlobalTranslation();

  const [isFormAlreadySubmitted, setIsFormAlreadySubmitted] = useState(false);

  const changeIsFormAlreadySubmittedStateToTrue = useCallback(() => setIsFormAlreadySubmitted(true), []);

  const { clients } = useGetClients();

  const clientsAsSelectItems = useMemo(() => {
    return clients.map((client) => ({ label: client.name, value: String(client.id) }));
  }, [clients]);

  const searchOrder = useSearchOrder();

  const formFields: FormFields = useMemo(() => {
    return [
      {
        name: 'orderNumber',
        label: 'orderNumber',
        type: 'text'
      },
      { label: 'client', name: 'clientId', type: 'select', labelFixed: true, items: clientsAsSelectItems },
      {
        name: 'notificationDate',
        label: 'notificationDate',
        type: 'date'
      },
      {
        name: 'goodIncomingDate',
        label: 'goodAcceptanceDate',
        type: 'date'
      },
      {
        name: 'recipient',
        label: 'recipient',
        type: 'text'
      },
      {
        name: 'goodOutgoingDate',
        label: 'goodOutgoingDate',
        type: 'date'
      },
      {
        name: 'shipmentType',
        label: 'shipmentType',
        type: 'select',
        items: [
          { label: ShipmentType.euro_pallet, value: ShipmentType.euro_pallet },
          { label: ShipmentType.packing_piece, value: ShipmentType.packing_piece },
          { label: ShipmentType.package, value: ShipmentType.package }
        ]
      },
      {
        fixedLabel: t('message.search'),
        type: 'submit'
      }
    ];
  }, [clientsAsSelectItems, t]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      selectItemLabelResolver,
      SubmitButton: SubmitButtonContainerCo,
      DateField: StyledDateField
    }),
    [formFields]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const callbacks = {
        success: (data: AxiosResponse<Order[]>) => {
          setFoundOrders(data.data);
          changeIsFormAlreadySubmittedStateToTrue();
        }
      };

      const data: SearchOrderRequest = getFormFieldsWithValues() as unknown as SearchOrderRequest;
      searchOrder(data, callbacks);
    },
    [changeIsFormAlreadySubmittedStateToTrue, getFormFieldsWithValues, searchOrder, setFoundOrders]
  );

  const formRef = useRef<HTMLFormElement | null>(null);

  // This variable is used to skip the initial execution of useEffect
  const isMounted = useRef(false);

  useEffect(() => {
    if (isFormAlreadySubmitted && formRef.current !== null) {
      if (isMounted.current) {
        const submitEvent = new Event('submit', { bubbles: true });
        formRef.current.dispatchEvent(submitEvent);
      } else {
        isMounted.current = true;
      }
    }
  }, [isFormAlreadySubmitted, refetchTrigger]);

  return (
    <OuterContainer>
      <Title>{t('message.search')}</Title>
      <InnerContainer>
        <form noValidate autoComplete="off" onSubmit={handleFormSubmit} ref={formRef}>
          <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
        </form>
      </InnerContainer>
    </OuterContainer>
  );
};

export default withHasAccessToFeature(OrderSearchForm, 'order.search');
