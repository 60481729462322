import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useCallback, useState } from 'react';

import { FormFieldsErrors } from 'types';

const useManageFormFieldsErrors = () => {
  const [formFieldsErrorsState, setFormFieldsErrorsState] = useState<FormFieldsErrors>([]);

  const [t] = useGlobalTranslation();

  const getErrorText = useCallback(
    (error?: string) => {
      if (!error) return '';
      return t(`form.error.${error}`);
    },
    [t]
  );

  const getFormFieldError = useCallback(
    (formFieldName: string) => {
      const error = formFieldsErrorsState?.find((errorField) => errorField.name === formFieldName)?.error;

      return getErrorText(error);
    },
    [formFieldsErrorsState, getErrorText]
  );

  const updateFormFieldsErrorsState = useCallback((formFieldsErrors: FormFieldsErrors | null) => {
    setFormFieldsErrorsState(formFieldsErrors || []);
  }, []);

  const removeErrorFromFormField = useCallback(
    (formFieldName: string) => {
      const newState = formFieldsErrorsState?.map((errorField) => (errorField.name === formFieldName ? { name: formFieldName, error: '' } : errorField)) || [];
      setFormFieldsErrorsState(newState);
    },
    [formFieldsErrorsState]
  );

  return {
    updateFormFieldsErrorsState,
    removeErrorFromFormField,
    getFormFieldError
  };
};

export default useManageFormFieldsErrors;
