import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

const useTransformWordToPDF = () => {
  const { transformWordToPDF } = useGetRequests();
  const { doRequest } = useManageRequests();

  const transform = useCallback(
    (data: FormData, callbacks: PromiseCallbacks) => {
      doRequest(transformWordToPDF(data), callbacks);
    },
    [doRequest, transformWordToPDF]
  );

  return transform;
};

export default useTransformWordToPDF;
