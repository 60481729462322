import { Access, Accesses } from 'auth/types';
import { hasAccessChecker } from 'auth/util';
import { useCallback } from 'react';
import useAuth from './useAuth';

const useGetAccessChecker = () => {
  const { userAccesses } = useAuth();

  const accessChecker = useCallback(
    (requiredAccess: Access) => {
      return hasAccessChecker(userAccesses, requiredAccess);
    },
    [userAccesses]
  );

  const accessCheckerSome = useCallback(
    (requiredAccesses: Accesses) => {
      return requiredAccesses.length === 0 || requiredAccesses.some((rAccess) => hasAccessChecker(userAccesses, rAccess));
    },
    [userAccesses]
  );

  return { accessChecker, accessCheckerSome };
};

export default useGetAccessChecker;
