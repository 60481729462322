import { I18nextProvider as Provider } from 'react-i18next';
import initI18Next from './initialize';

interface Props {
  children: React.ReactNode;
}

const I18nextProvider: React.FC<Props> = ({ children }) => {
  return <Provider i18n={initI18Next()}>{children}</Provider>;
};

export default I18nextProvider;
