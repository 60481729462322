import { pagesMinAccesses } from 'appConstants';
import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/Management/Warehouses/Content';

const Warehouses = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(Warehouses, pagesMinAccesses.warehouses);
