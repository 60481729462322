import { useMemo } from 'react';
import styled from 'styled-components';

import { extractServerRoleName, useHasAccess } from 'auth';
import Table from 'components/Table';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import { EditUserButton, IsUserActiveIcon, UsersSearchForm } from './components';
import { useGetUsers } from './hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 80px;
`;

const tableHeadersLabelResolver = (label: string) => `feature.usersManagement.usersList.table.header.${label}`;

interface Props {
  openEdit: (_userId: number, _username: string) => void;
}

const UsersList: React.FC<Props> = ({ openEdit }) => {
  const userHasAccessToEditUser = useHasAccess('user.update');

  const tableColumnHeaders = useMemo(() => {
    const headers = [
      { label: 'firstName', sortable: true },
      { label: 'lastName', sortable: true },
      { label: 'username', sortable: true },
      { label: 'role', sortable: true },
      { label: 'isActive', sortable: true }
    ];

    if (userHasAccessToEditUser) {
      headers.push({ label: 'edit', sortable: false });
    }

    return headers;
  }, [userHasAccessToEditUser]);

  const { users, search } = useGetUsers();

  const [t] = useGlobalTranslation();

  const tableTitle = useMemo(() => t('feature.usersManagement.usersList.table.title'), [t]);
  const tableHeight = useMemo(() => 300, []);

  const tableData = useMemo(() => {
    return users.map(({ firstName, lastName, name, isActive, id, roles }) => {
      const roleName = t(`role.label.${extractServerRoleName(roles).join(', ')}`);
      const editButton = <EditUserButton onClick={() => openEdit(id, name)} />;
      return {
        firstName: { value: firstName, label: firstName },
        lastName: { value: lastName, label: lastName },
        id: { label: id, value: id },
        username: { label: name, value: name },
        isActive: { label: <IsUserActiveIcon isActive={isActive} />, value: Number(isActive) },
        role: { label: roleName, value: roleName },
        edit: { label: editButton, value: 0 }
      };
    });
  }, [openEdit, t, users]);

  const tableProps = useMemo(
    () => ({
      title: tableTitle,
      height: tableHeight,
      columnHeaders: tableColumnHeaders,
      tableData,
      headerLabelResolver: tableHeadersLabelResolver,
      noDataMessage: t('message.noOrders'),
      type: 'sortable' as const
    }),
    [t, tableColumnHeaders, tableData, tableHeight, tableTitle]
  );

  return (
    <Container>
      <UsersSearchForm submit={search} />
      <Table {...tableProps} />
    </Container>
  );
};

export default withHasAccessToFeature(UsersList, 'user.getAll');
