import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { EditOrderRequest } from '../types';

const useEditOrder = () => {
  const { orderEdit } = useGetRequests();
  const { doRequest } = useManageRequests();

  const edit = useCallback(
    (data: EditOrderRequest, callbacks?: PromiseCallbacks, defaultPageLoading?: boolean) => {
      doRequest(orderEdit(data), callbacks, defaultPageLoading);
    },
    [doRequest, orderEdit]
  );

  return edit;
};

export default useEditOrder;
