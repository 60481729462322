import { DateFieldProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { DateFormField, FileFormFieldState, FormFieldsState, GetFormFieldValueFun } from '../types';

interface Props {
  field: DateFormField;
  DateField: React.FC<DateFieldProps<Dayjs>>;
  label: string;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  getFormFieldValue: GetFormFieldValueFun;
  error: string;
}

const FormDateField: React.FC<Props> = ({ field, DateField, label, changeFormFieldState, formFieldsState, fileFormFieldState, handleFormFieldChange, getFormFieldValue }) => {
  const handleDateFormFieldChange = useCallback(
    (newValue: Dayjs | null) => {
      let date = '';
      if (newValue !== null) {
        date = newValue.format('YYYY-MM-DD');
      }
      changeFormFieldState(field.name, date);
      handleFormFieldChange?.(newValue, field.name);
    },
    [changeFormFieldState, field.name, handleFormFieldChange]
  );

  const value = useMemo(() => {
    const stateValue = getFormFieldValue(field.name);
    if (typeof stateValue !== 'string') throw new Error('invalid type');
    return stateValue === null || stateValue === undefined || !stateValue.length ? null : dayjs(stateValue);
  }, [field.name, getFormFieldValue]);

  return (
    <DateField disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)} fullWidth variant="outlined" label={label} onChange={handleDateFormFieldChange} format="DD.MM.YYYY" value={value} />
  );
};

export default FormDateField;
