const paths = {
  settings: '/einstellungen',
  settingsEditClientSection: '/einstellungen/kundenstammdaten_bearbeiten',
  home: '/',
  login: '/anmelden',
  management: {
    masterData: '/verwaltung/stammdaten',
    users: '/verwaltung/benutzer',
    warehouses: '/verwaltung/lager'
  },
  newPassword: '/neues-password',
  resetPassword: '/passwort-zuruecksetzen',
  goodsNotification: '/warenavisierung',
  incomingGoods: '/wareneingang',
  inventoryManagement: '/bestandsmanagement',
  outgoingGoods: '/warenausgang',
  picking: '/kommissionierung',
  warehousing: '/lagerung'
};

export default paths;
