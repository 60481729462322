import { Check, Clear } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { ErrorButtonSmall } from 'components/UI/ErrorButton';
import useDisableWarehouse from 'features/warehouses/hooks/useDisableWarehouse';
import { ServerWarehouse } from 'features/warehouses/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { PromiseCallbacks } from 'types';

const StatusContainer = styled.div`
  border: 2px solid ${Color.failure};
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  text-align: center;
  color: ${Color.failure};

  svg {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }
`;

const IconButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
  justify-content: center;
`;

const SecondAssertionStatusContainer = styled(StatusContainer)`
  display: block;
`;

const StatusAssertionActionSection = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    gap: 0;
    flex-direction: column;
  }
`;

type Status = 'not_initiated' | 'first_assertion' | 'warehouse_disable_not_possible' | 'second_assertion' | 'failure';

const initialStatus: Status = 'not_initiated';

interface Props {
  warehouse: ServerWarehouse;
  callbacks?: PromiseCallbacks;
  resetStatusTriggerState?: boolean;
}

const DisableWarehouseButton: React.FC<Props> = ({ warehouse, callbacks, resetStatusTriggerState }) => {
  const [status, setStatus] = useState<Status>(initialStatus);

  const disableWarehouse = useDisableWarehouse();

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setStatus(initialStatus);
    }

    isMounted.current = true;
  }, [resetStatusTriggerState]);

  const changeStatusToNotInitiated = useCallback(() => setStatus('not_initiated'), []);
  const changeStatusToFirstAssertion = useCallback(() => setStatus('first_assertion'), []);
  const changeStatusToWarehouseDisableNotPossible = useCallback(() => setStatus('warehouse_disable_not_possible'), []);
  const changeStatusToSecondAssertion = useCallback(() => setStatus('second_assertion'), []);
  const changeStatusToFailure = useCallback(() => setStatus('failure'), []);

  const [t] = useGlobalTranslation();

  const warehouseName = useMemo(() => warehouse.locationName, [warehouse.locationName]);

  const warehouseDisableButtonRendered = useMemo(() => <ErrorButtonSmall onClick={changeStatusToFirstAssertion}>{t('message.disable')}</ErrorButtonSmall>, [changeStatusToFirstAssertion, t]);

  const isWarehouseAssociatedSuccessCallback = useCallback(
    (res: AxiosResponse<{ warehouseIsAssociated: boolean }>) => {
      if (res.data.warehouseIsAssociated) changeStatusToWarehouseDisableNotPossible();
      else changeStatusToSecondAssertion();
    },
    [changeStatusToWarehouseDisableNotPossible, changeStatusToSecondAssertion]
  );

  const failureCallback = useCallback(() => {
    changeStatusToFailure();
  }, [changeStatusToFailure]);

  const handleFirstWarehouseDisableAcceptance = useCallback(() => {
    const isWarehouseAssociatedCallbacks = {
      success: isWarehouseAssociatedSuccessCallback,
      failure: failureCallback
    };

    const isWarehouseAssociated = (
      __data: { id: number },
      testCallbacks: {
        success: (
          _res: AxiosResponse<{
            warehouseIsAssociated: boolean;
          }>
        ) => void;
        failure: () => void;
      }
    ) => {
      const promise = new Promise<{ data: { warehouseIsAssociated: false } }>((res, _) => {
        setTimeout(() => {
          res({ data: { warehouseIsAssociated: false } });
        }, 500);
      });

      promise.then((_data) => {
        testCallbacks.success(
          _data as unknown as AxiosResponse<{
            warehouseIsAssociated: boolean;
          }>
        );
      });
    };

    isWarehouseAssociated({ id: warehouse.id }, isWarehouseAssociatedCallbacks);
  }, [failureCallback, isWarehouseAssociatedSuccessCallback, warehouse.id]);

  const handleLastAssertionWarehouseDisableAcceptance = useCallback(() => {
    const extendedCallbacks = {
      success: callbacks?.success,
      failure: () => {
        failureCallback();
        callbacks?.failure?.();
      },
      finally: callbacks?.finally
    };

    const warehouseDisableData = { id: warehouse.id };

    disableWarehouse(warehouseDisableData, extendedCallbacks);
  }, [callbacks, warehouse.id, disableWarehouse, failureCallback]);

  const firstAssertionStatusMessageRendered = useMemo(
    () => (
      <StatusContainer>
        {t('feature.warehouse.message.shouldWarehouseBeDisabled', { warehouseName })}
        <IconButtonsContainer>
          <IconButton color="primary" onClick={handleFirstWarehouseDisableAcceptance}>
            <Check />
          </IconButton>
          <IconButton color="error" onClick={changeStatusToNotInitiated}>
            <Clear />
          </IconButton>
        </IconButtonsContainer>
      </StatusContainer>
    ),
    [t, warehouseName, handleFirstWarehouseDisableAcceptance, changeStatusToNotInitiated]
  );

  const warehouseDisableNotPossibleStatusMessageRendered = useMemo(
    () => <StatusContainer>{t('feature.warehouse.message.warehouseDisableFailed.warehouseAssociatedWithOrders', { warehouseName })}</StatusContainer>,
    [warehouseName, t]
  );

  const failureMessageRendered = useMemo(() => <StatusContainer>{t('message.errorOccurred')}</StatusContainer>, [t]);

  const secondAssertionStatusMessageRendered = useMemo(
    () => (
      <SecondAssertionStatusContainer>
        <p>{t('feature.warehouse.message.warehouseNotAssociatedWithOrders', { warehouseName })}</p>
        <StatusAssertionActionSection>
          <p>{t('feature.warehouse.message.shouldWarehouseBeDisabled', { warehouseName })}</p>
          <IconButtonsContainer>
            <IconButton color="primary" onClick={handleLastAssertionWarehouseDisableAcceptance}>
              <Check />
            </IconButton>
            <IconButton color="error" onClick={changeStatusToNotInitiated}>
              <Clear />
            </IconButton>
          </IconButtonsContainer>
        </StatusAssertionActionSection>
      </SecondAssertionStatusContainer>
    ),
    [t, warehouseName, handleLastAssertionWarehouseDisableAcceptance, changeStatusToNotInitiated]
  );

  if (status === 'not_initiated') {
    return warehouseDisableButtonRendered;
  }

  if (status === 'first_assertion') {
    return firstAssertionStatusMessageRendered;
  }

  if (status === 'warehouse_disable_not_possible') {
    return warehouseDisableNotPossibleStatusMessageRendered;
  }

  if (status === 'failure') {
    return failureMessageRendered;
  }

  if (status === 'second_assertion') {
    return secondAssertionStatusMessageRendered;
  }

  throw new Error('Not a valid status');
};

export default DisableWarehouseButton;
