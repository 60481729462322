import { styled } from 'styled-components';

import PageTitle from 'components/UI/Title/PageTitle';
import { GoodsNotificationForm } from 'features/goodsNotification/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const Container = styled.div`
  padding: 0 32px;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Content = () => {
  const [t] = useGlobalTranslation();

  return (
    <div>
      <PageTitle imageSrc="/images/features/order.png" titleText={t('page.goodsNotification.header')} />
      <Container>
        <GoodsNotificationForm />
      </Container>
    </div>
  );
};

export default Content;
