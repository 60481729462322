import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from 'appConstants';
import Facebook from 'components/Icons/Facebook';
import Instagram from 'components/Icons/Instagram';
import Linkedin from 'components/Icons/Linkedin';
import Xing from 'components/Icons/Xing';

const Container = styled.footer`
  padding: 88px 24px 40px;
  background: ${Color.footer_background};
  color: ${Color.white};
  margin-top: auto;
`;

const LogoContainer = styled.div`
  text-align: center;
`;

const Image = styled.img`
  width: 160px;
`;

const LowerSection = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  color: ${Color.white};
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1065px) {
    order: 2;
  }
`;

const Copyright = styled.span`
  font-weight: 600;
  font-size: 13px;
`;

const LegalLinksContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1065px) {
    order: 3;
  }
`;

const LegalLink = styled.a`
  color: ${Color.footer_grey};
  font-weight: 600;
  transition: color 0.3s;
  font-size: 13px;

  &:hover {
    color: ${Color.white};
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1065px) {
    order: 1;
    width: 100%;
    justify-content: center;
  }
`;

const iconCssRules = `   
fill: ${Color.footer_grey};
padding: 8px;

&:hover {
  fill: ${Color.white};
}`;

const StyledFacebook = styled(Facebook)`
  ${iconCssRules};
  width: 27px;
`;

const StyledInstagram = styled(Instagram)`
  ${iconCssRules};
  width: 30px;
`;

const StyledLinkedin = styled(Linkedin)`
  ${iconCssRules};
  width: 30px;
`;

const StyledXing = styled(Xing)`
  ${iconCssRules};
  width: 30px;
`;

const Footer = () => {
  return (
    <Container>
      <LogoContainer>
        <Image src="/images/logos/convidius_logo.png" alt="Convidius-Logo" />
      </LogoContainer>
      <LowerSection>
        <Text>
          <span>TracePort ist eine eingetragene Marke der&nbsp;&nbsp;</span>
          <Copyright>© 2023 CONVIDIUS business solutions GmbH</Copyright>
        </Text>
        <LegalLinksContainer>
          <LegalLink target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}/impressum`}>
            Impressum
          </LegalLink>
          <LegalLink target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}/datenschutzerklaerung`}>
            Datenschutzerklärung
          </LegalLink>
          <LegalLink target="_blank" href={`${process.env.REACT_APP_LANDING_PAGE_URL}/agb`}>
            AGB
          </LegalLink>
        </LegalLinksContainer>
        <SocialLinksContainer>
          <Link to="https://www.facebook.com/CONVIDIUS">
            <StyledFacebook />
          </Link>
          <Link to="https://www.instagram.com/convidius_gmbh">
            <StyledInstagram />
          </Link>
          <Link to="https://www.linkedin.com/convidius-business-solutions-gmbh">
            <StyledLinkedin />
          </Link>
          <Link to="https://www.xing.com/convidiusbusinesssolutionsgmbh">
            <StyledXing />
          </Link>
        </SocialLinksContainer>
      </LowerSection>
    </Container>
  );
};

export default Footer;
