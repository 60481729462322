import { v4 as uuidv4 } from 'uuid';

import { FileType } from 'appConstants';
import { AppFile } from 'types';

const transformPngDataUriToAppFile = (data: string): AppFile => {
  const byteString = atob(data);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const name = 'warehouse';

  const blob = new Blob([uint8Array], { type: 'image/png' });

  const appFile: AppFile = {
    id: uuidv4(),
    lastModified: new Date(),
    name,
    type: FileType.IMAGE,
    dataUri: `data:image/png;base64,${data}`,
    file: new File([blob], `${name}.png`, { type: 'image/png' })
  };

  return appFile;
};

export default transformPngDataUriToAppFile;
