import { FormControl, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { Color, paths } from 'appConstants';
import { FilesList, UploadInputWithIcon } from 'components/Files';
import { Form } from 'components/Form';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import { SectionTitle } from 'components/UI/Title';
import { Order } from 'features/orders/types';
import usePickOrder from 'features/outgoingGoods/hooks/usePickOrder';
import { PickGoodRequest } from 'features/outgoingGoods/types';
import { useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useNavigate } from 'react-router-dom';
import { PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';
import { transformResponseFilesToAppFiles } from 'utils/file';
import { generateCompletenessSelectOptions } from 'utils/form';

const Container = styled.div`
  margin: 24px auto;
`;

const TitleContainer = styled.div`
  margin-bottom: 8px;
  text-align: left;

  * {
    text-align: left;
  }
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  max-width: calc(50% - 16px);
  height: 80px;

  @media(max-width: 767px) {
    max-width: none;
    height: auto
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  max-width: calc(50% - 16px);
  height: 80px;

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 767px) {
    max-width: none;
    height: auto;
    margin-bottom: 16px
  }
`;

const StyledDateTimePicker = muiStyled(DateTimePicker)`
  max-width: calc(50% - 16px);
  height: 80px;
  width: 100%;

  @media(max-width: 767px) {
    max-width: none;
    height: auto;
    margin-bottom: 16px
  }
`;

const FormSubmissionContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StyledUploadInputWithIcon = styled(UploadInputWithIcon)`
  max-width: calc(50% - 16px);
  align-self: self-start;
  min-height: 80px;

  @media (max-width: 767px) {
    max-width: none;
    min-heigh: none;
    margin-bottom: 16px;
  }
`;

const FilesListingContainer = styled.div`
  max-width: calc(50% - 16px);
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    max-width: none;
  }
`;

const FilesListingHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  @media (max-width: 767px) {
    max-width: none;
  }
`;

const LinkToFile = styled.p`
  font-weight: 500;
  color: ${Color.typography};
`;

const LinkToFileImg = styled.img`
  height: 54px;
`;

const CancelButton = styled(PrimaryButtonLarge)`
  background: ${Color.failure};
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const generateNumberSelectOptions = (upTo: number) => Array.from({ length: upTo + 1 }, (_, i) => ({ value: String(i), label: String(i) }));

const numOfPalletsOptions = generateNumberSelectOptions(10);
const numOfPackagingPiecesOptions = generateNumberSelectOptions(20);
const numOfPackagesOptions = generateNumberSelectOptions(50);

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;
const selectItemLabelResolver = (itemName: string) => `message.${itemName}`;

interface Props {
  changeRequestStatus: { changeRequestStatusToSucceeded: () => void; changeRequestStatusToFailed: () => void };
  order: Order;
  callbacks?: PromiseCallbacks;
}

const ScopeOfShipmentForm: React.FC<Props> = ({ changeRequestStatus, order, callbacks }) => {
  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'recipient', name: 'recipient', type: 'text', initialValue: order.recipient },
      {
        name: 'numOfPallets',
        label: 'numOfPallets',
        type: 'select',
        items: numOfPalletsOptions,
        labelFixed: true,
        initialValue: String(order.numOfPallets)
      },
      {
        name: 'numOfPackagingPieces',
        label: 'numOfPackagingPieces',
        type: 'select',
        items: numOfPackagingPiecesOptions,
        labelFixed: true,
        isDisabled: () => true,
        initialValue: String(order.numOfPackagingPieces)
      },
      {
        name: 'numOfPackages',
        label: 'numOfPackages',
        type: 'select',
        items: numOfPackagesOptions,
        labelFixed: true,
        isDisabled: () => true,
        initialValue: String(order.numOfPackages)
      },
      { name: 'deliveryNote', type: 'file', label: 'deliveryNote', iconSrc: '/images/icons/file.png' },
      { name: 'damages', type: 'file', label: 'damages', iconSrc: '/images/icons/camera.png', multipleFiles: true },
      { name: 'completeness', label: 'completeness', type: 'select', items: generateCompletenessSelectOptions(), initialValue: order.completeness },
      { name: 'warehouseProperties', label: 'warehouseProperties', type: 'text', initialValue: order.warehouseProperties },
      { name: 'carrier', label: 'carrier', type: 'text', initialValue: order.carrier },
      {
        name: 'pickupTimeWindow',
        label: 'pickupTimeWindow',
        type: 'dateTime',
        initialValue: order.pickupTimeWindow
      }
    ];
  }, [order.carrier, order.completeness, order.numOfPackages, order.numOfPackagingPieces, order.numOfPallets, order.pickupTimeWindow, order.recipient, order.warehouseProperties]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormField: Select,
      SelectFormControl: StyledSelectFormControl,
      UploadFileComponent: StyledUploadInputWithIcon,
      DateTimeField: StyledDateTimePicker,
      selectItemLabelResolver
    }),
    [formFields]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const [t] = useGlobalTranslation();

  const pickOrder = usePickOrder();

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: PickGoodRequest = getFormFieldsWithValues() as unknown as PickGoodRequest;

      // These values should be changed to numbers to be compatible with the type GoodsAcceptanceData
      data.numOfPallets = Number(data.numOfPallets);

      data.orderId = order.id;

      const successCallback = () => {
        callbacks?.success?.();
        changeRequestStatus.changeRequestStatusToSucceeded();
      };

      const failureCallback = () => {
        callbacks?.failure?.();
        changeRequestStatus.changeRequestStatusToFailed();
      };

      const finallyCallbacks = () => {
        callbacks?.finally?.();
      };

      const extendedCallbacks = {
        success: successCallback,
        failure: failureCallback,
        finally: finallyCallbacks
      };

      pickOrder(data, extendedCallbacks);
    },
    [callbacks, changeRequestStatus, getFormFieldsWithValues, order.id, pickOrder]
  );

  const navigate = useNavigate();

  const handleCancelButtonClick = useCallback(() => {
    navigate(paths.home);
  }, [navigate]);

  return (
    <Container>
      <TitleContainer>
        <SectionTitle>{t('page.outgoingGoods.scopeOfShipment.form.title')}</SectionTitle>
      </TitleContainer>
      <Form onSubmit={handleFormSubmit}>
        <FormFieldsContainer>
          {formFieldsJSX.map((formField, i) => {
            if (i === 4) {
              return (
                <FilesListingContainer key="deliveryNote">
                  <FilesListingHeader>
                    <LinkToFileImg src="/images/icons/file.png" />
                    <LinkToFile>{t('feature.order.label.deliveryNote')}</LinkToFile>
                  </FilesListingHeader>
                  <FilesList feedbackIfEmpty files={transformResponseFilesToAppFiles(order.deliveryNote ? [order.deliveryNote] : [])} withDelete={false} />
                </FilesListingContainer>
              );
            }
            if (i === 5) {
              return (
                <FilesListingContainer key="damages">
                  <FilesListingHeader>
                    <LinkToFileImg src="/images/icons/camera.png" />
                    <LinkToFile>{t('feature.order.label.damages')}</LinkToFile>
                  </FilesListingHeader>
                  <FilesList feedbackIfEmpty files={transformResponseFilesToAppFiles(order.damages)} withDelete={false} />
                </FilesListingContainer>
              );
            }
            return formField;
          })}
        </FormFieldsContainer>
        <FormSubmissionContainer>
          <PrimaryButtonLarge type="submit">{t('page.outgoingGoods.scopeOfShipment.form.label.pickGood')}</PrimaryButtonLarge>
          <CancelButton onClick={handleCancelButtonClick}>{t('page.outgoingGoods.scopeOfShipment.form.label.cancel')}</CancelButton>
        </FormSubmissionContainer>
      </Form>
    </Container>
  );
};

export default ScopeOfShipmentForm;
