import { styled } from 'styled-components';

import { Color } from 'appConstants';

const Message = styled.div`
  border: 2px solid;
  padding: 11px 16px;
  border-radius: 4px;
  font-size: 1.125rem;
  font-weight: 500;
  width: fit-content;
  border-color: ${Color.accentPrimary};
  color: ${Color.accentPrimary};
`;

export default Message;
