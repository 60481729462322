import { ChangeEvent, ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FileType } from 'appConstants';
import { UploadFileProps } from 'components/Files';
import { AppFile } from 'types';
import { getFileType, readFile } from 'utils/file';

import { FileFormField, FileFormFieldState, FormFieldsState } from '../types';

interface Props {
  field: FileFormField;
  UploadFileComponent: React.FC<UploadFileProps>;
  label: string;
  setFilesFormFieldState: Dispatch<SetStateAction<FileFormFieldState>>;
  filesFormFieldState: FileFormFieldState;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  error: string;
}

const FormUploadFileField: React.FC<Props> = ({ field, UploadFileComponent, label, setFilesFormFieldState, formFieldsState, filesFormFieldState, handleFormFieldChange }) => {
  const handleMultipleFileFormFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
      if (!event.target.files) {
        throw new Error('Not a file input');
      }
      const newFiles = Array.from(event.target.files);

      let value: AppFile[] = [];

      newFiles.forEach((file) => {
        readFile(file).then((dataUri) => {
          const type = getFileType(file);
          if (type === FileType.NO_TYPE) {
            return;
          }

          const { name, lastModified } = file;

          const newFile = { lastModified: new Date(lastModified), dataUri, type, name, file, id: uuidv4() };
          setFilesFormFieldState((pre) => {
            const oldFiles = pre[fieldName] ? Array.from(pre[fieldName]) : [];
            const newFieldState = [...oldFiles, newFile];
            value = [...newFieldState];
            return {
              ...pre,
              [fieldName]: newFieldState
            };
          });
        });
      });
      handleFormFieldChange?.(value, fieldName);
    },
    [handleFormFieldChange, setFilesFormFieldState]
  );

  const handleSingleFileFormFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
      if (!event.target.files) {
        throw new Error('Not a file input');
      }
      const file = event.target.files[0];

      readFile(file).then((dataUri) => {
        const type = getFileType(file);
        if (type === FileType.NO_TYPE) {
          return;
        }

        const { name, lastModified } = file;

        const newFile = { lastModified: new Date(lastModified), dataUri, type, name, file, id: uuidv4() };

        handleFormFieldChange?.(newFile, fieldName);

        setFilesFormFieldState((pre) => ({
          ...pre,
          [fieldName]: [newFile]
        }));
      });
    },
    [handleFormFieldChange, setFilesFormFieldState]
  );

  const handleFileFormFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, fieldName: string, propsOnChange?: ChangeEventHandler<HTMLInputElement>, multiple = false) => {
      if (multiple) {
        handleMultipleFileFormFieldChange(event, fieldName);
      } else {
        handleSingleFileFormFieldChange(event, fieldName);
      }

      propsOnChange?.(event);
    },
    [handleMultipleFileFormFieldChange, handleSingleFileFormFieldChange]
  );

  const deleteFile = useCallback(
    (fieldName: string, file: AppFile) => {
      const newFileFieldState = filesFormFieldState[fieldName].filter((stateFile) => stateFile.id !== file.id);
      setFilesFormFieldState((pre) => {
        return {
          ...pre,
          [fieldName]: newFileFieldState
        };
      });
    },
    [filesFormFieldState, setFilesFormFieldState]
  );

  return (
    <UploadFileComponent
      disabled={field.isDisabled?.(formFieldsState, filesFormFieldState)}
      name={field.name}
      label={label}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileFormFieldChange(e, field.name, field.onChange, field.multipleFiles)}
      iconSrc={field.iconSrc}
      multipleFiles={field.multipleFiles}
      files={filesFormFieldState[field.name]}
      deleteFile={(file) => deleteFile(field.name, file)}
      accept={field.accept}
    />
  );
};

export default FormUploadFileField;
