import { LocalizationProvider as OriginalProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface Props {
  children: React.ReactNode;
}

const LocalizationProvider: React.FC<Props> = ({ children }) => {
  return <OriginalProvider dateAdapter={AdapterDayjs}>{children}</OriginalProvider>;
};

export default LocalizationProvider;
