import styled from 'styled-components';

import { Color } from 'appConstants';

const Container = styled.span<{ color: Color }>`
  display: inline-flex;

  svg {
    fill: ${({ color }) => color} !important;
  }
`;

interface Props {
  className?: string;
  color?: Color;
  Icon: React.FC;
}

const IconWrapper: React.FC<Props> = ({ Icon, color = Color.white, className }) => {
  return (
    <Container color={color} className={className}>
      <Icon />
    </Container>
  );
};

export default IconWrapper;
