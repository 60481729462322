import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { ServerRoles } from 'auth/types';

const useGetRoles = (refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { roleGetAll } = useGetRequests();

  const [roles, setRoles] = useState<ServerRoles>([]);

  const getRolesSuccessCallback = useCallback((res: AxiosResponse<ServerRoles>) => {
    setRoles(res.data);
  }, []);

  const getRoles = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<ServerRoles>) => {
          callbacks?.success?.();
          getRolesSuccessCallback(res);
        }
      };

      doRequest(roleGetAll(), extendedCallbacks);
    },
    [doRequest, getRolesSuccessCallback, roleGetAll]
  );

  useEffect(() => {
    getRoles();
  }, [getRoles, refetchTrigger]);

  return {
    roles
  };
};

export default useGetRoles;
