import { useCallback, useMemo, useState } from 'react';

import { useHasAccess } from 'auth';
import { PageTitle } from 'components/UI/Title';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import AddWarehouse from './components/AddWarehouse';
import EditWarehouse from './components/EditWarehouse';
import WarehouseDetails from './components/WarehouseDetails';
import WarehouseLayoutConfiguration from './components/WarehouseLayoutConfiguration';
import WarehousesList from './components/WarehousesList';

type View = 'warehouses' | 'addWarehouse' | 'warehouseDetails' | 'editWarehouse' | 'configureLayout';

const Content = () => {
  const [view, setView] = useState<View>('warehouses');

  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number | null>(null);

  const [t] = useGlobalTranslation();

  const changeToWarehousesView = useCallback(() => {
    setView('warehouses');
  }, []);

  const changeToAddWarehouseView = useCallback(() => {
    setView('addWarehouse');
  }, []);

  const changeToWarehouseDetailsView = useCallback(() => {
    setView('warehouseDetails');
  }, []);

  const changeToEditWarehouseView = useCallback(() => {
    setView('editWarehouse');
  }, []);

  const changeToConfigureLayoutView = useCallback(() => {
    setView('configureLayout');
  }, []);

  const selectWarehouse = useCallback(
    (warehouseId: number) => {
      setSelectedWarehouseId(warehouseId);
      changeToWarehouseDetailsView();
    },
    [changeToWarehouseDetailsView]
  );

  const unselectWarehouse = useCallback(() => {
    setSelectedWarehouseId(null);
  }, []);

  const closeWarehouseDetails = useCallback(() => {
    changeToWarehousesView();
    unselectWarehouse();
  }, [changeToWarehousesView, unselectWarehouse]);

  const userHasAccessToSeeWarehouseList = useHasAccess('warehouses.getList');
  const userHasAccessToCreateWarehouse = useHasAccess('warehouses.create');
  const userHasAccessToSeeWarehouseDetails = useHasAccess('warehouses.get');
  const userHasAccessToEditWarehouse = useHasAccess('warehouses.update');

  const viewToRender = useMemo(() => {
    if (view === 'warehouses' && (userHasAccessToSeeWarehouseList || userHasAccessToCreateWarehouse)) {
      return <WarehousesList changeToAddWarehouseView={changeToAddWarehouseView} selectWarehouse={selectWarehouse} />;
    }
    if (view === 'addWarehouse' && userHasAccessToCreateWarehouse) {
      return <AddWarehouse close={changeToWarehousesView} />;
    }
    if (view === 'warehouseDetails' && selectedWarehouseId && userHasAccessToSeeWarehouseDetails) {
      return <WarehouseDetails warehouseId={selectedWarehouseId} close={closeWarehouseDetails} onEditClick={changeToEditWarehouseView} onConfigureLayoutButtonClick={changeToConfigureLayoutView} />;
    }
    if (view === 'editWarehouse' && selectedWarehouseId && userHasAccessToEditWarehouse) {
      return <EditWarehouse warehouseId={selectedWarehouseId} close={changeToWarehouseDetailsView} />;
    }
    if (view === 'configureLayout' && selectedWarehouseId) {
      return <WarehouseLayoutConfiguration warehouseId={selectedWarehouseId} close={changeToWarehouseDetailsView} />;
    }
    throw new Error('Not a valid view.');
  }, [
    changeToAddWarehouseView,
    changeToConfigureLayoutView,
    changeToEditWarehouseView,
    changeToWarehouseDetailsView,
    changeToWarehousesView,
    closeWarehouseDetails,
    selectWarehouse,
    selectedWarehouseId,
    userHasAccessToCreateWarehouse,
    userHasAccessToEditWarehouse,
    userHasAccessToSeeWarehouseDetails,
    userHasAccessToSeeWarehouseList,
    view
  ]);

  return (
    <div>
      <PageTitle imageSrc="/images/features/warehouse.png" titleText={t('page.warehouses.header')} />
      {viewToRender}
    </div>
  );
};

export default Content;
