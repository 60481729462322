import { Dayjs, DateField as LibDateField, dayjs } from 'lib/componentLibrary';
import { useCallback, useMemo } from 'lib/react';

interface Props {
  value?: string;
  onChange?: (_value: string) => void;
  label?: string;
  error?: string;
  disabled?: boolean;
}

const DateField: React.FC<Props> = ({ disabled, label, error, value, onChange }) => {
  const handleChange = useCallback(
    (newValue: Dayjs | null) => {
      let date = '';
      if (newValue !== null) {
        date = newValue.format('YYYY-MM-DD');
      }
      onChange?.(date);
    },
    [onChange]
  );

  const adjustedValue = useMemo(() => {
    return value === null || value === undefined || !value.length ? null : dayjs(value);
  }, [value]);

  return (
    <LibDateField onChange={handleChange} value={adjustedValue} disabled={disabled} className="dateField" fullWidth variant="outlined" label={label} helperText={error || ''} format="DD.MM.YYYY" />
  );
};

export default DateField;
