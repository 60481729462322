import { FormControl, FormControlLabel, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { ServerRoles, useGetRoles } from 'auth';
import { Form } from 'components/Form';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useTrigger } from 'hooks/trigger';
import { AxiosError } from 'types';
import { preventEventDefault } from 'utils/event';

import ResetPasswordMail from './components/ResetPasswordMail';
import SubmitButton from './components/SubmitButton';
import { useEditUser, useGetUser } from './hooks';
import { EditUserRequest, GetUserResponse, SubmitEditButtonStatus } from './types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;
  max-width: calc(50% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  height: 80px;
  max-width: calc(50% - 16px);

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const SwitchFormControl = muiStyled(FormControlLabel)`
height: 80px;
max-width: calc(50% - 16px);
width: 100%;

@media(max-width: 1070px) {
  height: auto;
  max-width: 100%;
  margin-bottom: 16px
}
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.usersManagement.form.label.${formFieldName}`;

const INITIAL_SUBMIT_BUTTON_STATUS = 'not_initiated';

interface ContentProps {
  user: GetUserResponse;
  roles: ServerRoles;
  triggerRefetchUser: () => void;
}

const Content: React.FC<ContentProps> = ({ user, roles, triggerRefetchUser }) => {
  const [submitButtonStatus, setSubmitButtonStatus] = useState<SubmitEditButtonStatus>(INITIAL_SUBMIT_BUTTON_STATUS);

  const [t] = useGlobalTranslation();

  const changeSubmitButtonStatusToNotInitiated = useCallback(() => {
    setSubmitButtonStatus('not_initiated');
  }, []);

  const changeSubmitButtonStatusToAssertion = useCallback(() => {
    setSubmitButtonStatus('assertion');
  }, []);

  const changeSubmitButtonStatusToSuccess = useCallback(() => {
    setSubmitButtonStatus('success');
  }, []);

  const changeSubmitButtonStatusToFailure = useCallback(() => {
    setSubmitButtonStatus('failure');
  }, []);

  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const rolesMappedToSelectItems = useMemo(() => {
    if (!roles) return [];
    return roles.map((role) => ({ value: role.id, label: t(`role.label.${role.name}`) }));
  }, [roles, t]);

  const editUser = useEditUser();

  const handleFormFieldChange = useCallback(() => {
    changeSubmitButtonStatusToNotInitiated();
  }, [changeSubmitButtonStatusToNotInitiated]);

  const formFields: FormFields = useMemo(() => {
    const { firstName, lastName, email, phoneNumber, jobTitle, roles: userRoles, name, isActive } = user;

    return [
      { label: 'firstName', name: 'firstName', type: 'text', required: true, initialValue: firstName },
      { label: 'lastName', name: 'lastName', type: 'text', required: true, initialValue: lastName },
      { label: 'emailAddress', name: 'emailAddress', type: 'email', required: true, initialValue: email },
      { label: 'phoneNumber', name: 'phoneNumber', type: 'text', initialValue: phoneNumber },
      { label: 'jobTitle', name: 'jobTitle', type: 'text', initialValue: jobTitle },
      { label: 'role', name: 'roleIds', type: 'select', labelFixed: true, items: rolesMappedToSelectItems, required: true, initialValue: userRoles[0].id },
      { label: 'username', name: 'username', type: 'text', required: true, initialValue: name },
      { label: 'enable', name: 'isActive', type: 'switch', initialValue: isActive }
    ];
  }, [rolesMappedToSelectItems, user]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      SwitchFormControlLabel: SwitchFormControl,
      handleFormFieldChange,
      formFieldsErrorsState,
      removeErrorFromFormField
    }),
    [formFields, formFieldsErrorsState, handleFormFieldChange, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: EditUserRequest = getFormFieldsWithValues() as unknown as EditUserRequest;

      data.id = user.id;
      data.roleIds = [data.roleIds as unknown as number];

      const successCallback = () => {
        changeSubmitButtonStatusToSuccess();
        triggerRefetchUser();
      };

      const failureCallback = (errors: AxiosError) => {
        changeSubmitButtonStatusToFailure();
        updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
      };

      const callbacks = {
        success: successCallback,
        failure: failureCallback
      };

      editUser(data, callbacks);
    },
    [getFormFieldsWithValues, user.id, editUser, changeSubmitButtonStatusToSuccess, triggerRefetchUser, changeSubmitButtonStatusToFailure, updateFormFieldsErrorsState]
  );

  const shouldAssertDisableUser = useCallback(() => {
    const isUserInitiallyActive = user.isActive;
    const newValues = getFormFieldsWithValues() as unknown as EditUserRequest;
    const shouldUserBeInactive = !newValues.isActive;
    if (isUserInitiallyActive && shouldUserBeInactive) return true;
    return false;
  }, [getFormFieldsWithValues, user.isActive]);

  return (
    <Container>
      <ResetPasswordMail userId={user.id} />
      <Form onSubmit={handleFormSubmit}>
        <FormFieldsContainer>
          {formFieldsJSX}
          <SubmitButton
            status={submitButtonStatus}
            submit={handleFormSubmit}
            username={user.name}
            changeStatusToAssertion={changeSubmitButtonStatusToAssertion}
            changeStatusToNotInitiated={changeSubmitButtonStatusToNotInitiated}
            shouldAssert={shouldAssertDisableUser}
          />
        </FormFieldsContainer>
      </Form>
    </Container>
  );
};

interface Props {
  userId: number;
}

const EditUserForm: React.FC<Props> = ({ userId }) => {
  const { trigger: triggerRefetchUser, triggerState: getUserRefetchTrigger } = useTrigger();

  const { user } = useGetUser(userId, getUserRefetchTrigger);
  const { roles } = useGetRoles();

  return user && roles ? <Content user={user} roles={roles} triggerRefetchUser={triggerRefetchUser} /> : <div />;
};

export default withHasAccessToFeature(EditUserForm, 'user.getById', 'user.update', 'role.getAll');
