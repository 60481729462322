import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { GetWarehousesListResponse } from '../types';

const useGetAllWarehouses = (refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { warehouseGetList } = useGetRequests();

  const [warehouses, setWarehouses] = useState<GetWarehousesListResponse>([]);

  const getAllWarehousesSuccessCallback = useCallback((res: AxiosResponse<GetWarehousesListResponse>) => {
    setWarehouses(res.data);
  }, []);

  const getAllWarehouses = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<GetWarehousesListResponse>) => {
          callbacks?.success?.();
          getAllWarehousesSuccessCallback(res);
        }
      };

      doRequest(warehouseGetList(), extendedCallbacks);
    },
    [doRequest, getAllWarehousesSuccessCallback, warehouseGetList]
  );

  useEffect(() => {
    getAllWarehouses();
  }, [getAllWarehouses, refetchTrigger]);

  return {
    warehouses
  };
};

export default useGetAllWarehouses;
