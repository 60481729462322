import { FormControlProps, FormHelperText, InputLabel, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { useCallback, useMemo } from 'react';

import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { FileFormFieldState, FormFieldsState, GetFormFieldValueFun, SelectFormField } from '../types';

interface Props {
  field: SelectFormField;
  Select: React.FC<SelectProps<string>>;
  SelectFormControl: React.FC<FormControlProps>;
  label: string;
  getFormFieldValue: GetFormFieldValueFun;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  selectItemLabelResolver?: (_selectItemName: string) => string;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  error: string;
}

const FormSelectField: React.FC<Props> = ({
  field,
  label,
  getFormFieldValue,
  changeFormFieldState,
  SelectFormControl,
  Select,
  selectItemLabelResolver,
  formFieldsState,
  fileFormFieldState,
  handleFormFieldChange,
  error
}) => {
  const [t] = useGlobalTranslation();

  const handleSelectFormFieldChange = useCallback(
    (e: SelectChangeEvent, textFieldName: string) => {
      const { value } = e.target;
      changeFormFieldState(textFieldName, value);

      field.onChange?.(e);

      handleFormFieldChange?.(value, textFieldName);
    },
    [changeFormFieldState, field, handleFormFieldChange]
  );

  const printSelectMenuItemLabel = useCallback(
    (itemLabel: string, labelFixed?: boolean) => {
      if (labelFixed) return itemLabel;
      if (!selectItemLabelResolver) throw new Error('An item label resolver or labelFixed must be true');
      return t(selectItemLabelResolver?.(itemLabel));
    },
    [selectItemLabelResolver, t]
  );

  const errorAdjustedText = useMemo(() => {
    if (error === t('form.error.empty')) {
      return t('form.error.emptySelect');
    }
    return error;
  }, [error, t]);

  const value = useMemo(() => {
    const stateValue = getFormFieldValue(field.name);
    if (typeof stateValue === 'boolean') {
      return String(stateValue);
    }
    return stateValue;
  }, [field.name, getFormFieldValue]);

  return (
    <SelectFormControl fullWidth disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)} error={!!error}>
      <InputLabel id={`select-${label}`}>{label}</InputLabel>
      <Select labelId={`select-${label}`} variant="outlined" label={label} onChange={(e) => handleSelectFormFieldChange(e, field.name)} value={value}>
        {field.items.map((item) => (
          <MenuItem key={`${field.name}-${item.value}`} value={item.value}>
            {printSelectMenuItemLabel(item.label, field.labelFixed)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorAdjustedText || ''}</FormHelperText>
    </SelectFormControl>
  );
};

export default FormSelectField;
