import { useMemo } from 'react';

import { TABLE_CELL_HEIGHT } from 'appConstants';
import Table from 'components/Table';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { formatDate, getShipmentTypeLabel, printPickupTimeWindow } from 'utils/strings';

import useGetRecentOrders from '../hooks/useGetRecentOrders';

const columnHeaders = ['order', 'client', 'shipmentCompany', 'date', 'pickupTimeWindow', 'shipmentType'];

const headerLabelResolver = (label: string) => `feature.order.label.${label}`;

interface Props {
  height: number;
  noVerticalScrolling?: boolean;
  refetchTrigger?: boolean;
}

const RecentOrdersTable: React.FC<Props> = ({ refetchTrigger, height, noVerticalScrolling }) => {
  const [t] = useGlobalTranslation();

  const ordersCount = useMemo(() => (height - TABLE_CELL_HEIGHT) / TABLE_CELL_HEIGHT, [height]);

  const getRecentOrdersRequestData = useMemo(() => ({ count: ordersCount }), [ordersCount]);

  const recentOrders = useGetRecentOrders(getRecentOrdersRequestData, undefined, undefined, refetchTrigger);

  const tableTitle = useMemo(() => t('feature.order.recentOrders.table.title'), [t]);
  const tableNoDataMessage = useMemo(() => t('message.noOrders'), [t]);

  const tableData = useMemo(() => {
    return recentOrders.map(({ orderNumber, client, shipmentType, carrier, id, goodsIncomingDate, pickupTimeWindow }) => {
      return {
        client: client?.name,
        id,
        order: orderNumber,
        shipmentCompany: carrier,
        date: formatDate(goodsIncomingDate),
        pickupTimeWindow: printPickupTimeWindow(pickupTimeWindow),
        shipmentType: getShipmentTypeLabel(shipmentType, t)
      };
    });
  }, [recentOrders, t]);

  const tableProps = useMemo(
    () => ({
      title: tableTitle,
      height,
      columnHeaders,
      tableData,
      headerLabelResolver,
      noDataMessage: tableNoDataMessage,
      noVerticalScrolling
    }),
    [height, noVerticalScrolling, tableData, tableNoDataMessage, tableTitle]
  );

  return <Table {...tableProps} />;
};

export default withHasAccessToFeature(RecentOrdersTable, 'order.getRecent');
