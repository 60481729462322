import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { IDRequestData, PromiseCallbacks } from 'types';

const useIsClientAssociatedWithRunningOrders = () => {
  const { doRequest } = useManageRequests();
  const { clientIsAssociatedWithRunningOrder } = useGetRequests();

  const isClientAssociated = useCallback(
    (data: IDRequestData, callbacks?: PromiseCallbacks) => {
      doRequest(clientIsAssociatedWithRunningOrder(data), callbacks);
    },
    [clientIsAssociatedWithRunningOrder, doRequest]
  );

  return {
    isClientAssociated
  };
};

export default useIsClientAssociatedWithRunningOrders;
