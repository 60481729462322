import { configureStore } from '@reduxjs/toolkit';

import { authenticationReducer } from 'auth';

import languageReducer from './features/language/languageSlice';
import pageLoadingReducer from './features/page/pageLoadingSlice';
import pageSideNavReducer from './features/page/pageSideNavSlice';
import serverHealthReducer from './features/server/serverHealthSlice';

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    language: languageReducer,
    pageLoading: pageLoadingReducer,
    pageSideNav: pageSideNavReducer,
    serverHealth: serverHealthReducer
  }
});

export default store;
