import { FormControlLabelProps, Switch } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { FileFormFieldState, FormFieldsState, GetFormFieldValueFun, SwitchFormField } from '../types';

interface Props {
  field: SwitchFormField;
  SwitchFormControlLabel: React.FC<FormControlLabelProps>;
  label: string;
  getFormFieldValue: GetFormFieldValueFun;
  changeFormFieldState: (_fieldName: string, _fieldValue: boolean) => void;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: string, _formFieldName: string) => void;
}

const FormSwitchField: React.FC<Props> = ({ field, SwitchFormControlLabel, label, getFormFieldValue, changeFormFieldState, formFieldsState, fileFormFieldState, handleFormFieldChange }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { value } = e.target;
      changeFormFieldState(field.name, checked);

      field.onChange?.(e, checked);
      handleFormFieldChange?.(value, field.name);
    },
    [changeFormFieldState, field, handleFormFieldChange]
  );

  const checked = useMemo(() => {
    const stateValue = getFormFieldValue(field.name);
    if (typeof stateValue !== 'boolean') throw new Error('invalid type');
    return stateValue;
  }, [field.name, getFormFieldValue]);

  return <SwitchFormControlLabel disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)} label={label} control={<Switch onChange={handleChange} checked={checked} />} />;
};

export default FormSwitchField;
