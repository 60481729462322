import type { Accesses } from 'auth';

type Page = 'home' | 'goodsNotification' | 'incomingGoods' | 'inventoryManagement' | 'warehouses' | 'outgoingGoods';

type PagesMinAccesses = Record<Page, Accesses>;

const pagesMinAccesses: PagesMinAccesses = {
  home: [],
  goodsNotification: ['notification.create'],
  incomingGoods: ['notification.getAll'],
  inventoryManagement: ['order.getRecent', 'order.search'],
  warehouses: ['warehouses.create', 'warehouses.getList'],
  outgoingGoods: ['warehouse.getAll']
};

export default pagesMinAccesses;
