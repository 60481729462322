import { useCallback } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

const useAddWarehouse = () => {
  const { warehouseAdd } = useGetRequests();
  const { doRequest } = useManageRequests();

  const add = useCallback(
    (data: FormData, callbacks: PromiseCallbacks, defaultPageLoading = true) => {
      doRequest(warehouseAdd(data), callbacks, defaultPageLoading);
    },
    [doRequest, warehouseAdd]
  );

  return add;
};

export default useAddWarehouse;
