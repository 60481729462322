import { styled as muiStyled } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import Table from 'components/Table';
import { TableDataItem } from 'components/Table/types';
import EditButton from 'components/UI/EditButton';
import { RecentOrdersTable } from 'features/orders/components';
import OrderSearchForm from 'features/orders/components/OrderSearchForm';
import { Order } from 'features/orders/types';
import { OverdueOrdersTable } from 'features/overdueOrders/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useTrigger } from 'hooks/trigger';
import { formatDate, getShipmentTypeLabel } from 'utils/strings';

import { useHasAccess } from 'auth';
import OrderEditDialog from './components/OrderEditDialog';

const Container = styled.div`
  margin-top: 16px;
`;

const TablesSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const TableContainer = styled.div`
  width: calc(50% - 12px);

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Section = styled.div`
  margin-top: 32px;
`;

const StyledEditButton = muiStyled(EditButton)<{ active: boolean }>`
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

const headerLabelResolver = (label: string) => `feature.order.label.${label}`;

const Content = () => {
  const userHasAccessToEditOrder = useHasAccess('order.update');

  const columnHeaders = useMemo(() => {
    const headers = ['order', 'client', 'notification', 'goodsAcceptance', 'recipient', 'goodsOutgoing', 'shipmentType'];

    if (userHasAccessToEditOrder) {
      headers.push('editOrder');
    }

    return headers;
  }, [userHasAccessToEditOrder]);

  const [foundOrders, setFoundOrders] = useState<Order[]>([]);
  const [hoveredOrder, setHoveredOrder] = useState<Order | null>(null);
  const [editOrderDialogOpen, setEditOrderDialogOpen] = useState(false);
  const [selectedEditableOrder, setSelectedEditableOrder] = useState<Order | null>(null);

  const { triggerState: ordersTablesRefetchTriggerState, trigger: triggerOrdersTablesRefetch } = useTrigger();

  const openEditOrderDialog = useCallback(() => setEditOrderDialogOpen(true), []);
  const closeEditOrderDialog = useCallback(() => setEditOrderDialogOpen(false), []);

  const hoveredOrderId = useMemo(() => hoveredOrder?.id, [hoveredOrder?.id]);

  const [t] = useGlobalTranslation();

  const tableNoDataMessage = useMemo(() => t('message.noOrders'), [t]);

  const tableTitle = useMemo(() => t('message.searchResults'), [t]);

  const showTable = useMemo(() => !!foundOrders.length, [foundOrders.length]);

  const findOrderFromTable = useCallback((order: TableDataItem) => foundOrders.find((item) => item.id === order.id), [foundOrders]);

  const handleTableBodyRowHover = useCallback(
    (_e: MouseEvent, item: TableDataItem) => {
      const hoveredTableOrder = findOrderFromTable(item);
      if (!hoveredTableOrder) {
        throw new Error('Order not found');
      }
      setHoveredOrder(hoveredTableOrder);
    },
    [findOrderFromTable]
  );

  const handleTableEditOrderButtonClick = useCallback(
    (order: Order) => {
      setSelectedEditableOrder(order);
      openEditOrderDialog();
    },
    [openEditOrderDialog]
  );

  const tableData = useMemo(() => {
    return foundOrders.map((order) => ({
      id: order.id,
      order: order.orderNumber,
      client: order.client.name,
      notification: formatDate(order.notificationDate),
      goodsAcceptance: formatDate(order.goodsIncomingDate),
      recipient: order.recipient,
      goodsOutgoing: formatDate(order.goodsOutgoingDate),
      shipmentType: getShipmentTypeLabel(order.shipmentType, t),
      editOrder: <StyledEditButton active={order.id === hoveredOrderId} onClick={() => handleTableEditOrderButtonClick(order)} />
    }));
  }, [foundOrders, handleTableEditOrderButtonClick, hoveredOrderId, t]);

  const tableProps = useMemo(
    () => ({
      columnHeaders,
      headerLabelResolver,
      height: 400,
      noDataMessage: tableNoDataMessage,
      tableData,
      title: tableTitle,
      onTableBodyRowHover: handleTableBodyRowHover
    }),
    [columnHeaders, handleTableBodyRowHover, tableData, tableNoDataMessage, tableTitle]
  );

  const foundOrdersTableRendered = useMemo(() => (showTable ? <Table {...tableProps} /> : ''), [showTable, tableProps]);

  const editOrderCallbacks = useMemo(
    () => ({
      success: triggerOrdersTablesRefetch
    }),
    [triggerOrdersTablesRefetch]
  );

  const userHasAccessToRecentOrdersTable = useHasAccess('order.getRecent');
  const userHasAccessToSearchOrders = useHasAccess('order.search');

  const recentOrdersTableRendered = useMemo(() => {
    if (userHasAccessToRecentOrdersTable) {
      return (
        <TableContainer>
          <RecentOrdersTable height={210} refetchTrigger={ordersTablesRefetchTriggerState} />
        </TableContainer>
      );
    }

    return '';
  }, [ordersTablesRefetchTriggerState, userHasAccessToRecentOrdersTable]);

  const orderSearchFormRendered = useMemo(() => {
    if (userHasAccessToSearchOrders) {
      return (
        <>
          <Section>
            <OrderSearchForm refetchTrigger={ordersTablesRefetchTriggerState} setFoundOrders={setFoundOrders} />
          </Section>
          <Section>{foundOrdersTableRendered}</Section>
        </>
      );
    }

    return '';
  }, [foundOrdersTableRendered, ordersTablesRefetchTriggerState, userHasAccessToSearchOrders]);

  return (
    <Container>
      <OrderEditDialog parentRequestCallbacks={editOrderCallbacks} close={closeEditOrderDialog} isOpen={editOrderDialogOpen} order={selectedEditableOrder as Order} />
      <TablesSection>
        {recentOrdersTableRendered}
        <TableContainer>
          <OverdueOrdersTable height={210} />
        </TableContainer>
      </TablesSection>
      {orderSearchFormRendered}
    </Container>
  );
};

export default Content;
