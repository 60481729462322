import { Language } from '@mui/icons-material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled as muiStyled } from '@mui/system';
import { useMemo } from 'react';
import styled from 'styled-components';

import { Color } from 'appConstants';
import IconButton from 'components/UI/IconButton';
import useManageLanguage from 'hooks/language/useManageLanguage';

const StyledLanguageIcon = muiStyled(Language)<{ _color: Color }>`
  width: 24px;
  color: ${({ _color }) => _color};
`;

const NextLangMessage = styled.span<{ color: Color }>`
  font-size: 1rem;
  color: ${({ color }) => color};
`;

interface Props {
  color: Color;
}

const LanguageSwitcher: React.FC<Props> = ({ color }) => {
  const { nextLanguage, changeSelectedLanguage } = useManageLanguage();

  const printableNextLanguage = useMemo(() => nextLanguage.toUpperCase(), [nextLanguage]);

  return (
    <IconButton onClick={changeSelectedLanguage}>
      <StyledLanguageIcon _color={color} />
      <NextLangMessage color={color}>{printableNextLanguage}</NextLangMessage>
    </IconButton>
  );
};

export default LanguageSwitcher;
