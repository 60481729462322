import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import type { GetIncomingGoodsResponse } from '../types';

const useGetIncomingGoods = (refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { incomingGoodsGetAll } = useGetRequests();

  const [incomingGoods, setIncomingGoods] = useState<GetIncomingGoodsResponse[]>([]);

  const getIncomingGoodsSuccessCallback = useCallback((res: AxiosResponse<GetIncomingGoodsResponse[]>) => {
    setIncomingGoods(res.data);
  }, []);

  const getIncomingGoods = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<GetIncomingGoodsResponse[]>) => {
          callbacks?.success?.();
          getIncomingGoodsSuccessCallback(res);
        }
      };

      doRequest(incomingGoodsGetAll(), extendedCallbacks);
    },
    [doRequest, getIncomingGoodsSuccessCallback, incomingGoodsGetAll]
  );

  useEffect(() => {
    getIncomingGoods();
  }, [getIncomingGoods, refetchTrigger]);

  return {
    incomingGoods
  };
};

export default useGetIncomingGoods;
