import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

export const addAuthHeader = (authToken: string) => {
  axios.defaults.headers.common.Authorization = authToken;
};

export const removeAuthHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};
