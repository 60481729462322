import { Accesses } from 'auth/types';

import useHasEveryAccess from 'auth/hooks/useHasEveryAccess';

const withHasAccessToFeature = <T extends object>(WrappedComponent: React.FC<T>, ...accesses: Accesses) => {
  return (props: T) => {
    const userHasAccess = useHasEveryAccess(accesses);

    if (!userHasAccess) {
      return <div>Forbidden</div>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withHasAccessToFeature;
