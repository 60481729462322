import { Backdrop, CircularProgress, styled } from '@mui/material';
import { zIndices } from 'appConstants';
import useManagePageLoading from 'hooks/page/useMangePageLoading';

const StyledBackdrop = styled(Backdrop)`
  z-index: ${zIndices['app.backdrop']};
`;

const PageSpinner = () => {
  const { pageIsLoading } = useManagePageLoading();

  return (
    <StyledBackdrop open={pageIsLoading}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
};

export default PageSpinner;
