import { ArrowRightAlt } from '@mui/icons-material';
import { FormControl, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import React, { FormEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { Color, paths } from 'appConstants';
import { Form } from 'components/Form';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { useGetClients } from 'features/clients/hooks';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';

import { ShipmentType } from '../constants';
import useEditOrder from '../hooks/useEditOrder';
import { EditOrderRequest, Order } from '../types';

const OuterContainer = styled.div``;

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const InnerContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 40px 40px 16px;
  position: relative;
`;

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;

  @media(max-width: 1000px) {
    height: auto
  }
`;

const StyledDateField = muiStyled(DateField)`
  height: 80px;

  @media(max-width: 1000px) {
    height: auto
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  height: 80px;

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1000px) {
    height: auto;
  }
`;

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const FormFieldContainerEl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: calc(33% - 16px);
  width: 100%;

  @media (max-width: 1000px) {
    max-width: none;
    margin-bottom: 16px;
  }
`;

const EditIcon = styled.img`
  width: 35px;
  margin-bottom: 24px;

  @media (max-width: 1000px) {
    margin-bottom: 0;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const FormFieldContainerCo = ({ children }: { children: React.ReactNode }) => {
  return (
    <FormFieldContainerEl>
      {children}
      <EditIcon src="/images/icons/edit.png" />
    </FormFieldContainerEl>
  );
};

const formFieldLabelResolver = (formFieldName: string) => `feature.order.label.${formFieldName}`;
const selectItemLabelResolver = (itemName: string) => `feature.order.label.select.item.${itemName}`;

interface Props {
  order: Order;
  callbacks?: PromiseCallbacks;
  showEditClientsButton: boolean;
}

const OrderEditForm: React.FC<Props> = ({ order, callbacks, showEditClientsButton }) => {
  const [t] = useGlobalTranslation();

  const { clients } = useGetClients();

  const clientsAsSelectItems = useMemo(() => {
    return clients.map((client) => ({ label: client.name, value: String(client.id) }));
  }, [clients]);

  const editOrder = useEditOrder();

  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'client', name: 'clientId', type: 'select', labelFixed: true, items: clientsAsSelectItems, Container: FormFieldContainerCo, initialValue: String(order.client.id) },
      {
        name: 'goodIncomingDate',
        label: 'goodAcceptanceDate',
        type: 'date',
        Container: FormFieldContainerCo,
        initialValue: order.goodsIncomingDate
      },
      {
        name: 'recipient',
        label: 'recipient',
        type: 'text',
        Container: FormFieldContainerCo,
        initialValue: order.recipient
      },
      {
        name: 'notificationDate',
        label: 'notificationDate',
        type: 'date',
        Container: FormFieldContainerCo,
        initialValue: order.notificationDate
      },
      {
        name: 'goodOutgoingDate',
        label: 'goodOutgoingDate',
        type: 'date',
        Container: FormFieldContainerCo,
        initialValue: order.goodsOutgoingDate
      },
      {
        name: 'shipmentType',
        label: 'shipmentType',
        type: 'select',
        items: [
          { label: ShipmentType.euro_pallet, value: ShipmentType.euro_pallet },
          { label: ShipmentType.packing_piece, value: ShipmentType.packing_piece },
          { label: ShipmentType.package, value: ShipmentType.package }
        ],
        Container: FormFieldContainerCo,
        initialValue: order.shipmentType
      }
    ];
  }, [clientsAsSelectItems, order.client.id, order.goodsIncomingDate, order.goodsOutgoingDate, order.notificationDate, order.recipient, order.shipmentType]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      selectItemLabelResolver,
      DateField: StyledDateField,
      widthEditIcon: true
    }),
    [formFields]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: EditOrderRequest = getFormFieldsWithValues() as unknown as EditOrderRequest;

      data.id = order.id;

      editOrder(data, callbacks);
    },
    [callbacks, editOrder, getFormFieldsWithValues, order.id]
  );

  const editClientsButtonRendered = useMemo(() => {
    if (showEditClientsButton) {
      return (
        <Link to={paths.settingsEditClientSection}>
          <PrimaryButtonMedium>
            <ArrowRightAlt />
            {t('feature.client.label.editClientMasterData')}
          </PrimaryButtonMedium>
        </Link>
      );
    }

    return '';
  }, [showEditClientsButton, t]);

  return (
    <OuterContainer>
      <Title>{t('feature.order.label.editOrder')}</Title>
      <InnerContainer className="order_edit_form_inner_container">
        <Form onSubmit={handleFormSubmit}>
          <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
          <ButtonsContainer>
            {editClientsButtonRendered}
            <PrimaryButtonMedium type="submit">{t('message.saveChanges')}</PrimaryButtonMedium>
          </ButtonsContainer>
        </Form>
      </InnerContainer>
    </OuterContainer>
  );
};

export default withHasAccessToFeature(OrderEditForm, 'order.update');
