import { useCallback, useState } from 'react';

import { FormFieldsErrors } from 'types';

const useFormFieldsErrorState = () => {
  const [formFieldsErrorsState, setFormFieldsErrorsState] = useState<FormFieldsErrors>([]);

  const updateFormFieldsErrorsState = useCallback((formFieldsErrors: FormFieldsErrors | null) => {
    setFormFieldsErrorsState(formFieldsErrors || []);
  }, []);

  const removeErrorFromFormField = useCallback(
    (formFieldName: string) => {
      const newState = formFieldsErrorsState?.map((errorField) => (errorField.name === formFieldName ? { name: formFieldName, error: '' } : errorField)) || [];
      setFormFieldsErrorsState(newState);
    },
    [formFieldsErrorsState]
  );

  return {
    formFieldsErrorsState,
    updateFormFieldsErrorsState,
    removeErrorFromFormField
  };
};

export default useFormFieldsErrorState;
