import { useCallback, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';
import Client from '../types/client';

const useGetClients = (trigger?: boolean, callbacks?: PromiseCallbacks) => {
  const { doRequest } = useManageRequests();
  const { clientsGetAll } = useGetRequests();

  const [clients, setClients] = useState<Client[]>([]);

  const getClientsSuccessCallback = useCallback((res: AxiosResponse<Client[]>) => {
    setClients(res.data);
  }, []);

  const getClients = useCallback(() => {
    const extendedCallbacks = {
      ...callbacks,
      success: (res: AxiosResponse<Client[]>) => {
        callbacks?.success?.();
        getClientsSuccessCallback(res);
      }
    };

    doRequest(clientsGetAll(), extendedCallbacks);
  }, [callbacks, clientsGetAll, doRequest, getClientsSuccessCallback]);

  useEffect(() => {
    getClients();
  }, [getClients, trigger]);

  return {
    clients
  };
};

export default useGetClients;
