/* eslint-disable no-unused-vars */
enum Color {
  accentPrimary = 'rgb(76, 175, 80)',
  background_white = 'rgb(250, 250, 250)',
  failure = 'rgb(211, 47, 47)',
  footer_background = 'rgb(56, 56, 56)',
  footer_grey = 'rgb(136,153,168)',
  grey = 'rgb(245, 245, 245)',
  grey1 = 'rgb(217, 217, 217)',
  inputBorder = 'rgba(0, 0, 0, 0.23)',
  overlay = 'rgba(0, 0, 0, 0.5)',
  hover = 'rgba(0, 0, 0, 0.04)',
  topNav = 'rgb(32, 106, 93)',
  topNavActive = 'rgb(114, 231, 91)',
  topNavActiveTypography = 'rgba(32,106,93,.9)',
  typography = 'rgba(0, 0, 0, 0.87)',
  typography_input = 'rgb(113, 113, 113)',
  white = 'rgb(255, 255, 255)',
  accordionAccent = 'rgb(224, 224, 224)',
  accordionBackground = 'rgba(242, 242, 242, 1)',
  linkBlue = 'rgb(22 119 182)'
}

export default Color;
