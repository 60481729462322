import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

import { Color } from 'appConstants';

import SectionTitle from './SectionTitle';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Color.grey1};
  padding: 16px 8px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  flex: 1;
  text-align: center;
`;

interface Props {
  title: string;
  close: () => void;
  className?: string;
}

const SectionTitleWithCloseButton: React.FC<Props> = ({ title, close, className }) => {
  return (
    <HeaderContainer className={className}>
      <StyledSectionTitle>{title}</StyledSectionTitle>
      <IconButton onClick={close}>
        <Close />
      </IconButton>
    </HeaderContainer>
  );
};

export default SectionTitleWithCloseButton;
