import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { GetNotificationsResponse } from '../types';

const useGetNotifications = (refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { notificationGetAll } = useGetRequests();

  const [notifications, setNotifications] = useState<GetNotificationsResponse[]>([]);

  const getNotificationsSuccessCallback = useCallback((res: AxiosResponse<GetNotificationsResponse[]>) => {
    setNotifications(res.data);
  }, []);

  const getNotifications = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<GetNotificationsResponse[]>) => {
          callbacks?.success?.();
          getNotificationsSuccessCallback(res);
        }
      };

      doRequest(notificationGetAll(), extendedCallbacks);
    },
    [doRequest, getNotificationsSuccessCallback, notificationGetAll]
  );

  useEffect(() => {
    getNotifications();
  }, [getNotifications, refetchTrigger]);

  return {
    notifications
  };
};

export default useGetNotifications;
