import styled from 'styled-components';

const Title = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
`;

export default Title;
