import { Link } from 'react-router-dom';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { useAppVersions } from 'hooks/app';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

import { useHasAccess } from 'auth';
import SettingsSection from './SettingSection';

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const InfoTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  width: 150px;
`;

const Info = styled.div`
  font-size: 1rem;
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 32px;
`;

const StyledLink = styled(Link)`
  color: ${Color.accentPrimary};
  font-weight: 600;
`;

const AboutTracePort = () => {
  const [t] = useGlobalTranslation();

  const { backendVersion, frontendVersion } = useAppVersions();

  const userHasAccess = useHasAccess('application.getVersion');

  if (!userHasAccess) return <div />;

  return (
    <SettingsSection title={t('page.settings.section.aboutTracePort.title')}>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.aboutTracePort.label.frontendVersion')}</InfoTitle>
        <Info>{frontendVersion}</Info>
      </InfoContainer>
      <InfoContainer>
        <InfoTitle>{t('page.settings.section.aboutTracePort.label.backendVersion')}</InfoTitle>
        <Info>{backendVersion}</Info>
      </InfoContainer>
      <LinksContainer>
        <StyledLink to="/">{t('page.settings.section.aboutTracePort.label.copyrightProtected')}</StyledLink>
        <StyledLink to="/">{t('page.settings.section.aboutTracePort.label.wordmark')}</StyledLink>
      </LinksContainer>
    </SettingsSection>
  );
};

export default AboutTracePort;
