import { createTheme } from '@mui/material';

import { Color } from 'appConstants';

const theme = createTheme({
  palette: {
    primary: { main: Color.accentPrimary, contrastText: '#ffffff' },
    error: { main: Color.failure, contrastText: '#ffffff' }
  },

  typography: { fontFamily: 'Roboto, sans-serif' }
});

export default theme;
