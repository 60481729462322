import { ArrowRightAlt } from '@mui/icons-material';
import { ButtonProps, FormControl, Select, SelectChangeEvent, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import React, { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { StatusMessageProps } from 'components/StatusMessage/types';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { useGetClients, useUpdateClient } from 'features/clients/hooks';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { useCombineTriggersState, useTrigger } from 'hooks/trigger';
import { AxiosError, Status } from 'types';
import { preventEventDefault } from 'utils/event';
import { castToString } from 'utils/strings';

import { Client, UpdateClientRequest } from '../types';
import { preventPostCodeChange } from '../util';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;
  max-width: calc(25% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const TextareaEl = muiStyled(TextField)`
  height: 148px;
  max-width: calc(50%);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const FormSpaceFiller = styled.div`
  width: calc(25% - 16px);

  @media (max-width: 1070px) {
    display: none;
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  height: 80px;
  max-width: calc(25% - 16px);

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const SubmitButtonContainerEl = styled.div<{ $maxWidth: string }>`
max-width: ${({ $maxWidth }) => $maxWidth};
height: 80px;
width: calc(50% - 16px);
margin-right: auto;

padding: 0 16px;

@media(max-width: 1070px) {
  max-width: none;
  width: 100%;
  height: auto
  margin-bottom: 16px
}
`;

const StyledPrimaryButtonMedium = muiStyled(PrimaryButtonMedium)`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center
`;

const submitButtonContainerWithStatus = (status: Status, statusMessageProps: StatusMessageProps, maxWidth: string): React.FC<ButtonProps> => {
  return ({ children, ...otherProps }) => (
    <SubmitButtonContainerEl $maxWidth={maxWidth}>
      <QueryButton
        StatusMessage={StatusMessage}
        button={
          <StyledPrimaryButtonMedium {...otherProps} fullWidth>
            <ArrowRightAlt />
            {children}
          </StyledPrimaryButtonMedium>
        }
        status={status}
        statusMessageProps={statusMessageProps}
      />
    </SubmitButtonContainerEl>
  );
};

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const TextareaCo: React.FC<TextFieldProps> = (props) => {
  return <TextareaEl {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.client.label.${formFieldName}`;

const formFieldNames = [
  'name',
  'newName',
  'companyName',
  'businessManagement',
  'customerAdviser',
  'street',
  'houseNumber',
  'otherLocationInformation',
  'city',
  'postCode',
  'country',
  'rating',
  'iban',
  'commentsAndNotes'
];

interface Props {
  parentSetSelectedEditableClient: (_client: Client) => void;
  clientDeletedTriggerState?: boolean;
  triggerResetDeleteClientStatus?: () => void;
}

const ClientEditForm: React.FC<Props> = ({ parentSetSelectedEditableClient, clientDeletedTriggerState = false, triggerResetDeleteClientStatus }) => {
  const [t] = useGlobalTranslation();

  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const { trigger: triggerClientsFetchRequest, triggerState: clientsTriggerState } = useTrigger();

  const combinedClientsTriggerState = useCombineTriggersState(clientsTriggerState, clientDeletedTriggerState);

  const { clients } = useGetClients(combinedClientsTriggerState);
  const updateClient = useUpdateClient();

  const [selectedEditableClient, setSelectedEditableClient] = useState<Client | null>(null);

  const {
    status: requestStatus,
    changeToSucceeded: changeRequestStatusToSucceeded,
    changeToFailed: changeRequestStatusToFailed,
    changeToNotInitiated: changeRequestStatusToNotInitiated
  } = useStatus();

  const queryButtonMaxWidth = useMemo(() => (requestStatus === 'not_initiated' ? '316px' : '100%'), [requestStatus]);

  const clientsAsSelectItems = useMemo(() => {
    return clients.map((client) => ({ label: client.name, value: String(client.id) }));
  }, [clients]);

  const handleFormFieldChange = useCallback(() => {
    changeRequestStatusToNotInitiated();
    triggerResetDeleteClientStatus?.();
  }, [changeRequestStatusToNotInitiated, triggerResetDeleteClientStatus]);

  const handleClientNameChange = useCallback(
    (e: SelectChangeEvent) => {
      const clientId = Number(e.target.value);
      const selectedClient = clients.find((client) => client.id === clientId);

      if (!selectedClient) {
        throw new Error('Not a valid client id');
      }

      setSelectedEditableClient(selectedClient);
      parentSetSelectedEditableClient(selectedClient);
    },
    [clients, parentSetSelectedEditableClient]
  );

  const formFieldSubmitButton = useCallback(() => {
    return submitButtonContainerWithStatus(
      requestStatus,
      {
        status: requestStatus,
        successText: t('feature.client.message.clientAdded', { clientName: selectedEditableClient?.name }),
        failureText: t('message.errorOccurred')
      },
      queryButtonMaxWidth
    );
  }, [queryButtonMaxWidth, requestStatus, selectedEditableClient?.name, t]);

  const formFields: FormFields = useMemo(() => {
    return [
      { label: formFieldNames[0], name: formFieldNames[0], type: 'select', items: clientsAsSelectItems, labelFixed: true, onChange: handleClientNameChange, required: true },
      { label: formFieldNames[1], name: formFieldNames[1], type: 'text', initialValue: selectedEditableClient?.name },
      { label: formFieldNames[2], name: formFieldNames[2], type: 'text', initialValue: selectedEditableClient?.companyName },
      { label: formFieldNames[3], name: formFieldNames[3], type: 'text', initialValue: selectedEditableClient?.businessManagement },
      { label: formFieldNames[4], name: formFieldNames[4], type: 'text', initialValue: selectedEditableClient?.customerAdviser },
      { label: formFieldNames[5], name: formFieldNames[5], type: 'text', initialValue: selectedEditableClient?.street, required: true },
      { label: formFieldNames[6], name: formFieldNames[6], type: 'text', initialValue: selectedEditableClient?.houseNumber, required: true },
      { label: formFieldNames[7], name: formFieldNames[7], type: 'text', initialValue: selectedEditableClient?.otherLocationInformation },
      { label: formFieldNames[8], name: formFieldNames[8], type: 'text', initialValue: selectedEditableClient?.city, required: true },
      { label: formFieldNames[9], name: formFieldNames[9], type: 'number', initialValue: selectedEditableClient?.postCode, required: true, preventChange: preventPostCodeChange },
      { label: formFieldNames[10], name: formFieldNames[10], type: 'text', initialValue: selectedEditableClient?.country, required: true },
      { label: formFieldNames[11], name: formFieldNames[11], type: 'text', initialValue: castToString(selectedEditableClient?.rating) },
      { label: formFieldNames[12], name: formFieldNames[12], type: 'text', initialValue: selectedEditableClient?.iban },
      { label: formFieldNames[13], name: formFieldNames[13], type: 'textarea', initialValue: selectedEditableClient?.commentsAndNotes },
      { type: 'submit', fixedLabel: t('message.saveChanges') }
    ];
  }, [
    clientsAsSelectItems,
    handleClientNameChange,
    selectedEditableClient?.businessManagement,
    selectedEditableClient?.city,
    selectedEditableClient?.commentsAndNotes,
    selectedEditableClient?.companyName,
    selectedEditableClient?.country,
    selectedEditableClient?.customerAdviser,
    selectedEditableClient?.houseNumber,
    selectedEditableClient?.iban,
    selectedEditableClient?.name,
    selectedEditableClient?.otherLocationInformation,
    selectedEditableClient?.postCode,
    selectedEditableClient?.rating,
    selectedEditableClient?.street,
    t
  ]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      Textarea: TextareaCo,
      SubmitButton: formFieldSubmitButton(),
      handleFormFieldChange,
      formFieldsErrorsState,
      removeErrorFromFormField
    }),
    [formFields, handleFormFieldChange, formFieldSubmitButton, formFieldsErrorsState, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues, changeFormFieldState } = useManageFormFields(manageFormFieldsParams);

  const handleSelectedEditableClientChange = useCallback(() => {
    formFieldNames.forEach((formFieldName) => {
      if (selectedEditableClient === null) {
        changeFormFieldState(formFieldName, '');
        return;
      }

      let value = castToString((selectedEditableClient as any)[formFieldName]);

      if (formFieldName === 'name') {
        return;
      }

      if (formFieldName === 'newName') {
        value = castToString(selectedEditableClient.name);
      }

      changeFormFieldState(formFieldName, value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEditableClient]);

  useEffect(() => {
    handleSelectedEditableClientChange();
  }, [handleSelectedEditableClientChange, selectedEditableClient]);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const formFieldValues = getFormFieldsWithValues();
      const data: UpdateClientRequest = formFieldValues as unknown as UpdateClientRequest;

      if (selectedEditableClient === null) {
        return;
      }

      data.id = selectedEditableClient.id;
      data.name = formFieldValues.newName;

      const successCallback = () => {
        changeRequestStatusToSucceeded();
        triggerClientsFetchRequest();
      };

      const failureCallback = (errors: AxiosError) => {
        changeRequestStatusToFailed();
        updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
      };

      const callbacks = {
        success: successCallback,
        failure: failureCallback
      };

      updateClient(data, callbacks);
    },
    [changeRequestStatusToFailed, changeRequestStatusToSucceeded, getFormFieldsWithValues, selectedEditableClient, triggerClientsFetchRequest, updateClient, updateFormFieldsErrorsState]
  );

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      setSelectedEditableClient(null);
    }

    isMounted.current = true;
  }, [clientDeletedTriggerState]);

  const extendedFormFieldJSX = useMemo(() => {
    return formFieldsJSX.map((e, i) => {
      if (i === 13) {
        return (
          <React.Fragment key="with_form_space_filler">
            <FormSpaceFiller />
            <FormSpaceFiller />
            {e}
          </React.Fragment>
        );
      }
      return e;
    });
  }, [formFieldsJSX]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormFieldsContainer>{extendedFormFieldJSX}</FormFieldsContainer>
    </Form>
  );
};

export default withHasAccessToFeature(ClientEditForm, 'client.update', 'client.getAll');
