import { pagesMinAccesses } from 'appConstants';
import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/IncomingGoods/Content';

const IncomingGoods = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(IncomingGoods, pagesMinAccesses.incomingGoods);
