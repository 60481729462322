import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import { useCallback } from 'react';
import type { PromiseCallbacks } from 'types';
import { CreateNotificationRequest } from '../types';

const useCreateNotification = () => {
  const { notificationCreate } = useGetRequests();
  const { doRequest } = useManageRequests();

  const create = useCallback(
    (data: CreateNotificationRequest, callbacks: PromiseCallbacks) => {
      doRequest(notificationCreate(data), callbacks);
    },
    [doRequest, notificationCreate]
  );

  return create;
};

export default useCreateNotification;
