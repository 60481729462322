import { v4 as uuidv4 } from 'uuid';

import { FileType } from 'appConstants';
import { AppFile } from 'types';

const convertImageElementToPngAppFile = (imageElement: HTMLImageElement, name: string): AppFile => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = imageElement.width;
  canvas.height = imageElement.height;

  if (!context) {
    throw new Error('canvas is not supported');
  }

  context.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);

  const dataUri = canvas.toDataURL('image/png');

  const byteString = atob(dataUri.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: 'image/png' });

  const appFile: AppFile = {
    id: uuidv4(),
    lastModified: new Date(),
    name,
    type: FileType.IMAGE,
    dataUri,
    file: new File([blob], `${name}.png`, { type: 'image/png' })
  };

  return appFile;
};

export default convertImageElementToPngAppFile;
