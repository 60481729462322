import { useMemo } from 'react';

import Table from 'components/Table';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

interface Props {
  height: number;
  noVerticalScrolling?: boolean;
}

const OverdueOrdersTable: React.FC<Props> = ({ height, noVerticalScrolling }) => {
  const [t] = useGlobalTranslation();

  const tableProps = useMemo(
    () => ({
      title: t('feature.overdueOrders.table.title'),
      height,
      columnHeaders: [],
      tableData: [],
      headerLabelResolver: (test: string) => test,
      noDataMessage: t('feature.overdueOrders.table.noData'),
      noVerticalScrolling
    }),
    [height, noVerticalScrolling, t]
  );

  return <Table {...tableProps} />;
};

export default OverdueOrdersTable;
