import i18next from 'i18next';

import global_de from 'translations/de/global.json';
import global_en from 'translations/en/global.json';
import { getInitialLanguage } from 'utils/language/persist';

const initI18Next = () => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: getInitialLanguage(),
    resources: {
      en: { global: global_en },
      de: { global: global_de }
    }
  });

  return i18next;
};

export default initI18Next;
