import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useGetRequests from 'hooks/request/useGetRequests';
import useManageRequests from 'hooks/request/useManageRequests';
import type { PromiseCallbacks } from 'types';

import { GetUserResponse } from '../types';

const useGetUser = (userId: number, refetchTrigger?: boolean) => {
  const { doRequest } = useManageRequests();
  const { userGet } = useGetRequests();

  const [user, setUser] = useState<GetUserResponse | null>(null);

  const getUserSuccessCallback = useCallback((res: AxiosResponse<GetUserResponse>) => {
    setUser(res.data);
  }, []);

  const getUser = useCallback(
    (callbacks?: PromiseCallbacks) => {
      const extendedCallbacks = {
        ...callbacks,
        success: (res: AxiosResponse<GetUserResponse>) => {
          callbacks?.success?.();
          getUserSuccessCallback(res);
        }
      };

      doRequest(userGet(userId), extendedCallbacks);
    },
    [doRequest, getUserSuccessCallback, userGet, userId]
  );

  useEffect(() => {
    getUser();
  }, [getUser, refetchTrigger]);

  return {
    user
  };
};

export default useGetUser;
