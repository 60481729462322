import { changeLanguage } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguageAction } from 'redux/features/language/languageSlice';
import type { RootState } from 'redux/types';
import { saveSelectedLanguage } from 'utils/language/persist';

const selectedLanguageSelector = (state: RootState) => state.language.selectedLanguage;

const useManageLanguage = () => {
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const dispatch = useDispatch();

  const nextLanguage = useMemo(() => (selectedLanguage === 'en' ? 'de' : 'en'), [selectedLanguage]);

  const changeSelectedLanguage = useCallback(() => {
    changeLanguage(nextLanguage);
    saveSelectedLanguage(nextLanguage);
    dispatch(changeLanguageAction(nextLanguage));
  }, [dispatch, nextLanguage]);

  return {
    selectedLanguage,
    nextLanguage,
    changeSelectedLanguage
  };
};

export default useManageLanguage;
