/* eslint-disable indent */
import { FormFieldMeta, FormFieldsMeta, FormFieldsState } from '../types';

const getInitialValue = (field: FormFieldMeta) => {
  let initialValue = field.initialValue;
  if (!initialValue) {
    switch (field.type) {
      case 'multiSelect':
      case 'file':
        initialValue = [];
        break;
      case 'switch':
        initialValue = false;
        break;
      default:
        initialValue = '';
    }
  }

  return initialValue;
};

const generateInitialState = (formFieldsMeta: FormFieldsMeta) => {
  const result: FormFieldsState = {};

  formFieldsMeta.forEach((field) => {
    const value = getInitialValue(field);

    result[field.name] = { value };
  });

  return result;
};

export default generateInitialState;
