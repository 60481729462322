import { ArrowRightAlt } from '@mui/icons-material';
import { ButtonProps, FormControl, FormControlLabel, Select, TextField, TextFieldProps, styled as muiStyled } from '@mui/material';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { useGetRoles, useHasAccess } from 'auth';
import { Form } from 'components/Form';
import { QueryButton } from 'components/QueryButton';
import { StatusMessage } from 'components/StatusMessage';
import { StatusMessageProps } from 'components/StatusMessage/types';
import { PrimaryButtonMedium } from 'components/UI/PrimaryButton';
import { withHasAccessToFeature } from 'features/hocComponents';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useStatus } from 'hooks/status';
import { AxiosError, Status } from 'types';
import { preventEventDefault } from 'utils/event';

import { useAddUser } from './hooks';
import { AddUserRequest } from './types';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledTextField = muiStyled(TextField)`
  height: 80px;
  max-width: calc(50% - 16px);

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const StyledSelectFormControl = muiStyled(FormControl)`
  height: 80px;
  max-width: calc(50% - 16px);

  &.error * {
    color: ${Color.failure} !important;
  }

  @media(max-width: 1070px) {
    height: auto;
    max-width: 100%;
    margin-bottom: 16px
  }
`;

const SubmitButtonContainerEl = styled.div<{ $maxWidth: string }>`
max-width: ${({ $maxWidth }) => $maxWidth};
height: 80px;
width: calc(50% - 16px);
margin-right: auto;

padding: 0 16px;

@media(max-width: 1000px) {
  max-width: none;
  width: 100%;
  height: auto
  margin-bottom: 16px
}
`;

const StyledPrimaryButtonMedium = muiStyled(PrimaryButtonMedium)`
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center
`;

const SwitchFormControl = muiStyled(FormControlLabel)`
height: 80px;
max-width: calc(50% - 16px);
width: 100%;

@media(max-width: 1070px) {
  height: auto;
  max-width: 100%;
  margin-bottom: 16px
}
`;

const submitButtonContainerWithStatus = (status: Status, statusMessageProps: StatusMessageProps, maxWidth: string): React.FC<ButtonProps> => {
  return ({ children, ...otherProps }) => (
    <SubmitButtonContainerEl $maxWidth={maxWidth}>
      <QueryButton
        StatusMessage={StatusMessage}
        button={
          <StyledPrimaryButtonMedium {...otherProps} fullWidth>
            <ArrowRightAlt />
            {children}
          </StyledPrimaryButtonMedium>
        }
        status={status}
        statusMessageProps={statusMessageProps}
      />
    </SubmitButtonContainerEl>
  );
};

const CoStyledTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

const formFieldLabelResolver = (formFieldName: string) => `feature.usersManagement.form.label.${formFieldName}`;

const AddUserForm = () => {
  const userHasAccess = useHasAccess('user.create');

  const [t] = useGlobalTranslation();

  const { roles } = useGetRoles();

  const rolesMappedToSelectItems = useMemo(() => {
    if (!roles) return [];
    return roles.map((role) => ({ value: role.id, label: t(`role.label.${role.name}`) }));
  }, [roles, t]);

  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const [addedUsername, setAddedUsername] = useState('');

  const addUser = useAddUser();

  const {
    status: requestStatus,
    changeToSucceeded: changeRequestStatusToSucceeded,
    changeToFailed: changeRequestStatusToFailed,
    changeToNotInitiated: changeRequestStatusToNotInitiated
  } = useStatus();

  const queryButtonMaxWidth = useMemo(() => (requestStatus === 'not_initiated' ? '316px' : '100%'), [requestStatus]);

  const handleFormFieldChange = useCallback(() => {
    changeRequestStatusToNotInitiated();
  }, [changeRequestStatusToNotInitiated]);

  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'firstName', name: 'firstName', type: 'text', required: true },
      { label: 'lastName', name: 'lastName', type: 'text', required: true },
      { label: 'emailAddress', name: 'emailAddress', type: 'email', required: true },
      { label: 'phoneNumber', name: 'phoneNumber', type: 'text' },
      { label: 'jobTitle', name: 'jobTitle', type: 'text' },
      { label: 'role', name: 'roleIds', type: 'select', labelFixed: true, items: rolesMappedToSelectItems, required: true },
      { label: 'username', name: 'username', type: 'text', required: true },
      { label: 'enable', name: 'isActive', type: 'switch' },
      { type: 'submit', fixedLabel: t('feature.usersManagement.form.label.addUser') }
    ];
  }, [rolesMappedToSelectItems, t]);

  const formFieldSubmitButton = useCallback(() => {
    return submitButtonContainerWithStatus(
      requestStatus,
      {
        status: requestStatus,
        successText: t('feature.usersManagement.addUser.message.userAdded', { username: addedUsername }),
        failureText: t('message.errorOccurred')
      },
      queryButtonMaxWidth
    );
  }, [addedUsername, queryButtonMaxWidth, requestStatus, t]);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      TextFormField: CoStyledTextField,
      SelectFormControl: StyledSelectFormControl,
      SelectFormField: Select,
      SwitchFormControlLabel: SwitchFormControl,
      SubmitButton: formFieldSubmitButton(),
      handleFormFieldChange,
      formFieldsErrorsState,
      removeErrorFromFormField
    }),
    [formFieldSubmitButton, formFields, formFieldsErrorsState, handleFormFieldChange, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues, initializeAllFormFieldState } = useManageFormFields(manageFormFieldsParams);

  const handleFormSubmit = useCallback(
    (e: FormEvent) => {
      preventEventDefault(e);

      const data: AddUserRequest = getFormFieldsWithValues() as unknown as AddUserRequest;
      data.roleIds = [data.roleIds as unknown as number];

      const successCallback = () => {
        setAddedUsername(data.username);
        changeRequestStatusToSucceeded();
        initializeAllFormFieldState();
      };

      const failureCallback = (errors: AxiosError) => {
        changeRequestStatusToFailed();
        updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
      };

      const callbacks = {
        success: successCallback,
        failure: failureCallback
      };

      addUser(data, callbacks);
    },
    [changeRequestStatusToFailed, changeRequestStatusToSucceeded, addUser, getFormFieldsWithValues, initializeAllFormFieldState, updateFormFieldsErrorsState]
  );

  if (!userHasAccess) {
    return <div>Forbidden</div>;
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormFieldsContainer>{formFieldsJSX}</FormFieldsContainer>
    </Form>
  );
};

export default withHasAccessToFeature(AddUserForm, 'user.create');
