import styled from 'styled-components';

import { SectionTitleWithCloseButton } from 'components/UI/Title';
import { EditWarehouseForm } from 'features/warehouses/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const EditWarehouseFormContainer = styled.div`
  margin: 32px 0 16px;
`;

interface Props {
  close: () => void;
  warehouseId: number;
}

const EditWarehouse: React.FC<Props> = ({ close, warehouseId }) => {
  const [t] = useGlobalTranslation();

  return (
    <div>
      <SectionTitleWithCloseButton title={t('feature.warehouse.editWarehouse')} close={close} />
      <EditWarehouseFormContainer>
        <EditWarehouseForm cancel={close} warehouseId={warehouseId} />
      </EditWarehouseFormContainer>
    </div>
  );
};

export default EditWarehouse;
