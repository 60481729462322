import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { useHasAccess } from 'auth';
import Message from 'components/UI/Message';
import { PrimaryButtonLarge } from 'components/UI/PrimaryButton';
import PageTitle from 'components/UI/Title/PageTitle';
import { GoodsNotificationTable } from 'features/goodsNotification/components';
import { GetNotificationsResponse } from 'features/goodsNotification/types';
import { IncomingGoodsForm } from 'features/incomingGoods/components';
import { Order } from 'features/orders/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { useTrigger } from 'hooks/trigger';

const Container = styled.div`
  padding: 0 32px;
  display: flex;
  gap: 48px;

  @media (max-width: 1240px) {
    display: block;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const TableContainer = styled.div`
  width: calc(50% - 16px);

  @media (max-width: 1240px) {
    width: 100%;
    margin: 24px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PDFContainer = styled.div`
  margin-top: 39px;
  width: calc(50% - 16px);
  height: 400px;

  @media (max-width: 1240px) {
    width: 100%;
    margin: 24px auto;
    max-width: 500px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 40px;

  @media (max-width: 675px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IncomingGoodsContent = () => {
  const userHasAccessToCreateUnloadingReport = useHasAccess('warehouse.create');

  const [t] = useGlobalTranslation();

  const [highlightedNotification, setHighlightedNotification] = useState<GetNotificationsResponse | null>(null);
  const [clickedNotification, setClickedNotification] = useState<GetNotificationsResponse | null>(null);
  const [incomingGoodsFormOpen, setIncomingGoodsFormOpen] = useState(false);

  const { triggerState: notificationTableTriggerRefetchState, trigger: triggerNotificationTableState } = useTrigger();

  const openIncomingGoodsForm = useCallback(() => setIncomingGoodsFormOpen(true), []);
  const closeIncomingGoodsForm = useCallback(() => setIncomingGoodsFormOpen(false), []);

  const pdfVisible = useMemo(() => highlightedNotification !== null, [highlightedNotification]);
  const pdfURL = useMemo(() => highlightedNotification?.orderPdf || '', [highlightedNotification?.orderPdf]);

  const pdf = useMemo(() => (pdfVisible ? <iframe title="PDF Viewer" src={`data:application/pdf;base64,${pdfURL}`} width="100%" height="100%" /> : ''), [pdfURL, pdfVisible]);

  const order = useMemo(() => clickedNotification?.orderData, [clickedNotification?.orderData]);
  const orderNumber = useMemo(() => clickedNotification?.orderData.orderNumber, [clickedNotification?.orderData.orderNumber]);

  const actionsVisible = useMemo(() => clickedNotification !== null, [clickedNotification]);
  const currentOrderMessage = useMemo(() => `${t('message.order')} ${orderNumber}`, [orderNumber, t]);

  const createUnloadingReportButtonRendered = useMemo(() => {
    if (userHasAccessToCreateUnloadingReport) {
      return <PrimaryButtonLarge onClick={openIncomingGoodsForm}>{t('message.createUnloadingReport')}</PrimaryButtonLarge>;
    }

    return '';
  }, [openIncomingGoodsForm, t, userHasAccessToCreateUnloadingReport]);

  const actions = useMemo(() => {
    return actionsVisible ? (
      <ActionsContainer>
        <Message>{currentOrderMessage}</Message>
        {createUnloadingReportButtonRendered}
      </ActionsContainer>
    ) : (
      ''
    );
  }, [actionsVisible, createUnloadingReportButtonRendered, currentOrderMessage]);

  const incomingGoodsFormRequestCallbacks = useMemo(
    () => ({
      success: triggerNotificationTableState
    }),
    [triggerNotificationTableState]
  );

  const incomingGoodsForm = useMemo(
    () => (incomingGoodsFormOpen ? <IncomingGoodsForm callbacks={incomingGoodsFormRequestCallbacks} order={order as Order} /> : ''),
    [incomingGoodsFormOpen, incomingGoodsFormRequestCallbacks, order]
  );

  const handleGoodsAcceptanceHover = useCallback((e: MouseEvent, item: GetNotificationsResponse) => {
    setHighlightedNotification(item);
  }, []);

  const handleGoodsAcceptanceClick = useCallback(
    (_e: MouseEvent, item: GetNotificationsResponse) => {
      setClickedNotification(item);
      closeIncomingGoodsForm();
    },
    [closeIncomingGoodsForm]
  );

  return (
    <div>
      <PageTitle imageSrc="/images/features/package.png" titleText={t('page.incomingGoods.header')} />
      <Container>
        <TableContainer>
          <GoodsNotificationTable
            refetchTrigger={notificationTableTriggerRefetchState}
            height={210}
            handleNotificationHover={handleGoodsAcceptanceHover}
            handleNotificationClick={handleGoodsAcceptanceClick}
          />
          {actions}
          {incomingGoodsForm}
        </TableContainer>
        <PDFContainer>{pdf}</PDFContainer>
      </Container>
    </div>
  );
};

export default IncomingGoodsContent;
