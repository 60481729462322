import { styled } from 'styled-components';

import { withHasAccessToFeature } from 'features/hocComponents';
import { useGetAllWarehouses } from 'features/warehouses/hooks';

import WarehouseBox from './WarehouseBox';

const WarehouseBoxesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
`;

interface Props {
  refetchTrigger?: boolean;
  onSelect: (_warehouseId: number) => void;
}

const WarehousesList: React.FC<Props> = ({ refetchTrigger, onSelect }) => {
  const { warehouses } = useGetAllWarehouses(refetchTrigger);

  return (
    <div>
      <WarehouseBoxesContainer>
        {warehouses.map((warehouse) => (
          <WarehouseBox key={`management-warehouse-warehouses-list-warehouse-box${warehouse.id}`} warehouse={warehouse} onSelect={onSelect} />
        ))}
      </WarehouseBoxesContainer>
    </div>
  );
};

export default withHasAccessToFeature(WarehousesList, 'warehouses.getList');
