import { useCallback, useState } from 'react';

import { Status } from 'types';

const useStatus = (defaultStatus: Status = 'not_initiated') => {
  const [status, setStatus] = useState<Status>(defaultStatus);

  const changeToNotInitiated = useCallback(() => {
    setStatus('not_initiated');
  }, []);

  const changeToPending = useCallback(() => {
    setStatus('pending');
  }, []);

  const changeToSucceeded = useCallback(() => {
    setStatus('succeeded');
  }, []);

  const changeToFailed = useCallback(() => {
    setStatus('failed');
  }, []);

  return {
    status,
    changeToNotInitiated,
    changeToPending,
    changeToSucceeded,
    changeToFailed
  };
};

export default useStatus;
