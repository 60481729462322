import { styled } from 'styled-components';

import { useHasAccess } from 'auth';
import { RecentGoodsNotificationTable } from 'features/goodsNotification/components';
import { RecentIncomingGoodsTable } from 'features/incomingGoods/components';
import { OverdueOrdersTable } from 'features/overdueOrders/components';
import WarehouseStatusTable from 'features/warehouse/components/WarehouseStatusTable';
import { useMemo } from 'react';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const TableContainer = styled.div`
  width: calc(50% - 16px);

  @media (max-width: 1050px) {
    width: 100%;
  }
`;

const Content = () => {
  const userHasAccessToRecentNotificationTable = useHasAccess('notification.getRecent');
  const userHasAccessToIncomingGoodsTable = useHasAccess('warehouse.getRecent');

  const recentGoodsNotificationTableRendered = useMemo(() => {
    if (userHasAccessToRecentNotificationTable) {
      return (
        <TableContainer>
          <RecentGoodsNotificationTable height={210} />
        </TableContainer>
      );
    }
    return '';
  }, [userHasAccessToRecentNotificationTable]);

  const recentIncomingGoodsTableRendered = useMemo(() => {
    if (userHasAccessToIncomingGoodsTable) {
      return (
        <TableContainer>
          <RecentIncomingGoodsTable height={210} />
        </TableContainer>
      );
    }
    return '';
  }, [userHasAccessToIncomingGoodsTable]);

  return (
    <Container>
      {recentGoodsNotificationTableRendered}
      {recentIncomingGoodsTableRendered}
      <TableContainer>
        <OverdueOrdersTable height={210} />
      </TableContainer>
      <TableContainer>
        <WarehouseStatusTable height={210} />
      </TableContainer>
    </Container>
  );
};

export default Content;
