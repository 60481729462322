import Login from 'pages/Login';
import { Navigate, Route, Routes } from 'react-router-dom';

import { paths } from 'appConstants';
import { SideNav } from 'components/Navigation';
import PageSpinner from 'components/PageSpinner';
import useCheckServerHealth from 'hooks/server/useCheckServerHealth';
import GoodsNotification from 'pages/GoodsNotification';
import Home from 'pages/Home';
import IncomingGoods from 'pages/IncomingGoods';
import InventoryManagement from 'pages/InventoryManagement';
import Warehouses from 'pages/Management/Warehouses';
import NewPassword from 'pages/NewPassword';
import OutgoingGoods from 'pages/OutgoingGoods';
import ResetPassword from 'pages/ResetPassword';
import Settings from 'pages/Settings';

const App = () => {
  useCheckServerHealth();

  return (
    <>
      <PageSpinner />
      <SideNav />
      <Routes>
        <Route path={paths.goodsNotification} element={<GoodsNotification />} />
        <Route path={paths.home} element={<Home />} />
        <Route path={paths.incomingGoods} element={<IncomingGoods />} />
        <Route path={paths.inventoryManagement} element={<InventoryManagement />} />
        <Route path={paths.login} element={<Login />} />
        <Route path={paths.newPassword} element={<NewPassword />} />
        <Route path={paths.outgoingGoods} element={<OutgoingGoods />} />
        <Route path={paths.resetPassword} element={<ResetPassword />} />
        <Route path={paths.settings} element={<Settings />} />
        <Route path={paths.settingsEditClientSection} element={<Settings />} />
        <Route path={paths.management.warehouses} element={<Warehouses />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default App;
