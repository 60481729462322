import { styled } from 'styled-components';

import { Color } from 'appConstants';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const Container = styled.div`
  max-width: 100%;
`;

const TableOuterContainer = styled.div`
  border: 3px solid ${Color.accentPrimary};
  padding: 8px 0;
  position: relative;
`;

const TableInnerContainer = styled.div<{ height: number }>`
  ${({ height }) => `height: ${height}px`};
  overflow: auto;
  padding: 8px 16px;
`;

const Title = styled.h4`
  background: ${Color.accentPrimary};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${Color.white};
  min-width: 200px;
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 8px 16px;

  @media (max-width: 318px) {
    width: fit-content;
  }
`;

const Section = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

const WarehouseName = styled.p`
  font-weight: 500;
`;

const WarehouseStatusOuter = styled.div`
  margin-top: 4px;
  background: green;
  position: relative;
  height: 30px;
  width: 200px;
`;
const WarehouseStatusInner = styled.div<{ width: number }>`
  ${({ width }) => `
  background: red;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0px;

  animation: a${width}scale 1s linear forwards;

  @keyframes a${width}scale {
    0% {
      width: 0%;
    }
    100% {
      width: ${width}%;
    }
  }
  `}
`;

interface Props {
  height: number;
}

const WarehouseStatusTable: React.FC<Props> = ({ height }) => {
  const [t] = useGlobalTranslation();

  return (
    <Container>
      <Title>{t('feature.warehouse.status.table.title')}</Title>
      <TableOuterContainer>
        <TableInnerContainer height={height}>
          <Section>
            <WarehouseName>Lager Düsseldorf , Fontaneweg 22, Garage</WarehouseName>
            <WarehouseStatusOuter>
              <WarehouseStatusInner width={40} />
            </WarehouseStatusOuter>
          </Section>
          <Section>
            <WarehouseName>Lager Hamburg, Ellernchaussee 231, Schuppen 2</WarehouseName>
            <WarehouseStatusOuter>
              <WarehouseStatusInner width={60} />
            </WarehouseStatusOuter>
          </Section>
          <Section>
            <WarehouseName>Lager Langenhagen, Walsroder Straße 93, Tiefgarage Platz 53</WarehouseName>
            <WarehouseStatusOuter>
              <WarehouseStatusInner width={20} />
            </WarehouseStatusOuter>
          </Section>
        </TableInnerContainer>
      </TableOuterContainer>
    </Container>
  );
};

export default WarehouseStatusTable;
