import { pagesMinAccesses } from 'appConstants';
import { withAuthentication } from 'auth';
import Base from 'components/Layout/Base';
import Content from 'components/Pages/OutgoingGoods/Content';

const OutgoingGoods = () => {
  return (
    <Base>
      <Content />
    </Base>
  );
};

export default withAuthentication(OutgoingGoods, pagesMinAccesses.outgoingGoods);
