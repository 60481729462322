import { createSlice } from '@reduxjs/toolkit';

interface State {
  serverIsOn: boolean;
}

const initialState: State = {
  serverIsOn: false
};

export interface UpdateServerHealthCheckAction {
  payload: boolean;
}

const serverHealthSlice = createSlice({
  name: 'serverHealth',
  initialState,
  reducers: {
    updateServerHealthCheckAction: (state, action: UpdateServerHealthCheckAction) => {
      state.serverIsOn = action.payload;
    }
  }
});

export const { updateServerHealthCheckAction } = serverHealthSlice.actions;

export default serverHealthSlice.reducer;
