import { Upload } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { SecondaryButtonMedium } from 'components/UI/SecondaryButton';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { AppFile } from 'types';

import ImageUploadDialog from './components/ImageUploadDialog';

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 767px) {
    min-heigh: none;
    margin-bottom: 16px;
  }

  &:hover {
    border-color: ${Color.typography};
  }
`;

const Label = styled.label`
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const Image = styled.img`
  height: 45px;
`;

interface Props {
  setWarehouseImage: (_: AppFile) => void;
  warehouseImage: AppFile | null;
}

const UploadImageButton: React.FC<Props> = ({ setWarehouseImage, warehouseImage }) => {
  const [t] = useGlobalTranslation();

  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);

  const openUploadDialog = useCallback(() => {
    setUploadDialogIsOpen(true);
  }, []);

  const closeUploadDialog = useCallback(() => {
    setUploadDialogIsOpen(false);
  }, []);

  return (
    <>
      <ImageUploadDialog close={closeUploadDialog} isOpen={uploadDialogIsOpen} setWarehouseImage={setWarehouseImage} warehouseImage={warehouseImage} />
      <Container onClick={openUploadDialog}>
        <Label>
          <InputContainer>
            <SecondaryButtonMedium>
              <Upload />
              {t('feature.warehouse.label.warehouseImage')}
            </SecondaryButtonMedium>
            <Image src="/images/icons/camera.png" />
          </InputContainer>
        </Label>
      </Container>
    </>
  );
};

export default UploadImageButton;
