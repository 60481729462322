import styled from 'styled-components';

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5;
  text-align: center;
`;

export default SectionTitle;
