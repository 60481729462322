import { createSlice } from '@reduxjs/toolkit';

import type { Language } from 'types';
import { getInitialLanguage } from 'utils/language/persist';

interface State {
  selectedLanguage: Language;
}

const initialState: State = {
  selectedLanguage: getInitialLanguage()
};

export interface ChangeLanguageAction {
  payload: Language;
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguageAction: (state, action: ChangeLanguageAction) => {
      state.selectedLanguage = action.payload;
    }
  }
});

export const { changeLanguageAction } = languageSlice.actions;

export default languageSlice.reducer;
