import styled from 'styled-components';

import { SectionTitleWithCloseButton } from 'components/UI/Title';
import { WarehouseDetails as WarehouseDetailsFeature } from 'features/warehouses/components';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';

const WarehouseDetailsContainer = styled.div`
  margin: 32px 0 16px;
`;

interface Props {
  close: () => void;
  warehouseId: number;
  onEditClick: () => void;
  onConfigureLayoutButtonClick: () => void;
}

const WarehouseDetails: React.FC<Props> = ({ close, warehouseId, onEditClick, onConfigureLayoutButtonClick }) => {
  const [t] = useGlobalTranslation();

  return (
    <div>
      <SectionTitleWithCloseButton title={t('feature.warehouse.warehouseLocationDetails')} close={close} />
      <WarehouseDetailsContainer>
        <WarehouseDetailsFeature warehouseId={warehouseId} onEditClick={onEditClick} onConfigureLayoutButtonClick={onConfigureLayoutButtonClick} />
      </WarehouseDetailsContainer>
    </div>
  );
};

export default WarehouseDetails;
