import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateIsSideNavIsOpenAction } from 'redux/features/page/pageSideNavSlice';
import { RootState } from 'redux/types';

import { allowScrolling, preventScrolling } from 'utils/page/scroll';

const sideNavIsOpenSelector = (state: RootState) => state.pageSideNav.sideNavIsOpen;

const useManageSideNan = () => {
  const sideNavIsOpen = useSelector(sideNavIsOpenSelector);
  const dispatch = useDispatch();

  const openSideNav = useCallback(() => {
    dispatch(UpdateIsSideNavIsOpenAction(true));
    preventScrolling();
  }, [dispatch]);

  const closeSideNav = useCallback(() => {
    dispatch(UpdateIsSideNavIsOpenAction(false));
    allowScrolling();
  }, [dispatch]);

  const addDocumentClickEventListener = useCallback(() => {
    window.addEventListener('click', closeSideNav);
  }, [closeSideNav]);

  const removeDocumentClickEventListener = useCallback(() => {
    window.removeEventListener('click', closeSideNav);
  }, [closeSideNav]);

  useEffect(() => {
    addDocumentClickEventListener();

    return () => {
      removeDocumentClickEventListener();
    };
  }, [addDocumentClickEventListener, removeDocumentClickEventListener]);

  return { sideNavIsOpen, openSideNav };
};

export default useManageSideNan;
