import { useCallback, useState } from 'lib/react';
import { AppFile } from 'types';
import { useManageFormFieldsErrors, useRenderFormFields } from './privateHooks';
import { FormFieldsMeta } from './types';
import { areFileIdsEqual, extractFormFieldsWithValues, generateEmptyState, generateInitialState } from './util';

interface Params {
  formFieldsMeta: FormFieldsMeta;
  formFieldLabelResolver: (_label: string) => string;
  handleFormFieldChange?: (_name: string, _value: string) => void;
}

const useManageFormFields = ({ formFieldsMeta, formFieldLabelResolver, handleFormFieldChange }: Params) => {
  const [formFieldsState, setFormFieldsState] = useState(generateInitialState(formFieldsMeta));
  const { removeErrorFromFormField, updateFormFieldsErrorsState, getFormFieldError } = useManageFormFieldsErrors();

  const emptyFormFieldsState = useCallback(() => {
    setFormFieldsState(generateEmptyState(formFieldsMeta));
  }, [formFieldsMeta]);

  const getFormFieldState = useCallback((formFieldName: string) => formFieldsState[formFieldName], [formFieldsState]);

  const getFormFieldValue = useCallback((formFieldName: string) => getFormFieldState(formFieldName).value, [getFormFieldState]);

  const getFormFieldsWithValues = useCallback(() => {
    return extractFormFieldsWithValues(formFieldsState);
  }, [formFieldsState]);

  const changeFormFieldState = useCallback((formFieldName: string, newValue: any) => {
    setFormFieldsState((pre) => {
      return {
        ...pre,
        [formFieldName]: { value: newValue }
      };
    });
  }, []);

  const deleteFile = useCallback(
    (formFieldName: string, file: AppFile) => {
      const newValue = getFormFieldValue(formFieldName).filter((stateFile: AppFile) => areFileIdsEqual(stateFile, file));
      changeFormFieldState(formFieldName, newValue);
    },
    [changeFormFieldState, getFormFieldValue]
  );

  const formFieldsRendered = useRenderFormFields({
    changeFormFieldState,
    deleteFile,
    formFieldLabelResolver,
    formFieldsMeta,
    getFormFieldError,
    getFormFieldValue,
    handleFormFieldChange,
    removeErrorFromFormField
  });

  return { formFieldsRendered, emptyAllFormFields: emptyFormFieldsState, getFormFieldsWithValues, updateFormFieldsErrorsState };
};

export default useManageFormFields;
