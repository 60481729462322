import { matchIsValidColor } from 'mui-color-input';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';

import { Color } from 'appConstants';
import { AxiosResponse } from 'axios';
import { PrimaryButtonSmall } from 'components/UI/PrimaryButton';
import { useAddBackgroundColorToImage } from 'features/warehouses/hooks';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { AppFile, FileData } from 'types';
import { stopEventPropagation } from 'utils/event';
import { appendFileToFormData, transformPngDataUriToAppFile } from 'utils/file';
import convertImageElementToPngAppFile from 'utils/file/convertImageElementToPngAppFile';

import { DefaultDialog } from 'components/Dialog';
import ColorInput from './ColorInput';
import UploadButton from './UploadButton';

const IDS_AND_KEYS_BASE_NAME = 'management-warehouse-upload-image';

const OuterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media (max-width: 580px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Spacer = styled.div`
  width: 3px;
  background: ${Color.inputBorder};
  align-self: stretch;

  @media (max-width: 580px) {
    order: 2;
    width: 100%;
    height: 3px;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  @media (max-width: 767px) {
    justify-content: center;
  }

  @media (max-width: 580px) {
    order: 3;
  }
`;

const Image = styled.img`
  width: 198px;
  padding: 5px;
  border-radius: 10px;
  transition: all 0.3s;
  cursor: pointer;

  @media (max-width: 840px) {
    width: 170px;
  }

  &.active {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const Display = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (max-width: 580px) {
    order: 1;
  }
`;

const DisplayImage = styled.img<{ $backgroundColor: string }>`
  width: 250px;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 10px;
`;

const DisplayEmpty = styled.div`
  width: 250px;
  height: 250px;
  border: 4px dashed ${Color.inputBorder};
  font-weight: 500;
  font-size: 1.25rem;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 16px;
  color: ${Color.inputBorder};
`;

const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  @media (max-width: 840px) {
    width: 170px;
  }
`;

const HiddenPlaceholderImage = styled.img`
  width: 1080;
  display: none;
`;

const DEFAULT_IMAGE_PATH = '/images/warehouseIcons/';
const defaultImagesNames = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const defaultImagesSources = defaultImagesNames.map((name) => `${DEFAULT_IMAGE_PATH + name}.png`);

type SelectedImageType = 'customColor' | 'defaultImage' | 'uploadedImage';

const SELECTED_IMAGE_SRC_INITIAL_STATE = '';
const SELECTED_IMAGE_TYPE_INITIAL_STATE = null;
const UPLOADED_IMAGE_FILE_INITIAL_STATE = null;
const SELECTED_COLOR_INITIAL_STATE = 'rgb(255, 255, 255)';
const SELECTED_COLOR_IS_VALID_INITIAL_STATE = true;

const removeActiveClassFromElement = (el: Element) => {
  el.classList.remove('active');
};

interface Props {
  isOpen: boolean;
  setWarehouseImage: (_: AppFile) => void;
  warehouseImage: AppFile | null;
  close: () => void;
}

const ImageUploadDialog: React.FC<Props> = ({ isOpen, close, setWarehouseImage, warehouseImage }) => {
  const [t] = useGlobalTranslation();

  const imagesContainerRef = useRef<HTMLDivElement | null>(null);
  const hiddenImagePlaceholderRef = useRef<HTMLImageElement | null>(null);
  const uploadedImage = useRef<HTMLImageElement>(null);

  const addBackground = useAddBackgroundColorToImage();

  const [selectedImageSrc, setSelectedImageSrc] = useState(SELECTED_IMAGE_SRC_INITIAL_STATE);
  const [selectedImageType, setSelectedImageType] = useState<SelectedImageType | null>(SELECTED_IMAGE_TYPE_INITIAL_STATE);
  const [uploadedImageFile, setUploadedImageFile] = useState<AppFile | null>(UPLOADED_IMAGE_FILE_INITIAL_STATE);

  const changeSelectedImageTypeToCustomColor = useCallback(() => {
    setSelectedImageType('customColor');
  }, []);

  const changeSelectedImageTypeToDefaultImage = useCallback(() => {
    setSelectedImageType('defaultImage');
  }, []);

  const changeSelectedImageTypeToUploadedImage = useCallback(() => {
    setSelectedImageType('uploadedImage');
  }, []);

  const [selectedColor, setSelectedColor] = useState(SELECTED_COLOR_INITIAL_STATE);
  const [selectedColorIsValid, setSelectedColorIsValid] = useState(SELECTED_COLOR_IS_VALID_INITIAL_STATE);

  const handleColoInputChange = useCallback((value: string) => {
    setSelectedColor(value);
    setSelectedColorIsValid(matchIsValidColor(value));
  }, []);

  const cleanup = useCallback(() => {
    setTimeout(() => {
      setSelectedImageSrc(warehouseImage ? selectedImageSrc : SELECTED_IMAGE_SRC_INITIAL_STATE);
      setSelectedImageType(warehouseImage ? selectedImageType : SELECTED_IMAGE_TYPE_INITIAL_STATE);
    }, 300);
  }, [selectedImageSrc, selectedImageType, warehouseImage]);

  const closeAndCleanUp = useCallback(() => {
    close();
    cleanup();
  }, [cleanup, close]);

  const createImageFile = useCallback(() => {
    const image = hiddenImagePlaceholderRef.current;

    const srcParts = selectedImageSrc.split('/');
    const imageNameIndex = srcParts.length - 1;
    const selectedImageName = srcParts[imageNameIndex].split('.')[0];

    if (!image) throw new Error('Selected Image not found');

    return convertImageElementToPngAppFile(image, selectedImageName);
  }, [selectedImageSrc]);

  const handleDefaultImageSaved = useCallback(() => {
    setWarehouseImage(createImageFile());
    closeAndCleanUp();
  }, [closeAndCleanUp, createImageFile, setWarehouseImage]);

  const handleUploadedImageSaved = useCallback(() => {
    if (uploadedImageFile === null) {
      throw new Error('uploadedImageFile can not be null');
    }
    setWarehouseImage(uploadedImageFile);
    closeAndCleanUp();
  }, [closeAndCleanUp, setWarehouseImage, uploadedImageFile]);

  const handleCustomImageColorSaved = useCallback(() => {
    if (!selectedColorIsValid) return;

    const imageFile = createImageFile();

    const callbacks = {
      success: (res: AxiosResponse<FileData>) => {
        const imageWithBackground = transformPngDataUriToAppFile(res.data.file);

        setWarehouseImage(imageWithBackground);
        closeAndCleanUp();
      }
    };

    const requestData = new FormData();

    requestData.append('backgroundColor', selectedColor);
    appendFileToFormData(imageFile, requestData, 'image');

    addBackground(requestData, callbacks);
  }, [addBackground, closeAndCleanUp, createImageFile, selectedColor, selectedColorIsValid, setWarehouseImage]);

  const handleSaveButtonClick = useCallback(() => {
    if (selectedImageType === 'uploadedImage') {
      handleUploadedImageSaved();
    } else if (selectedImageType === 'customColor') {
      handleCustomImageColorSaved();
    } else if (selectedImageType === 'defaultImage') {
      handleDefaultImageSaved();
    }
  }, [handleCustomImageColorSaved, handleDefaultImageSaved, handleUploadedImageSaved, selectedImageType]);

  const colorInputRendered = useMemo(() => {
    if (selectedImageType === 'customColor') {
      return <ColorInput onChange={handleColoInputChange} selectedColor={selectedColor} />;
    }
    return '';
  }, [handleColoInputChange, selectedColor, selectedImageType]);

  const displayImageBackgroundColor = useMemo(() => {
    if (selectedImageType === 'customColor') return selectedColor;
    return 'transparent';
  }, [selectedColor, selectedImageType]);

  const displayContentRendered = useMemo(() => {
    if (selectedImageSrc) {
      return (
        <>
          <DisplayImage src={selectedImageSrc} alt="" $backgroundColor={displayImageBackgroundColor} />
          {colorInputRendered}
          <PrimaryButtonSmall onClick={handleSaveButtonClick}>{t('message.save')}</PrimaryButtonSmall>
        </>
      );
    }
    return <DisplayEmpty>{t('feature.warehouse.message.selectOrUploadImage')}</DisplayEmpty>;
  }, [colorInputRendered, displayImageBackgroundColor, handleSaveButtonClick, selectedImageSrc, t]);

  const addActiveClassToImage = useCallback((image: HTMLImageElement) => {
    const parentNode = image.parentNode;
    const siblingElements = Array.from(parentNode?.children || []).filter((child) => child instanceof HTMLElement && child !== image);

    siblingElements.forEach(removeActiveClassFromElement);
    image.classList.add('active');

    setSelectedImageSrc(image.getAttribute('src') as string);
  }, []);

  const handleImageClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      stopEventPropagation(e);
      addActiveClassToImage(e.currentTarget);
    },
    [addActiveClassToImage]
  );

  const handleCustomColorImageClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      handleImageClick(e);
      changeSelectedImageTypeToCustomColor();
    },
    [changeSelectedImageTypeToCustomColor, handleImageClick]
  );

  const handleDefaultImageClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      handleImageClick(e);
      changeSelectedImageTypeToDefaultImage();
    },
    [changeSelectedImageTypeToDefaultImage, handleImageClick]
  );

  const handleUploadedImageClick = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      handleImageClick(e);
      changeSelectedImageTypeToUploadedImage();
    },
    [changeSelectedImageTypeToUploadedImage, handleImageClick]
  );

  const uploadedImageFileRendered = useMemo(() => {
    if (uploadedImageFile) {
      return <Image src={uploadedImageFile.dataUri} onClick={handleUploadedImageClick} ref={uploadedImage} />;
    }
    return '';
  }, [handleUploadedImageClick, uploadedImageFile]);

  const autoSelectedCurrentlyUploadedImage = useCallback(() => {
    if (uploadedImageFile) {
      setSelectedImageSrc(uploadedImageFile.dataUri);
      changeSelectedImageTypeToUploadedImage();
      const customColorImage = uploadedImage.current;
      if (!customColorImage) {
        return;
      }
      addActiveClassToImage(customColorImage);
    }
  }, [addActiveClassToImage, changeSelectedImageTypeToUploadedImage, uploadedImageFile]);

  useEffect(() => {
    autoSelectedCurrentlyUploadedImage();
  }, [autoSelectedCurrentlyUploadedImage, changeSelectedImageTypeToUploadedImage, uploadedImageFile]);

  return (
    <DefaultDialog title={t('feature.warehouse.label.warehouseImage')} maxWidth="lg" isOpen={isOpen} close={closeAndCleanUp}>
      <OuterContainer>
        <ImagesContainer ref={imagesContainerRef}>
          {defaultImagesSources.map((src) => {
            const key = `${IDS_AND_KEYS_BASE_NAME}-images-container-image-${src}`;
            return <Image src={src} key={key} onClick={handleDefaultImageClick} />;
          })}
          <Image src={`${DEFAULT_IMAGE_PATH}11.png`} onClick={handleCustomColorImageClick} />
          {uploadedImageFileRendered}
          <UploadButtonContainer>
            <UploadButton setState={setUploadedImageFile} />
          </UploadButtonContainer>
          <HiddenPlaceholderImage src={selectedImageSrc} alt="hidden image" ref={hiddenImagePlaceholderRef} />
        </ImagesContainer>
        <Spacer />
        <Display>{displayContentRendered}</Display>
      </OuterContainer>
    </DefaultDialog>
  );
};

export default ImageUploadDialog;
