/* eslint-disable indent */
import { DateField, DateTimeField, EmailField, FileFieldWithIcon, MultiSelectField, NumberField, PasswordField, SelectField, SwitchField, TextField, TextareaField } from 'components/Form';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { AppFile } from 'types';
import { FormFieldsMeta } from '../types';

interface Params {
  formFieldsMeta: FormFieldsMeta;
  formFieldLabelResolver: (_label: string) => string;
  getFormFieldValue: (_formFieldName: string) => any;
  getFormFieldError: (_formFieldName: string) => string;
  changeFormFieldState: (_formFieldName: string, _value: any) => void;
  deleteFile: (_formFieldName: string, _file: AppFile) => void;
  handleFormFieldChange?: (_formFieldName: string, _value: any) => void;
  removeErrorFromFormField?: (_formFieldName: string) => void;
}

const useRenderFormFields = ({
  formFieldsMeta,
  getFormFieldValue,
  getFormFieldError,
  changeFormFieldState,
  handleFormFieldChange,
  removeErrorFromFormField,
  deleteFile,
  formFieldLabelResolver
}: Params) => {
  const [t] = useGlobalTranslation();

  return formFieldsMeta.map((fMeta) => {
    const { label, name, type, disabled, onChange } = fMeta;

    const value = getFormFieldValue(name);

    const error = getFormFieldError(name);

    const handleChange = (newValue: any) => {
      changeFormFieldState(name, newValue);
      handleFormFieldChange?.(name, newValue);
      removeErrorFromFormField?.(name);
      onChange?.(newValue);
    };

    const commonProps = {
      label: t(formFieldLabelResolver(label)),
      disabled: disabled?.(),
      value,
      onChange: handleChange,
      key: name
    };

    switch (type) {
      case 'date':
        return <DateField {...commonProps} error={error} />;
      case 'number':
        return <NumberField {...commonProps} error={error} placeholder={fMeta.placeholder} />;
      case 'dateTime':
        return <DateTimeField {...commonProps} error={error} />;
      case 'email':
        return <EmailField {...commonProps} error={error} placeholder={fMeta.placeholder} />;
      case 'file':
        return <FileFieldWithIcon deleteFile={(file) => deleteFile(name, file)} iconSrc={fMeta.iconSrc} {...commonProps} multiple={fMeta.multiple} accept={fMeta.accept} />;
      case 'textarea':
        return <TextareaField {...commonProps} error={error} rows={fMeta.rows} placeholder={fMeta.placeholder} />;
      case 'multiSelect':
        return <MultiSelectField items={fMeta.items} {...commonProps} error={error} labelFixed={fMeta.labelFixed} placeholder={fMeta.placeholder} itemLabelResolver={fMeta.itemLabelResolver} />;
      case 'password':
        return <PasswordField {...commonProps} error={error} placeholder={fMeta.placeholder} autoGenerate={fMeta.autoGenerate} />;
      case 'select':
        return <SelectField items={fMeta.items} {...commonProps} error={error} labelFixed={fMeta.labelFixed} placeholder={fMeta.placeholder} itemLabelResolver={fMeta.itemLabelResolver} />;
      case 'switch':
        return <SwitchField {...commonProps} />;
      case 'text':
        return <TextField {...commonProps} error={error} placeholder={fMeta.placeholder} />;
      default:
        return '';
    }
  });
};

export default useRenderFormFields;
