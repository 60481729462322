import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState } from 'react';

import { Autorenew, Visibility, VisibilityOff } from '@mui/icons-material';
import { passwordGenerator } from 'lib/generatePassword';
import { preventEventDefault } from 'utils/event';

import { FileFormFieldState, FormFieldsState, GetFormFieldValueFun, PasswordFormField } from '../types';

const generatePassword = () => {
  return passwordGenerator.generate({
    length: 8,
    numbers: true,
    lowercase: true,
    uppercase: true,
    symbols: true,
    strict: true
  });
};

interface Props {
  field: PasswordFormField;
  PasswordField: React.FC<TextFieldProps>;
  label: string;
  getFormFieldValue: GetFormFieldValueFun;
  changeFormFieldState: (_fieldName: string, _fieldValue: string) => void;
  formFieldsState: FormFieldsState;
  fileFormFieldState: FileFormFieldState;
  handleFormFieldChange?: (_value: any, _formFieldName: string) => void;
  error: string;
}

const FormPasswordField: React.FC<Props> = ({ field, PasswordField, label, getFormFieldValue, changeFormFieldState, formFieldsState, fileFormFieldState, handleFormFieldChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((pre) => !pre);
  }, []);

  const setShowPasswordToTrue = useCallback(() => {
    setShowPassword(true);
  }, []);

  const type = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword]);

  const handleTextFormFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, textFieldName: string, propsOnChange?: ChangeEventHandler<HTMLInputElement>) => {
      const { value } = e.target;
      changeFormFieldState(textFieldName, value);

      propsOnChange?.(e);
      handleFormFieldChange?.(value, textFieldName);
    },
    [changeFormFieldState, handleFormFieldChange]
  );

  const handleAutoGenerateButtonClick = useCallback(() => {
    const password = generatePassword();
    changeFormFieldState(field.name, password);
    setShowPasswordToTrue();
  }, [changeFormFieldState, field.name, setShowPasswordToTrue]);

  return (
    <PasswordField
      disabled={field.isDisabled?.(formFieldsState, fileFormFieldState)}
      fullWidth
      type={type}
      variant="outlined"
      label={label}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleTextFormFieldChange(e, field.name, field.onChange)}
      value={getFormFieldValue(field.name)}
      error={!!error}
      helperText={error || ''}
      placeholder={field.placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword} onMouseDown={preventEventDefault} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            {field.autoGenerate ? (
              <IconButton onClick={handleAutoGenerateButtonClick} onMouseDown={preventEventDefault} edge="end">
                <Autorenew />
              </IconButton>
            ) : (
              ''
            )}
          </InputAdornment>
        )
      }}
    />
  );
};

export default FormPasswordField;
