/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useMemo, useState } from 'react';

import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { AppFile } from 'types';
import { castToString } from 'utils/strings';

import FormDateField from './components/FormDateField';
import FormDateTimeField from './components/FormDateTimeField';
import FormEmailField from './components/FormEmailField';
import FormMultiSelect from './components/FormMultiSelect';
import FormNumberField from './components/FormNumberField';
import FormPasswordField from './components/FormPasswordField';
import FormSelectField from './components/FormSelectField';
import FormSwitchField from './components/FormSwitchField';
import FormTextField from './components/FormTextField';
import FormTextarea from './components/FormTextarea';
import FormUploadFileField from './components/FormUploadFileField';
import { FileFormFieldState, FormField, FormFieldsState, Params } from './types';

const SWITCH_INPUT_INITIAL_VALUE = false;

const useManageFormFields = ({
  formFields,
  formFieldLabelResolver,
  TextFormField,
  PasswordFormField,
  SelectFormField,
  SelectFormControl,
  selectItemLabelResolver,
  UploadFileComponent,
  DateTimeField,
  SubmitButton,
  DateField,
  Textarea,
  SwitchFormControlLabel,
  handleFormFieldChange,
  formFieldsErrorsState,
  removeErrorFromFormField
}: Params) => {
  const [t] = useGlobalTranslation();

  const getSwitchInitialValue = useCallback((initialValue?: boolean) => {
    if (initialValue === undefined) return SWITCH_INPUT_INITIAL_VALUE;
    return initialValue;
  }, []);

  const initialFormFieldsState = useMemo(() => {
    const state: FormFieldsState = {};

    formFields.forEach((formField) => {
      if (formField.type === 'submit') {
        return;
      }
      if (formField.type === 'switch') {
        state[formField.name] = { value: getSwitchInitialValue(formField.initialValue) };
        return;
      }

      state[formField.name] = { value: castToString(formField.initialValue) };
    });

    return state;
  }, [formFields, getSwitchInitialValue]);

  const initialFileFormFieldsState = useMemo(() => {
    const state: FileFormFieldState = {};
    formFields.forEach((formField) => {
      if (formField.type === 'file') {
        state[formField.name] = formField.initialValue || [];
      }
    });

    return state;
  }, [formFields]);

  const [formFieldsState, setFormFieldsState] = useState(initialFormFieldsState);

  const [filesFormFieldState, setFilesFormFieldState] = useState(initialFileFormFieldsState);

  const initializeAllFormFieldState = useCallback(() => {
    setFormFieldsState(initialFormFieldsState);
    setFilesFormFieldState(initialFileFormFieldsState);
  }, [initialFileFormFieldsState, initialFormFieldsState]);

  const getFormFieldValue = useCallback((formFieldName: string) => formFieldsState[formFieldName].value, [formFieldsState]);

  const getErrorText = useCallback(
    (error?: string) => {
      if (!error) return '';
      return t(`form.error.${error}`);
    },
    [t]
  );

  const getFormFieldError = useCallback(
    (formFieldName: string) => {
      const error = formFieldsErrorsState?.find((errorField) => errorField.name === formFieldName)?.error;

      return getErrorText(error);
    },
    [formFieldsErrorsState, getErrorText]
  );

  const changeFormFieldState = useCallback(
    (textFieldName: string, value: any) => {
      removeErrorFromFormField?.(textFieldName);
      setFormFieldsState((pre) => ({
        ...pre,
        [textFieldName]: {
          value
        }
      }));
    },
    [removeErrorFromFormField]
  );

  const emptyFormFields = useCallback(() => {
    const state: FormFieldsState = {};

    formFields.forEach((formField) => {
      if (formField.type === 'submit') {
        return;
      }

      state[formField.name] = { value: '' };
    });

    setFormFieldsState(state);
  }, [formFields]);

  const emptyFileFormField = useCallback(() => {
    const state: FileFormFieldState = {};
    formFields.forEach((formField) => {
      if (formField.type === 'file') {
        state[formField.name] = [];
      }
    });

    setFilesFormFieldState(state);
  }, [formFields]);

  const emptyAllFormFields = useCallback(() => {
    emptyFormFields();
    emptyFileFormField();
  }, [emptyFormFields, emptyFileFormField]);

  const getFormFieldsWithValues = useCallback(() => {
    const formFieldsWithValues: { [_x: string]: string } = {};

    const formFieldsEntries = Object.entries(formFieldsState);

    formFieldsEntries.forEach((field) => {
      const key = field[0];
      formFieldsWithValues[key] = field[1].value as string;
    });

    return formFieldsWithValues;
  }, [formFieldsState]);

  const getFileFormFieldsWithValues = useCallback(() => {
    const result: { [_x: string]: AppFile[] } = {};

    const formFieldsEntries = Object.entries(filesFormFieldState);

    formFieldsEntries.forEach((field) => {
      const [key, value] = field;
      result[key] = value;
    });

    return result;
  }, [filesFormFieldState]);

  const wrapInFieldContainer = useCallback((field: FormField, fieldRendered: JSX.Element, key: string): JSX.Element => {
    const Container = field.Container;
    if (Container) {
      return <Container key={key}>{fieldRendered}</Container>;
    }
    return fieldRendered;
  }, []);

  const throwErrorWhenDependenciesNotProvided = useCallback((fieldType: string, ...dependencies: any[]) => {
    const dependenciesProvided = dependencies.every((dependency) => dependency !== undefined);

    if (!dependenciesProvided) {
      const formattedDependencyNames = dependencies.map((dependency) => dependency.name).join(' and a');
      throw new Error(`For ${fieldType} input field a ${formattedDependencyNames} must be passed as argument`);
    }
  }, []);

  const formFieldsJSX = useMemo(() => {
    return formFields.map((field) => {
      const { required } = field;
      const labelSuffix = required ? ' *' : '';
      let label = '';
      if ('label' in field) {
        label = t(formFieldLabelResolver(field.label)) + labelSuffix;
      }

      const { type } = field;
      if (field.type === 'textarea') {
        throwErrorWhenDependenciesNotProvided(type, [Textarea]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormTextarea
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            Textarea={Textarea!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
            placeholder={field.placeholder}
          />,
          key
        );
      }
      if (field.type === 'text') {
        throwErrorWhenDependenciesNotProvided(type, [TextFormField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormTextField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            TextField={TextFormField!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'password') {
        throwErrorWhenDependenciesNotProvided(type, [PasswordFormField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormPasswordField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            PasswordField={PasswordFormField!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'switch') {
        throwErrorWhenDependenciesNotProvided(type, [SwitchFormControlLabel]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormSwitchField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            SwitchFormControlLabel={SwitchFormControlLabel!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
          />,
          key
        );
      }
      if (field.type === 'number') {
        throwErrorWhenDependenciesNotProvided(type, [TextFormField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormNumberField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            TextField={TextFormField!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'email') {
        throwErrorWhenDependenciesNotProvided(type, [TextFormField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormEmailField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            TextField={TextFormField!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'date') {
        throwErrorWhenDependenciesNotProvided(type, [DateField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormDateField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            DateField={DateField!}
            field={field}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            getFormFieldValue={getFormFieldValue}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'select') {
        const dependencies: any[] = [SelectFormField, SelectFormControl];
        if (!field.labelFixed) {
          dependencies.push(selectItemLabelResolver);
        }
        throwErrorWhenDependenciesNotProvided(type, dependencies);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormSelectField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            Select={SelectFormField!}
            SelectFormControl={SelectFormControl!}
            changeFormFieldState={changeFormFieldState}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            selectItemLabelResolver={selectItemLabelResolver}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'multiSelect') {
        const dependencies: any[] = [SelectFormControl];
        if (!field.labelFixed) {
          dependencies.push(selectItemLabelResolver);
        }
        throwErrorWhenDependenciesNotProvided(type, dependencies);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormMultiSelect
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            SelectFormControl={SelectFormControl!}
            changeFormFieldState={changeFormFieldState}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            selectItemLabelResolver={selectItemLabelResolver}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'file') {
        throwErrorWhenDependenciesNotProvided(type, [UploadFileComponent]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormUploadFileField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            UploadFileComponent={UploadFileComponent!}
            field={field}
            label={label}
            filesFormFieldState={filesFormFieldState}
            setFilesFormFieldState={setFilesFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'dateTime') {
        throwErrorWhenDependenciesNotProvided(type, [DateTimeField]);
        const key = field.name;

        return wrapInFieldContainer(
          field,
          <FormDateTimeField
            formFieldsState={formFieldsState}
            fileFormFieldState={filesFormFieldState}
            key={key}
            DateTimeField={DateTimeField!}
            field={field}
            getFormFieldValue={getFormFieldValue}
            label={label}
            changeFormFieldState={changeFormFieldState}
            handleFormFieldChange={handleFormFieldChange}
            error={getFormFieldError(field.name)}
          />,
          key
        );
      }
      if (field.type === 'submit') {
        throwErrorWhenDependenciesNotProvided(type, [SubmitButton]);
        const ExistentSubmitButton = SubmitButton!;
        return (
          <ExistentSubmitButton key="submit" type="submit">
            {field.fixedLabel}
          </ExistentSubmitButton>
        );
      }

      return '';
    });
  }, [
    DateField,
    DateTimeField,
    PasswordFormField,
    SelectFormControl,
    SelectFormField,
    SubmitButton,
    SwitchFormControlLabel,
    TextFormField,
    Textarea,
    UploadFileComponent,
    changeFormFieldState,
    filesFormFieldState,
    formFieldLabelResolver,
    formFields,
    formFieldsState,
    getFormFieldError,
    getFormFieldValue,
    handleFormFieldChange,
    selectItemLabelResolver,
    t,
    throwErrorWhenDependenciesNotProvided,
    wrapInFieldContainer
  ]);

  return {
    formFieldsJSX,
    getFormFieldsWithValues,
    getFileFormFieldsWithValues,
    changeFormFieldState,
    initializeAllFormFieldState,
    emptyAllFormFields
  };
};

export default useManageFormFields;
