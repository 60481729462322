import { TextField } from '@mui/material';
import { FormEventHandler, useCallback, useMemo } from 'react';
import { styled } from 'styled-components';

import { Color, paths } from 'appConstants';
import { useResetPassword } from 'auth';
import {
  Content as ContentUI,
  FooterContainer,
  FormInnerContainer,
  Header,
  IconsSection,
  Image,
  InnerBox,
  OuterBox,
  PageWithFooterContainer,
  Title,
  TitleContainer
} from 'components/AuthenticationPages';
import Footer from 'components/Footer';
import { Form } from 'components/Form';
import LanguageSwitcher from 'components/Language/LanguageSwitcher';
import Logo from 'components/Logo';
import { FullWidthPrimaryButtonLarge } from 'components/UI/PrimaryButton';
import CenteredTextSmall from 'components/UI/Text';
import { useFormFieldsErrorState, useManageFormFields } from 'hooks/form';
import { FormFields } from 'hooks/form/types';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import useGetServerHealth from 'hooks/server/useGetServerHealth';
import useCustomSnackbar from 'hooks/snackbar/useCustomSnackbar';
import useGetQueryVariables from 'hooks/url/useGetQueryVariables';
import { useNavigate } from 'react-router-dom';
import type { AxiosError, PromiseCallbacks } from 'types';
import { preventEventDefault } from 'utils/event';

import PasswordValidationHelper from './PasswordValidationHelper';

const TextContainer = styled.div`
  padding: 24px;
`;

interface ResetPasswordFormData {
  password: string;
  passwordRepetition: string;
}

const formFieldLabelResolver = (formFieldName: string) => `form.label.${formFieldName}`;

const Content = () => {
  const { formFieldsErrorsState, updateFormFieldsErrorsState, removeErrorFromFormField } = useFormFieldsErrorState();

  const formFields: FormFields = useMemo(() => {
    return [
      { label: 'password', name: 'password', type: 'password', required: true, autoGenerate: true },
      { label: 'passwordRepetition', name: 'passwordRepetition', type: 'password', required: true }
    ];
  }, []);

  const manageFormFieldsParams = useMemo(
    () => ({
      formFields,
      formFieldLabelResolver,
      PasswordFormField: TextField,
      formFieldsErrorsState,
      removeErrorFromFormField
    }),
    [formFields, formFieldsErrorsState, removeErrorFromFormField]
  );

  const { formFieldsJSX, getFormFieldsWithValues } = useManageFormFields(manageFormFieldsParams);

  const passwordFormFieldValue = useMemo(() => (getFormFieldsWithValues() as unknown as ResetPasswordFormData).password, [getFormFieldsWithValues]);

  const query = useGetQueryVariables();

  const navigate = useNavigate();

  const [t] = useGlobalTranslation();

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCustomSnackbar();

  const { serverIsOn } = useGetServerHealth();

  const { reset } = useResetPassword();

  const elementDisabled = useMemo(() => !serverIsOn, [serverIsOn]);

  const serverStatusIcon = useMemo(() => (serverIsOn ? <Image src="/images/icons/check.png" /> : <Image src="/images/icons/cancel.png" />), [serverIsOn]);

  const failedPasswordReset = useCallback(
    (errors: AxiosError) => {
      enqueueErrorSnackbar(t('message.errorOccurred'));
      updateFormFieldsErrorsState(errors.response?.data.fieldErrors || null);
    },
    [enqueueErrorSnackbar, t, updateFormFieldsErrorsState]
  );

  const enqueueSucceededPasswordReset = useCallback(() => {
    enqueueSuccessSnackbar(t('page.newPassword.message.passwordSuccessfullyReset'));
  }, [enqueueSuccessSnackbar, t]);

  const sendToLogin = useCallback(() => {
    navigate(paths.login);
  }, [navigate]);

  const succeededPasswordReset = useCallback(() => {
    enqueueSucceededPasswordReset();
    sendToLogin();
  }, [enqueueSucceededPasswordReset, sendToLogin]);

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      preventEventDefault(e);

      const data: ResetPasswordFormData = getFormFieldsWithValues() as unknown as ResetPasswordFormData;

      const callbacks: PromiseCallbacks = {
        failure: failedPasswordReset,
        success: succeededPasswordReset
      };

      reset(data.password, data.passwordRepetition, query.get('token') as string, callbacks);
    },
    [failedPasswordReset, getFormFieldsWithValues, query, reset, succeededPasswordReset]
  );

  return (
    <PageWithFooterContainer>
      <Header>
        <Logo />
      </Header>
      <ContentUI>
        <OuterBox>
          <InnerBox>
            <IconsSection>
              <LanguageSwitcher color={Color.typography} />
              {serverStatusIcon}
            </IconsSection>
            <TitleContainer>
              <Title>{t('page.newPassword.title')}</Title>
            </TitleContainer>
            <TextContainer>
              <CenteredTextSmall>{t('page.newPassword.enterNewPassword')}</CenteredTextSmall>
            </TextContainer>
            <Form onSubmit={handleFormSubmit}>
              <FormInnerContainer>
                {formFieldsJSX[0]}
                <PasswordValidationHelper password={passwordFormFieldValue} />
                {formFieldsJSX[1]}
                <FullWidthPrimaryButtonLarge disabled={elementDisabled} type="submit">
                  {t('message.save')}
                </FullWidthPrimaryButtonLarge>
              </FormInnerContainer>
            </Form>
          </InnerBox>
        </OuterBox>
      </ContentUI>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </PageWithFooterContainer>
  );
};

export default Content;
