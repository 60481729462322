import { MouseEvent, useCallback, useMemo } from 'react';

import Table from 'components/Table';
import { TableDataItem } from 'components/Table/types';
import { withHasAccessToFeature } from 'features/hocComponents';
import useGlobalTranslation from 'hooks/language/useGlobalTranslation';
import { formatDate, getShipmentTypeLabel, printPickupTimeWindow } from 'utils/strings';

import { useGetIncomingGoods } from '../hooks';
import { GetIncomingGoodsResponse } from '../types';

const columnHeaders = ['order', 'client', 'shipmentCompany', 'date', 'pickupTimeWindow', 'shipmentType'];

const headersLabelResolver = (label: string) => `feature.order.label.${label}`;

interface Props {
  height: number;
  handleIncomingGoodHover?: (_e: MouseEvent, _item: GetIncomingGoodsResponse) => void;
  handleIncomingGoodClick?: (_e: MouseEvent, _item: GetIncomingGoodsResponse) => void;
  refetchTrigger?: boolean;
}

const IncomingGoodsTable: React.FC<Props> = ({ refetchTrigger, height, handleIncomingGoodHover, handleIncomingGoodClick }) => {
  const { incomingGoods } = useGetIncomingGoods(refetchTrigger);

  const [t] = useGlobalTranslation();

  const tableTitle = useMemo(() => t('feature.incomingGoods.table.title'), [t]);

  const tableData = useMemo(() => {
    return incomingGoods.map(({ orderData: { orderNumber, client, shipmentType, carrier, id, goodsIncomingDate, pickupTimeWindow } }) => {
      return {
        client: client?.name,
        id,
        order: orderNumber,
        shipmentCompany: carrier,
        date: formatDate(goodsIncomingDate),
        pickupTimeWindow: printPickupTimeWindow(pickupTimeWindow),
        shipmentType: getShipmentTypeLabel(shipmentType, t)
      };
    });
  }, [incomingGoods, t]);

  const findGetIncomingGoodsResponseItem = useCallback((tableItem: TableDataItem) => incomingGoods.find((item) => item.orderData.id === tableItem.id), [incomingGoods]);

  const handleTableBodyRowHover = useCallback(
    (e: MouseEvent, item: TableDataItem) => {
      const incomingGoodsResponseItem = findGetIncomingGoodsResponseItem(item);
      if (!incomingGoodsResponseItem) {
        throw new Error('Incoming Good not found');
      }
      handleIncomingGoodHover?.(e, incomingGoodsResponseItem);
    },
    [findGetIncomingGoodsResponseItem, handleIncomingGoodHover]
  );

  const handleTableBodyRowClick = useCallback(
    (e: MouseEvent, item: TableDataItem) => {
      const incomingGoodsResponseItem = findGetIncomingGoodsResponseItem(item);
      if (!incomingGoodsResponseItem) {
        throw new Error('Incoming Good not found');
      }
      handleIncomingGoodClick?.(e, incomingGoodsResponseItem);
    },
    [findGetIncomingGoodsResponseItem, handleIncomingGoodClick]
  );

  const TableProps = useMemo(
    () => ({
      title: tableTitle,
      height,
      columnHeaders,
      tableData,
      headerLabelResolver: headersLabelResolver,
      onTableBodyRowHover: handleTableBodyRowHover,
      onTableBodyRowClick: handleTableBodyRowClick,
      noDataMessage: t('message.noOrders')
    }),
    [handleTableBodyRowClick, handleTableBodyRowHover, height, t, tableData, tableTitle]
  );

  return <Table {...TableProps} />;
};

export default withHasAccessToFeature(IncomingGoodsTable, 'warehouse.getAll');
