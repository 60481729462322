import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

interface Props {
  children: React.ReactNode;
}

const SnackbarProvider: React.FC<Props> = ({ children }) => {
  return (
    <NotistackSnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3} className="snackbarProvider__container">
      {children}
    </NotistackSnackbarProvider>
  );
};

export default SnackbarProvider;
