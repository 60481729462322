import { FileType } from 'appConstants';
import { UploadInputWithIcon } from 'components/Files';
import { ChangeEvent, useCallback } from 'lib/react';
import { generateUniqueId } from 'lib/uniqueId';
import { AppFile } from 'types';
import { getFileType, readFile } from 'utils/file';

interface Props {
  iconSrc: string;
  value?: AppFile[];
  onChange?: (_value: AppFile[]) => void;
  deleteFile: (_file: AppFile) => void;
  label?: string;
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
}

const FileFieldWithIcon: React.FC<Props> = ({ value, onChange, label, disabled, multiple, iconSrc, deleteFile, accept }) => {
  const handleMultipleFileFormFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        throw new Error('Not a file input');
      }
      const newFiles = Array.from(event.target.files);

      const newValue: AppFile[] = value ? [...value] : [];

      newFiles.forEach((file) => {
        readFile(file).then((dataUri) => {
          const type = getFileType(file);
          if (type === FileType.NO_TYPE) {
            return;
          }

          const { name, lastModified } = file;

          const newFile = { lastModified: new Date(lastModified), dataUri, type, name, file, id: generateUniqueId() };
          newValue.push(newFile);
        });
      });

      onChange?.(newValue);
    },
    [onChange, value]
  );

  const handleSingleFileFormFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        throw new Error('Not a file input');
      }
      const file = event.target.files[0];

      readFile(file).then((dataUri) => {
        const type = getFileType(file);
        if (type === FileType.NO_TYPE) {
          return;
        }

        const { name, lastModified } = file;

        const newFile = { lastModified: new Date(lastModified), dataUri, type, name, file, id: generateUniqueId() };

        onChange?.([newFile]);
      });
    },
    [onChange]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (multiple) {
        handleMultipleFileFormFieldChange(event);
      } else {
        handleSingleFileFormFieldChange(event);
      }
    },
    [handleMultipleFileFormFieldChange, handleSingleFileFormFieldChange, multiple]
  );

  return (
    <UploadInputWithIcon
      className="fileFieldWithIcon"
      disabled={disabled}
      label={label}
      onChange={handleChange}
      iconSrc={iconSrc}
      multipleFiles={multiple}
      files={value}
      deleteFile={deleteFile}
      accept={accept}
    />
  );
};

export default FileFieldWithIcon;
