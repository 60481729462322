import { styled } from 'styled-components';

import { Color } from 'appConstants';

export const PageWithFooterContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled.header`
  background: ${Color.accentPrimary};
  padding: 16px;
  text-align: center;
`;

export const Content = styled.div`
  padding: 0 16px;
`;

export const OuterBox = styled.div`
  margin: 100px auto;
  border: 2px solid ${Color.accentPrimary};
  background: ${Color.grey};
  width: 700px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 648px) {
    width: fit-content;
    height: auto;
    display: block;
  }
`;

export const InnerBox = styled.div`
  padding: 24px 16px;
  background: ${Color.background_white};
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  width: 350px;

  @media (max-width: 648px) {
    border: none;
  }

  @media (max-width: 382px) {
    width: auto;
  }
`;

export const IconsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Image = styled.img`
  width: 66px;
  padding: 0 18px;
`;

export const FooterContainer = styled.div`
  align-self: flex-end;
  width: 100%;
`;

export const TitleContainer = styled.div`
  padding: 16px 0;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5;
  text-align: center;
`;

export const FormInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
